// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --create-poll-dialog-background-color: var(--mi-white-color);
  --create-poll-dialog-section-bottom-padding: 20px;
}

.create-poll-container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content auto min-content;
  background-color: var(--create-poll-dialog-background-color);
}
.create-poll-container .top-bar-container {
  grid-row: 1;
  grid-column: 1;
  align-self: start;
}
.create-poll-container .top-bar-container app-top-bar {
  --top-bar-padding-start: 20px;
  --top-bar-padding-end: 20px;
  --top-bar-column-gap: 20px;
  --top-bar-title-text-align: center;
}
.create-poll-container .top-bar-container .create-poll-button {
  background: none;
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.create-poll-container .top-bar-container .create-poll-button .button-text {
  font-weight: var(--font-weight-medium);
  font-style: normal;
  font-size: var(--font-size-large);
  line-height: var(--font-line-height-large);
  color: var(--mi-primary-color);
}
.create-poll-container .top-bar-container .create-poll-button.disabled .button-text {
  color: var(--mi-color-text-lite);
}
.create-poll-container .content-wrapper {
  min-height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
}
.create-poll-container .content-wrapper .poll-top-content {
  display: flex;
  flex-direction: column;
}
.create-poll-container .content-wrapper .poll-top-content ion-reorder-group {
  margin: 0 -20px;
}
.create-poll-container .content-wrapper .poll-top-content ion-reorder-group .input-field-wrapper {
  padding: 14px 20px;
}
.create-poll-container .content-wrapper .poll-top-content .button-container {
  display: flex;
  flex-direction: column;
  gap: 7px;
}
.create-poll-container .content-wrapper .poll-top-content .button-container ion-button {
  height: 50px;
  --background: var(--mi-blue-color-4);
  --background-activated: transparent;
  --color: --mi-secondary-color-2;
  --box-shadow: none;
  text-transform: none;
  border-radius: 8px;
  overflow: hidden;
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-regular);
}
.create-poll-container .content-wrapper .poll-top-content .button-container .small-text {
  font-size: var(--font-size-tiny);
  font-weight: var(--font-weight-light);
  text-align: center;
}
.create-poll-container .content-wrapper .poll-bottom-content {
  display: flex;
  flex-direction: column;
  gap: 17px;
}
.create-poll-container .content-wrapper .poll-bottom-content app-info-banner {
  --info-banner-horizontal-margin: 0;
}
.create-poll-container .content-wrapper .poll-bottom-content app-toggle-area-button {
  --title-font-size: var(--font-size-large);
  --title-font-weight: var(--font-weight-light);
  --title-color: var(--mi-grey-color-13);
}
.create-poll-container .content-wrapper .poll-bottom-content app-toggle-area-button ::ng-deep .toggle-area-text {
  display: flex;
  align-items: center;
}

.input-field-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.input-field-wrapper .label-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.input-field-wrapper .label-container .left-side {
  display: flex;
  align-items: center;
}
.input-field-wrapper .label-container .left-side ion-reorder::part(icon) {
  font-size: 22px;
  width: 22px;
  height: 22px;
}
.input-field-wrapper .label-container app-label {
  --label-font-size: var(--font-size-regular);
  --label-font-weight: var(--font-weight-medium);
  --label-color: var(--mi-secondary-color-2);
}
.input-field-wrapper .label-container app-icon-button {
  --icon-button-background-width: 16px;
  --icon-button-background-height: 16px;
}
.input-field-wrapper app-text-field-limit-counter-wrapper ::ng-deep .text-field-limit-counter-wrapper {
  background-color: var(--mi-light-purple-1-color);
  border-radius: 15px;
  padding-right: 20px;
}
.input-field-wrapper ion-textarea {
  --background: var(--mi-light-purple-1-color);
  --padding-bottom: 20px;
  --padding-start: 20px;
  --placeholder-color: var(--mi-secondary-color-2);
  --placeholder-font-weight: var(--font-weight-regular);
  border-radius: 15px;
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-light);
  color: var(--mi-black-color);
  overflow: hidden;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
