import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { AiTagComponent } from '../ai-tag/ai-tag.component';

@Component({
  selector: 'app-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    AiTagComponent
  ]
})
export class LabelComponent implements OnInit {

  @Input() text: string;
  @Input() aiTag: boolean;
  @Input() error: boolean;

  constructor() { }

  ngOnInit(): void { }

}
