import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-add-case-options-dialog',
  templateUrl: './add-case-options-dialog.component.html',
  styleUrls: ['./add-case-options-dialog.component.scss'],
})
export class AddCaseOptionsDialogComponent  implements OnInit {

  constructor(private modalController: ModalController) { }

  ngOnInit(): void {}

  onCreateNewCase(): void {
    this.modalController.dismiss('new-case-clicked');
  }

  onExistingCase(): void {
    this.modalController.dismiss('existing-case-clicked');
  }

  async close(): Promise<void> {
    try {
      const dialog = await this.modalController.getTop();
      if (dialog) {
        this.modalController.dismiss();
      }
    } catch (err) {
      console.error(err);
    }
  }

}
