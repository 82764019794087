// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --label-font-size: var(--font-size-regular);
  --label-font-weight: var(--font-weight-medium);
  --label-color: var(--mi-black-color);
  --label-color-error: var(--mi-red-color-5);
}

.label-container {
  width: 100%;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: auto min-content;
  -moz-column-gap: 20px;
       column-gap: 20px;
  align-items: center;
}
.label-container .text-container {
  grid-row: 1;
  grid-column: 1;
}
.label-container .text-container .text {
  font-size: var(--label-font-size);
  font-weight: var(--label-font-weight);
  color: var(--label-color);
  white-space: nowrap;
}
.label-container .text-container .text.error {
  color: var(--label-color-error);
}
.label-container .ai-tag-container {
  grid-row: 1;
  grid-column: 2;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
