import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Post, PostType } from '../../services/yeti-protocol/chatter-api';
import { AttachmentMimeType, ImageAttachment, isVideo } from 'src/app/services/attachments.model';
import { stripHtmlTagsFromString } from 'src/app/services/utils/string-utils';
import { AppTranslationService } from 'src/app/services/app-translation.service';

@Component({
  selector: 'app-post-list-item',
  templateUrl: './post-list-item.component.html',
  styleUrls: ['./post-list-item.component.scss'],
})
export class PostListItemComponent implements OnInit {

  @Input() post: Post;
  @Input() showDefaultImage = false;
  @Input() showPostOwner = false;
  @Input() showPollDefaultImage = false;
  @Input() itemTitle?: string;

  @Output() listItemClicked: EventEmitter<Post> = new EventEmitter();

  PostType = PostType;
  fallBackToDefaultPostImage: boolean;

  constructor(
    private appTranslationService: AppTranslationService
  ) { }

  ngOnInit(): void { }

  get showLinkImage(): boolean {
    return this.post?.type === PostType.link && !this.showDefaultImage &&
      this.post?.og?.ogImage?.url?.length ? true : false;
  }

  get showPdfIcon(): boolean {
    return this.post?.type === PostType.attachment && !this.showDefaultImage &&
      this.post?.attachments?.[0].mimeType?.includes('pdf') ? true : false;
  }

  get showPostImages(): boolean {
    return this.post?.type === PostType.attachment && !this.showPdfIcon && !this.showDefaultImage;
  }

  get showDefaultPostImage(): boolean {
    return this.showDefaultImage || !this.showLinkImage && !this.showPdfIcon && !this.showPostImages;
  }

  get imageAndVideoAttachments(): Array<ImageAttachment> {

    let attachments: Array<ImageAttachment>;

    if (this.post?.attachments) {
      attachments = this.post?.attachments.map(attachment => {
        return {
          ...attachment,
          url: attachment.previewUrl,
          fullImageUrl: attachment?.fullUrl,
          videoUrl: isVideo(attachment.mimeType) ? attachment?.fullUrl : null,
          mimeType: isVideo(attachment.mimeType) ? AttachmentMimeType.MP4 : attachment.mimeType
        }
      });

      if (this.post?.watermarked) {
        return attachments.map(img => {
          img.fullUrl = img?.fullWatermarkUrl;
          img.previewUrl = img?.watermarkedPreviewUrl;
          img.videoUrl = isVideo(img.mimeType) ? img?.fullUrl : null
          return img;
        })
      }

      return attachments;
    }

    return [];
  }

  get postDescription(): string {
    const text = this.appTranslationService.instant('app.components.PostListItemComponent.shared-link');
    if (this.post?.type === PostType.link) {
      return text;
    } else if (this.post?.type === PostType.case) {
      return this.post.title;
    } else {
      return stripHtmlTagsFromString(this.post?.description || '') || '';
    }
  }

  get mediaOnlyText(): string {
    const text = this.appTranslationService.instant('app.components.PostListItemComponent.media-only-post-text');
    return `${this.post?.owner?.fullName} ${text}`;
  }

  postListItemClicked(): void {
    this.listItemClicked.emit(this.post);
  }

  get bannerText(): string {
    if (this.showPollDefaultImage) {
      return 'app.common.poll';
    } else if (this.post?.type === PostType.case) {
      return 'app.common.case';
    } else {
      return 'app.common.post';
    }
  }

}
