import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { SwiperModule } from 'swiper/angular';
import { TranslateModule } from '@ngx-translate/core';

import { ChipsModule } from '../modules/chips/chips.module';
import { IconButtonModule } from '../modules/icon-button/icon-button.module';
import { InfoSheetModule } from '../modules/info-sheet/info-sheet.module';
import { LayoutModule } from '../modules/layout/layout.module';
import { MIButtonsModule } from '../modules/buttons/buttons.module';
import { MIDirectivesModule } from '../directives/directives.module';
import { MIPipesModule } from '../pipes/pipes.module';
import { RichTextDocumentEditorModule } from '../modules/rich-text-document-editor/rich-text-document-editor.module';
import { UsersModule } from '../modules/users/users.module';
import { VideoHubModule } from '../modules/video-hub/video-hub.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';

import { ActiveConversationListItemComponent } from './messenger/active-conversation-list-item/active-conversation-list-item.component';
import { ActiveConversationsListComponent } from './messenger/active-conversations-list/active-conversations-list.component';
import { AoEventParticipantsListComponent } from './ao-event-participants-list/ao-event-participants-list.component';
import { AoEventParticipantItemComponent } from './ao-event-participant-item/ao-event-participant-item.component';
import { ArticleCardComponent } from './article-card/article-card.component';
import { ArticleCardExtendedComponent } from './article-card-extended/article-card-extended.component';
import { ArticleImageComponent } from './article-image/article-image.component';
import { AttachmentsUploadStatusComponent } from './attachments-upload-status/attachments-upload-status.component';
import { BaseUserProfileListComponent } from './base-user-profile-list/base-user-profile-list.component';
import { BecomeAoMemberComponent } from './become-ao-member/become-ao-member.component';
import { CaseClassificationChipComponent } from './case-classification-chip/case-classification-chip.component';
import {
  CaseClassificationTagsSelectionComponent
} from './case-classification-tags-selection/case-classification-tags-selection.component';
import { CaseImageUploadEditComponent } from './case-image-upload-edit/case-image-upload-edit.component';
import { CaseTagsComponent } from './case-tags/case-tags.component';
import { ClinicalCaseSharingCardComponent } from './clinical-case-sharing-card/clinical-case-sharing-card.component';
import { ProfileSharingCardComponent } from './profile-sharing-card/profile-sharing-card.component';
import {
  ClinicalCaseRequestsListComponent
} from './clinical-case-requests/clinical-case-requests-list/clinical-case-requests-list.component';
import {
  AcceptRejectCardComponent
} from './accept-reject-card/accept-reject-card.component';
import { ClinicalExpertCardComponent } from './clinical-expert-card-list/clinical-expert-card/clinical-expert-card.component';
import { ClinicalExpertCardListComponent } from './clinical-expert-card-list/clinical-expert-card-list.component';
import { ClinicalExpertsListComponent } from './clinical-experts-list/clinical-experts-list.component';
import { ClinicalExpertsListItemComponent } from './clinical-experts-list-item/clinical-experts-list-item.component';
import { ConnectionRequestsListComponent } from './messenger/connection-requests-list/connection-requests-list.component';
import {
  ConnectWithAoEventCoParticipantsCardComponent
} from './connect-with-ao-event-co-participants-card/connect-with-ao-event-co-participants-card.component';
import { ContextsListComponent } from './contexts-list/contexts-list.component';
import { ContextSwitchButtonComponent } from './context-switch-button/context-switch-button.component';
import { ContextSwitchComponent } from './context-switch/context-switch.component';
import { ContextSwitchMenuComponent } from './context-switch-menu/context-switch-menu.component';
import {
  CreateGroupCaseAddImagesSectionComponent
} from './create-group-case-add-images-section/create-group-case-add-images-section.component';
import { CreateGroupCaseSectionTitleComponent } from './create-group-case-section-title/create-group-case-section-title.component';
import {
  CreateGroupCaseTextInputSectionComponent
} from './create-group-case-text-input-section/create-group-case-text-input-section.component';
import { CreateGroupContentGroupSelectComponent } from './create-group-content-group-select/create-group-content-group-select.component';
import { CustomTabsComponent } from './custom-tabs/custom-tabs.component';
import { DocumentAttachmentComponent } from './document-attachment/document-attachment.component';
import { EmojiPickerComponent } from './emoji-picker/emoji-picker.component';
import { EmptyStateMessageCardComponent } from './empty-state-message-card/empty-state-message-card.component';
import { EmptyStateSearchComponent } from './search/empty-state-search/empty-state-search.component';
import { EmptyStateItemsListComponent } from './empty-state-items-list/empty-state-items-list.component';
import { EmptyStateMessageAndButtonComponent } from './empty-state-message-and-button/empty-state-message-and-button.component';
import { EventContributorCardComponent } from './events/event-contributor-card/event-contributor-card.component';
import { EventContributorsCardComponent } from './events/event-contributors-card/event-contributors-card.component';
import { EventImageComponent } from './event-image/event-image.component';
import { EventListItemComponent } from './event-list-item/event-list-item.component';
import { EventParticipantsCardComponent } from './events/event-participants-card/event-participants-card.component';
import { EventsListComponent } from './events-list/events-list.component';
import { ExpertRecommendationCardHeaderComponent } from './expert-recommendation-card-header/expert-recommendation-card-header.component';
import { ExpertRecommendationComponent } from './expert-recommendation/expert-recommendation.component';
import { FooterComponent } from './footer/footer.component';
import { GenericInputComponent } from './generic-input/generic-input.component';
import { GenericEmptyStateComponent } from './generic-empty-state/generic-empty-state.component';
import { GetVerifiedCardComponent } from './get-verified-card/get-verified-card.component';
import { GlobalSearchComponent } from './search/global-search/global-search.component';
import { AddContentCardComponent } from './add-content-card/add-content-card.component';
import { CaseCardComponent } from './case-card/case-card.component';
import { GroupImageComponent } from './group-image/group-image.component';
import { GroupInfoCardComponent } from './group-info-card/group-info-card.component';
import { GroupMemberRowItemComponent } from './group-member-row-item/group-member-row-item.component';
import { GroupParticipantCardComponent } from './group-participants/group-participant-card/group-participant-card.component';
import { GroupParticipantsListComponent } from './group-participants/group-participants-list/group-participants-list.component';
import { PostCardAttachmentsComponent } from './post-card-attachments/post-card-attachments.component';
import { PostCardComponent } from './post-card/post-card.component';
import { PostCardHeaderComponent } from './post-card-header/post-card-header.component';
import { PostsListComponent } from './posts-list/posts-list.component';
import { GroupPublicComponent } from './group-public/group-public.component';
import { GroupPublicViewMemberItemComponent } from './group-public-view-member-item/group-public-view-member-item.component';
import { GroupsRowListItemComponent } from './groups-row-list/groups-row-list-item/groups-row-list-item.component';
import { HeaderComponent } from './header/header.component';
import { IconComponent } from '../modules/icon/icon.component';
import { ImageAttachmentComponent } from './image-attachment/image-attachment.component';
import { ImageAttachmentsContainerComponent } from './image-attachments-container/image-attachments-container.component';
import { ImageWithBadgeComponent } from './search/image-with-badge/image-with-badge.component';
import { InterestsListComponent } from './interests-list/interests-list.component';
import { InterestItemComponent } from './interest-item/interest-item.component';
import { InviteUsersPromptCardComponent } from './invite-users-prompt-card/invite-users-prompt-card.component';
import { ItemsListSkeletonComponent } from './items-list-skeleton/items-list-skeleton.component';
import { JournalHighlightsComponent } from './journal-highlights/journal-highlights.component';
import {
  JournalHighlightsJournalItemComponent
} from './journal-highlights/journal-highlights-journal-item/journal-highlights-journal-item.component';
import { JournalPostItemComponent } from './journal-highlights/journal-post-item/journal-post-item.component';
import { JournalsListComponent } from './journals-list/journals-list.component';
import { JournalsListItemComponent } from './journals-list/journals-list-item/journals-list-item.component';
// import { LoadingErrorComponent } from './loading-error/loading-error.component';
import { LogoComponent } from './logo/logo.component';
import { MarketingTeaserCardComponent } from './marketing-teaser-card/marketing-teaser-card.component';
import {
  MessageCardAcceptedRequestComponent
} from './messenger-chat/messenger-list/message-card-accepted-request/message-card-accepted-request.component';
import { MessageCardAttachmentComponent } from './messenger-chat/messenger-list/message-card-attachment/message-card-attachment.component';
import {
  MessageCardConnectionRequestComponent
} from './messenger-chat/messenger-list/message-card-connection-request/message-card-connection-request.component';
import { MessageCardDateLineComponent } from './messenger-chat/messenger-list/message-card-date-line/message-card-date-line.component';
import {
  MessageCardDisconnectedComponent
} from './messenger-chat/messenger-list/message-card-disconnected/message-card-disconnected.component';
import { MessageCardImageComponent } from './messenger-chat/messenger-list/message-card-image/message-card-image.component';
import { MessageCardLinkComponent } from './messenger-chat/messenger-list/message-card-link/message-card-link.component';
import {
  MessageCardSenderLineComponent
} from './messenger-chat/messenger-list/message-card-sender-line/message-card-sender-line.component';
import { MessageCardTextComponent } from './messenger-chat/messenger-list/message-card-text/message-card-text.component';
import { MessageCardCaseComponent } from './messenger-chat/messenger-list/message-card-case/message-card-case.component';
import { MessengerButtonComponent } from './messenger-button/messenger-button.component';
import { MessengerListContainerComponent } from './messenger/messenger-list-container/messenger-list-container.component';
import { MiCardActionsComponent } from './mi-card-actions/mi-card-actions.component';
import { MiCardComponent } from './mi-card/mi-card.component';
import { MyJournalsListComponent } from './my-journals-list/my-journals-list.component';
import { MyJournalsListItemComponent } from './my-journals-list/my-journals-list-item/my-journals-list-item.component';
import { MyPeopleListComponent } from './my-people-list/my-people-list.component';
import { MyPeopleListItemComponent } from './my-people-list/my-people-list-item/my-people-list-item.component';
import { OpenGraphInfoComponent } from './open-graph-info/open-graph-info.component';
import { PendingRequestsComponent } from './group-participants/pending-requests/pending-requests.component';
import { CasePostToggleActionComponent } from './case-post-toggle-action/case-post-toggle-action.component';
import { PostTypeInfoTagsComponent } from './post-type-info-tags/post-type-info-tags.component';
import { ProfileCardComponent } from './profile-card/profile-card.component';
import {
  ProfileSettingsGetVerifiedCardComponent
} from './profile-settings-get-verified-card/profile-settings-get-verified-card.component';
import { RecentSearchesListComponent } from './search/recent-searches-list/recent-searches-list.component';
import { RecommendationsInfoComponent } from './recommendations-info/recommendations-info.component';
import { RecommendedGroupsItemComponent } from './recommended-groups-item/recommended-groups-item.component';
import { RecommendedGroupsListComponent } from './recommended-groups-list/recommended-groups-list.component';
import { ResourcesUpdatePopupComponent } from './resources-update-popup/resources-update-popup.component';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { SearchSuggestListComponent } from './search/search-suggest-list/search-suggest-list.component';
import { SelectableContactListItemComponent } from './selectable-contact-list-item/selectable-contact-list-item.component';
import { SelectableGroupListItemComponent } from './selectable-group-list-item/selectable-group-list-item.component';
import { ShareGroupCardComponent } from './share-group-card/share-group-card.component';
import { ShareSelectedRecipientCardComponent } from './share-selected-recipient-card/share-selected-recipient-card.component';
import { ShareSelectedRecipientsSliderComponent } from './share-selected-recipients-slider/share-selected-recipients-slider.component';
import { SkeletonLoaderComponent } from './skeleton-loader/skeleton-loader.component';
import { SkillsCardComponent } from './skills-card/skills-card.component';
import { StageOfTreatmentChipComponent } from './stage-of-treatment/stage-of-treatment-chip/stage-of-treatment-chip.component';
import { StageOfTreatmentChipsComponent } from './stage-of-treatment/stage-of-treatment-chips/stage-of-treatment-chips.component';
import { StreamFiltersComponent } from './stream-filters/stream-filters.component';
import { StreamFiltersTabsComponent } from './stream-filters-tabs/stream-filters-tabs.component';
import { SurgeryReferenceImageComponent } from './surgery-reference-image/surgery-reference-image.component';
import { SurgeryReferenceInfoCardComponent } from './surgery-reference-info-card/surgery-reference-info-card.component';
import { TextFieldLimitCounterComponent } from './text-field-limit-counter/text-field-limit-counter.component';
import { TextFieldLimitCounterWrapperComponent } from './text-field-limit-counter-wrapper/text-field-limit-counter-wrapper.component';
import { TopRecommendedArticleVideoComponent } from './top-recommended-article-video/top-recommended-article-video.component';
import { TopRecommendedEventComponent } from './top-recommended-event/top-recommended-event.component';
import { TopRecommendedGroupComponent } from './top-recommended-group/top-recommended-group.component';
import { TranslationLanguagesListComponent } from './translation-languages-list/translation-languages-list.component';
import { UserFullNameComponent } from './user-full-name/user-full-name.component';
import { UserInfoComponent } from './user-info/user-info.component';
import { UserProfileAdditionalInfoComponent } from './user-profile-additional-info/user-profile-additional-info.component';
import { UserProfileInfoCardComponent } from './user-profile-info-card/user-profile-info-card.component';
import { VerifiedBadgeComponent } from './ao-member-badge/verified-badge.component';
import { CrownBadgeComponent } from './badges/crown-badge/crown-badge.component';
import { WebAutoSuggestPopUpComponent } from './search/web-auto-suggest-pop-up/web-auto-suggest-pop-up.component';
import { WebRecentSearchesPopUpComponent } from './search/web-recent-searches-pop-up/web-recent-searches-pop-up.component';
import { SwipeButtonComponent } from './swipe-button/swipe-button.component';
import { CreateEditCasePostBottomBarComponent } from './create-edit-case-post-bottom-bar/create-edit-case-post-bottom-bar.component';
import { MessageContainerComponent } from './message-container/message-container.component';
import { FileSelectModule } from '../modules/file-select/file-select.module';
import { TutorialBubbleComponent } from './tutorial-bubble/tutorial-bubble.component';
import { SearchFieldComponent } from './search-field/search-field.component';
import { DedicatedSearchPeopleComponent } from './dedicated-search-people/dedicated-search-people.component';
import { DedicatedSearchGroupsComponent } from './dedicated-search-groups/dedicated-search-groups.component';
import { ClinicalCaseSharedWithSliderComponent } from './clinical-case-shared-with-slider/clinical-case-shared-with-slider.component';
import { ClinicalCaseSharedWithSliderItemComponent }
  from './clinical-case-shared-with-slider-item/clinical-case-shared-with-slider-item.component';
import { TopBarModule } from '../modules/top-bar/top-bar.module';
import { VerificationComponent } from './verification/verification/verification.component';
import { VerificationHeaderComponent } from './verification/verification-header/verification-header.component';
import { VerificationStepOneComponent } from './verification/verification-step-one/verification-step-one.component';
import { VerificationStepTwoComponent } from './verification/verification-step-two/verification-step-two.component';
import { VerificationStepThreeComponent } from './verification/verification-step-three/verification-step-three.component';
import { VerificationStepFourComponent } from './verification/verification-step-four/verification-step-four.component';
import { PostAttachmentsUploadEditComponent } from './post-attachments-upload-edit/post-attachments-upload-edit.component';
import { QrCodeWidgetComponent } from './qr-code-widget/qr-code-widget.component';
import { QRCodeModule } from 'angularx-qrcode';
import { CaseReadinessIndicatorComponent } from './case-readiness-indicator/case-readiness-indicator.component';
import { TopicSelectComponent } from './topic-select/topic-select.component';
import { CaseTextDetailsComponent } from './case-text-details/case-text-details.component';
import { CaseTextDetailsButtonComponent } from './case-text-details-button/case-text-details-button.component';
import { CaseDocumentsUploadEditPreviewComponent } from './case-documents-upload-edit-preview/case-documents-upload-edit-preview.component';
import { VerticalListComponent } from './vertical-list/vertical-list.component';
import { HorizontalListComponent } from './horizontal-list/horizontal-list.component';
import { CaseLibraryFilterSelectorComponent } from './case-library-filter-selector/case-library-filter-selector.component';
import { EventCardComponent } from './event-card/event-card.component';
import { MyGroupsComponent } from './my-groups/my-groups.component';
import { GroupsDiscoverComponent } from './groups-discover/groups-discover.component';
import { AcceptRejectListComponent } from './accept-reject-list/accept-reject-list.component';
import { VisibilityTrackerModule } from 'src/app/modules/visibility-tracker/visibility-tracker.module';
import { CardWithTabsComponent } from './card-with-tabs/card-with-tabs.component';
import { AboutMeCardComponent } from './about-me-card/about-me-card.component';
import { ClinicalCaseListItemComponent } from './clinical-case-list-item/clinical-case-list-item.component';
import { PostListItemComponent } from './post-list-item/post-list-item.component';
import { FeaturedCaseCardComponent } from './featured-case-card/featured-case-card.component';
import { OrcidButtonComponent } from './orcid-button/orcid-button.component';
import { SwiperVisibilityTrackerModule } from '../modules/swiper-visibility-tracker/swiper-visibility-tracker.module';
import { LinkOrcidComponent } from './link-orcid/link-orcid.component';
import { OrcidArticlesListComponent } from './orcid-articles-list/orcid-articles-list.component';
import { OrcidArticleListItemComponent } from './orcid-article-list-item/orcid-article-list-item.component';
import { OrcidProfileLinkComponent } from './orcid-profile-link/orcid-profile-link.component';
import { OrcidArticleCardComponent } from './orcid-article-card/orcid-article-card.component';
import { OrcidArticleCardHeaderComponent } from './orcid-article-card-header/orcid-article-card-header.component';
import { AoOtaClassificationTextComponent } from './ao-ota-classification-text/ao-ota-classification-text.component';
import { MultilineTextWithEllipsisModule } from './multiline-text-with-ellipsis/multiline-text-with-ellipsis.module';
import { UserMentionItemComponent } from './user-mention-item/user-mention-item.component';
import {ImageEditorModule} from '../modules/image-editor/image-editor.module';
import {TopContributorsListComponent} from './top-contributors-list/top-contributors-list.component';
import {
  RecommendedTopContributorsHlistComponent
} from './recommended-top-contributors-hlist/recommended-top-contributors-hlist.component';
import {PersonHlistCardComponent} from './person-hlist-card/person-hlist-card.component';
import {TopContributorBadgeComponent} from './badges/top-contributor-badge/top-contributor-badge.component';
import {PostImageComponent} from './post-image/post-image.component';
import { NetworkStreamItemsComponent } from './network-stream-items/network-stream-items.component';
import { NetworkStreamTopItemsComponent } from './network-stream-top-items/network-stream-top-items.component';
import { KnowledgeStreamItemsComponent } from './knowledge-stream-items/knowledge-stream-items.component';
import { PostToYourNetworkButtonComponent } from './post-to-your-network-button/post-to-your-network-button.component';
import {AlsoDiscussedInCardComponent} from './also-discussed-in-card/also-discussed-in-card.component';
import {LatestCommentContentComponent} from './latest-comment-content/latest-comment-content.component';
import { GroupActionButtonComponent } from './group-action-button/group-action-button.component';
import {MyFacultyLoungeComponent} from './my-faculty-lounge/my-faculty-lounge.component';
import {MyAoMembershipComponent} from './my-ao-membership/my-ao-membership.component';
import {
  NoInternetConnectionBannerComponent
} from './no-internet-connection-banner/no-internet-connection-banner.component';
import {PollDetailsStateComponent} from './poll-details-state/poll-details-state.component';
import {PollImageComponent} from './poll-image/poll-image.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        IonicModule,
        MIPipesModule,
        InfoSheetModule,
        TranslateModule,
        RichTextDocumentEditorModule,
        IconButtonModule,
        SwiperModule,
        PickerModule,
        MIDirectivesModule,
        MIButtonsModule,
        UsersModule,
        LayoutModule,
        VideoHubModule,
        ChipsModule,
        PdfViewerModule,
        FileSelectModule,
        TopBarModule,
        QRCodeModule,
        VisibilityTrackerModule.forRoot(),
        SwiperVisibilityTrackerModule.forRoot(),
        MultilineTextWithEllipsisModule,
        ImageEditorModule,
        PersonHlistCardComponent,
        ImageEditorModule,
        TopContributorBadgeComponent,
        GroupActionButtonComponent,
        IconComponent,
        NoInternetConnectionBannerComponent,
        PollDetailsStateComponent
    ],
  exports: [
    AoEventParticipantsListComponent,
    AoEventParticipantItemComponent,
    ActiveConversationsListComponent,
    ArticleCardComponent,
    ArticleCardExtendedComponent,
    ArticleImageComponent,
    AttachmentsUploadStatusComponent,
    BaseUserProfileListComponent,
    BecomeAoMemberComponent,
    CaseClassificationChipComponent,
    CaseClassificationTagsSelectionComponent,
    CaseImageUploadEditComponent,
    CaseTagsComponent,
    ClinicalCaseSharingCardComponent,
    ProfileSharingCardComponent,
    ClinicalCaseRequestsListComponent,
    AcceptRejectCardComponent,
    ClinicalExpertCardListComponent,
    ClinicalExpertsListComponent,
    ClinicalExpertsListItemComponent,
    ConnectionRequestsListComponent,
    ConnectWithAoEventCoParticipantsCardComponent,
    ContextsListComponent,
    ContextSwitchMenuComponent,
    CreateGroupContentGroupSelectComponent,
    CustomTabsComponent,
    DocumentAttachmentComponent,
    EmojiPickerComponent,
    EmptyStateMessageCardComponent,
    EmptyStateMessageCardComponent,
    EmptyStateSearchComponent,
    EmptyStateItemsListComponent,
    EmptyStateMessageAndButtonComponent,
    EventContributorCardComponent,
    EventContributorsCardComponent,
    EventImageComponent,
    EventListItemComponent,
    EventParticipantsCardComponent,
    EventsListComponent,
    ExpertRecommendationCardHeaderComponent,
    ExpertRecommendationComponent,
    FooterComponent,
    GenericInputComponent,
    GenericEmptyStateComponent,
    GetVerifiedCardComponent,
    GlobalSearchComponent,
    AddContentCardComponent,
    CaseCardComponent,
    GroupImageComponent,
    GroupInfoCardComponent,
    GroupMemberRowItemComponent,
    GroupParticipantsListComponent,
    PostCardAttachmentsComponent,
    PostCardComponent,
    PostCardHeaderComponent,
    PostsListComponent,
    GroupPublicComponent,
    GroupPublicViewMemberItemComponent,
    GroupsRowListItemComponent,
    HeaderComponent,
    ImageAttachmentComponent,
    ImageAttachmentsContainerComponent,
    InterestsListComponent,
    InterestItemComponent,
    InviteUsersPromptCardComponent,
    ItemsListSkeletonComponent,
    JournalHighlightsComponent,
    JournalsListComponent,
    MarketingTeaserCardComponent,
    MessengerButtonComponent,
    MessengerListContainerComponent,
    MiCardActionsComponent,
    MiCardComponent,
    MyJournalsListComponent,
    MyPeopleListComponent,
    PendingRequestsComponent,
    CasePostToggleActionComponent,
    PostTypeInfoTagsComponent,
    ProfileCardComponent,
    ProfileSettingsGetVerifiedCardComponent,
    RecentSearchesListComponent,
    RecommendedGroupsItemComponent,
    RecommendedGroupsListComponent,
    ResourcesUpdatePopupComponent,
    SearchBarComponent,
    SearchSuggestListComponent,
    SelectableContactListItemComponent,
    SelectableGroupListItemComponent,
    ShareGroupCardComponent,
    ShareSelectedRecipientCardComponent,
    ShareSelectedRecipientsSliderComponent,
    SkeletonLoaderComponent,
    SkillsCardComponent,
    StageOfTreatmentChipComponent,
    StageOfTreatmentChipsComponent,
    StreamFiltersComponent,
    StreamFiltersTabsComponent,
    SurgeryReferenceImageComponent,
    SurgeryReferenceInfoCardComponent,
    TextFieldLimitCounterWrapperComponent,
    TopRecommendedArticleVideoComponent,
    TopRecommendedEventComponent,
    TopRecommendedGroupComponent,
    TranslationLanguagesListComponent,
    UserFullNameComponent,
    UserInfoComponent,
    UserProfileInfoCardComponent,
    WebAutoSuggestPopUpComponent,
    WebRecentSearchesPopUpComponent,
    SwipeButtonComponent,
    CreateEditCasePostBottomBarComponent,
    MessageContainerComponent,
    TutorialBubbleComponent,
    SearchFieldComponent,
    DedicatedSearchPeopleComponent,
    DedicatedSearchGroupsComponent,
    VerificationComponent,
    VerificationHeaderComponent,
    VerificationStepOneComponent,
    VerificationStepTwoComponent,
    VerificationStepThreeComponent,
    VerificationStepFourComponent,
    ClinicalCaseSharedWithSliderComponent,
    ClinicalCaseSharedWithSliderItemComponent,
    PostAttachmentsUploadEditComponent,
    QrCodeWidgetComponent,
    CaseReadinessIndicatorComponent,
    TopicSelectComponent,
    TopicSelectComponent,
    CaseTextDetailsComponent,
    CaseTextDetailsButtonComponent,
    CaseDocumentsUploadEditPreviewComponent,
    VerticalListComponent,
    HorizontalListComponent,
    CaseLibraryFilterSelectorComponent,
    EventCardComponent,
    MyGroupsComponent,
    GroupsDiscoverComponent,
    AcceptRejectListComponent,
    CardWithTabsComponent,
    MultilineTextWithEllipsisModule,
    AboutMeCardComponent,
    ClinicalCaseListItemComponent,
    PostListItemComponent,
    FeaturedCaseCardComponent,
    OrcidButtonComponent,
    LinkOrcidComponent,
    OrcidArticlesListComponent,
    OrcidArticleListItemComponent,
    OrcidProfileLinkComponent,
    OrcidArticleCardComponent,
    OrcidArticleCardHeaderComponent,
    AoOtaClassificationTextComponent,
    UserMentionItemComponent,
    TopContributorsListComponent,
    RecommendedTopContributorsHlistComponent,
    MessageCardConnectionRequestComponent,
    MessageCardAcceptedRequestComponent,
    MessageCardDisconnectedComponent,
    MessageCardDateLineComponent,
    MessageCardSenderLineComponent,
    MessageCardTextComponent,
    MessageCardLinkComponent,
    MessageCardImageComponent,
    MessageCardAttachmentComponent,
    MessageCardCaseComponent,
    ImageWithBadgeComponent,
    PostImageComponent,
    NetworkStreamItemsComponent,
    NetworkStreamTopItemsComponent,
    KnowledgeStreamItemsComponent,
    PostToYourNetworkButtonComponent,
    AlsoDiscussedInCardComponent,
    LatestCommentContentComponent,
    MyFacultyLoungeComponent,
    MyAoMembershipComponent,
    NoInternetConnectionBannerComponent,
    PollImageComponent
  ],
  declarations: [
    ActiveConversationListItemComponent,
    ActiveConversationsListComponent,
    AoEventParticipantsListComponent,
    AoEventParticipantItemComponent,
    ArticleCardComponent,
    ArticleCardExtendedComponent,
    ArticleImageComponent,
    AttachmentsUploadStatusComponent,
    BaseUserProfileListComponent,
    BecomeAoMemberComponent,
    CaseClassificationChipComponent,
    CaseClassificationTagsSelectionComponent,
    CaseImageUploadEditComponent,
    CaseTagsComponent,
    ClinicalCaseSharingCardComponent,
    ProfileSharingCardComponent,
    ClinicalCaseRequestsListComponent,
    AcceptRejectCardComponent,
    ClinicalExpertCardComponent,
    ClinicalExpertCardListComponent,
    ClinicalExpertsListComponent,
    ClinicalExpertsListItemComponent,
    ConnectionRequestsListComponent,
    ConnectWithAoEventCoParticipantsCardComponent,
    ContextsListComponent,
    ContextSwitchButtonComponent,
    ContextSwitchComponent,
    ContextSwitchMenuComponent,
    CreateGroupCaseAddImagesSectionComponent,
    CreateGroupCaseSectionTitleComponent,
    CreateGroupCaseTextInputSectionComponent,
    CreateGroupContentGroupSelectComponent,
    CustomTabsComponent,
    DocumentAttachmentComponent,
    EmojiPickerComponent,
    EmptyStateMessageCardComponent,
    EmptyStateSearchComponent,
    EmptyStateItemsListComponent,
    EmptyStateMessageAndButtonComponent,
    EventContributorCardComponent,
    EventContributorsCardComponent,
    EventImageComponent,
    EventListItemComponent,
    EventParticipantsCardComponent,
    EventsListComponent,
    ExpertRecommendationCardHeaderComponent,
    ExpertRecommendationComponent,
    FooterComponent,
    GenericInputComponent,
    GenericEmptyStateComponent,
    GetVerifiedCardComponent,
    GlobalSearchComponent,
    AddContentCardComponent,
    CaseCardComponent,
    GroupImageComponent,
    GroupInfoCardComponent,
    GroupMemberRowItemComponent,
    GroupParticipantCardComponent,
    GroupParticipantsListComponent,
    PostCardAttachmentsComponent,
    PostCardComponent,
    PostCardHeaderComponent,
    PostsListComponent,
    GroupPublicComponent,
    GroupPublicViewMemberItemComponent,
    GroupsRowListItemComponent,
    HeaderComponent,
    ImageAttachmentComponent,
    ImageAttachmentsContainerComponent,
    ImageWithBadgeComponent,
    InterestsListComponent,
    InterestItemComponent,
    InviteUsersPromptCardComponent,
    ItemsListSkeletonComponent,
    JournalHighlightsComponent,
    JournalHighlightsJournalItemComponent,
    JournalPostItemComponent,
    JournalsListComponent,
    JournalsListItemComponent,
    LogoComponent,
    MarketingTeaserCardComponent,
    MessageCardAcceptedRequestComponent,
    MessageCardAttachmentComponent,
    MessageCardConnectionRequestComponent,
    MessageCardDateLineComponent,
    MessageCardDisconnectedComponent,
    MessageCardImageComponent,
    MessageCardLinkComponent,
    MessageCardSenderLineComponent,
    MessageCardTextComponent,
    MessageCardCaseComponent,
    MessengerButtonComponent,
    MessengerListContainerComponent,
    MiCardActionsComponent,
    MiCardComponent,
    MiCardComponent,
    MyJournalsListComponent,
    MyJournalsListItemComponent,
    MyPeopleListComponent,
    MyPeopleListItemComponent,
    OpenGraphInfoComponent,
    PendingRequestsComponent,
    PendingRequestsComponent,
    PendingRequestsComponent,
    CasePostToggleActionComponent,
    PostTypeInfoTagsComponent,
    ProfileCardComponent,
    ProfileSettingsGetVerifiedCardComponent,
    RecentSearchesListComponent,
    RecommendationsInfoComponent,
    RecommendedGroupsItemComponent,
    RecommendedGroupsListComponent,
    ResourcesUpdatePopupComponent,
    SearchBarComponent,
    SearchSuggestListComponent,
    SelectableContactListItemComponent,
    SelectableGroupListItemComponent,
    ShareGroupCardComponent,
    ShareSelectedRecipientCardComponent,
    ShareSelectedRecipientsSliderComponent,
    SkeletonLoaderComponent,
    SkillsCardComponent,
    StageOfTreatmentChipComponent,
    StageOfTreatmentChipsComponent,
    StreamFiltersComponent,
    StreamFiltersTabsComponent,
    SurgeryReferenceImageComponent,
    SurgeryReferenceInfoCardComponent,
    TextFieldLimitCounterComponent,
    TextFieldLimitCounterWrapperComponent,
    TopRecommendedArticleVideoComponent,
    TopRecommendedEventComponent,
    TopRecommendedGroupComponent,
    TranslationLanguagesListComponent,
    UserFullNameComponent,
    UserInfoComponent,
    UserProfileAdditionalInfoComponent,
    UserProfileAdditionalInfoComponent,
    UserProfileInfoCardComponent,
    VerifiedBadgeComponent,
    CrownBadgeComponent,
    WebAutoSuggestPopUpComponent,
    WebRecentSearchesPopUpComponent,
    SwipeButtonComponent,
    CreateEditCasePostBottomBarComponent,
    MessageContainerComponent,
    TutorialBubbleComponent,
    SearchFieldComponent,
    DedicatedSearchPeopleComponent,
    DedicatedSearchGroupsComponent,
    VerificationComponent,
    VerificationHeaderComponent,
    VerificationStepOneComponent,
    VerificationStepTwoComponent,
    VerificationStepThreeComponent,
    VerificationStepFourComponent,
    ClinicalCaseSharedWithSliderComponent,
    ClinicalCaseSharedWithSliderItemComponent,
    PostAttachmentsUploadEditComponent,
    QrCodeWidgetComponent,
    CaseReadinessIndicatorComponent,
    TopicSelectComponent,
    TopicSelectComponent,
    CaseTextDetailsComponent,
    CaseTextDetailsButtonComponent,
    CaseDocumentsUploadEditPreviewComponent,
    VerticalListComponent,
    HorizontalListComponent,
    CaseLibraryFilterSelectorComponent,
    EventCardComponent,
    MyGroupsComponent,
    GroupsDiscoverComponent,
    AcceptRejectListComponent,
    CardWithTabsComponent,
    AboutMeCardComponent,
    ClinicalCaseListItemComponent,
    PostListItemComponent,
    FeaturedCaseCardComponent,
    OrcidButtonComponent,
    LinkOrcidComponent,
    OrcidArticlesListComponent,
    OrcidArticleListItemComponent,
    OrcidProfileLinkComponent,
    OrcidArticleCardComponent,
    OrcidArticleCardHeaderComponent,
    AoOtaClassificationTextComponent,
    UserMentionItemComponent,
    TopContributorsListComponent,
    RecommendedTopContributorsHlistComponent,
    PostImageComponent,
    NetworkStreamItemsComponent,
    NetworkStreamTopItemsComponent,
    KnowledgeStreamItemsComponent,
    PostToYourNetworkButtonComponent,
    AlsoDiscussedInCardComponent,
    LatestCommentContentComponent,
    MyFacultyLoungeComponent,
    MyAoMembershipComponent,
    PollImageComponent
  ]
})
export class MIComponentsModule {
}
