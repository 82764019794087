// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --info-banner-background-color: var(--mi-blue-color-19);
  --info-banner-horizontal-margin: 10px;
  --info-banner-vertical-margin: 0;
}

.additional-info-container {
  display: flex;
  align-items: center;
  padding: 8px 10px;
  background-color: var(--info-banner-background-color);
  border-radius: 8px;
  font-size: var(--font-size-small);
  color: var(--mi-grey-color-13);
  margin: var(--info-banner-vertical-margin) var(--info-banner-horizontal-margin);
}
.additional-info-container .header {
  display: flex;
}
.additional-info-container .header .title {
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-medium);
}
.additional-info-container .additional-info {
  font-size: var(--font-size-small);
}
.additional-info-container .text-button {
  margin-left: auto;
  font-size: var(--font-size-tiny);
  font-weight: var(--font-weight-regular);
  color: var(--mi-grey-color-13);
  cursor: pointer;
}
.additional-info-container app-icon {
  color: var(--mi-blue-color-20);
  margin-right: 5px;
}
.additional-info-container.directionColumn {
  flex-direction: column;
}
.additional-info-container.directionColumn .additional-info {
  font-size: var(--font-size-tiny);
}
.additional-info-container.error {
  background-color: var(--mi-red-color-7);
}
.additional-info-container.error app-icon {
  color: var(--mi-red-color-6);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
