import {Component, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {
  CounterPosition
} from '../../components/text-field-limit-counter-wrapper/text-field-limit-counter-wrapper.component';
import {ItemReorderEventDetail} from '@ionic/core';
import {CreateGroupContentService} from '../../services/create-content/group/create-group-content-service.service';
import {ChatterApiService} from '../../services/chatter-api.service';
import {
  CreatePollPayload,
  CreatePollSuccessResponse,
  Poll,
  UpdatePollPayload
} from '../../services/yeti-protocol/chatter-api';
import {CreateContentStrategy} from '../../services/create-content/create-content';
import {ModalController} from '@ionic/angular';
import {ConfirmDialogData} from '../../services/dialogs/dialogs.ui.interface';
import {DialogsUIService} from '../../services/dialogs/dialogs.ui.service';
import {ToastMode, ToastService} from '../../services/toast.service';
import {ActionSource} from '../../services/yeti-protocol/tracking';
import {TRACKING_SERVICE, TrackingService} from '../../services/tracking/tracking.model';
import {debounceTime, Subject, Subscription} from 'rxjs';

interface PollInputField {
  _id?: string;
  value: string;
  isDirty: boolean;
}

@Component({
  selector: 'app-create-poll-dialog',
  templateUrl: './create-poll-dialog.component.html',
  styleUrls: ['./create-poll-dialog.component.scss'],
})
export class CreatePollDialogComponent implements OnInit, OnDestroy {

  @Input() createContentStrategy: CreateContentStrategy;

  readonly INPUT_FIELDS_TEXT_LIMIT = 90;
  readonly MAX_OPTIONS = 4;
  readonly CounterPosition = CounterPosition;

  emptyInputField: PollInputField = {
    value: '',
    isDirty: false
  };
  question: PollInputField = {...this.emptyInputField};
  options: Array<PollInputField> = [{...this.emptyInputField}, {...this.emptyInputField}];
  isBlurring = false;
  multipleChoice = false;
  creatingPoll = false;
  createdPoll: Poll;
  questionChange$ = new Subject<string>();
  questionSubscription: Subscription;

  constructor(
    private createGroupContentService: CreateGroupContentService,
    private chatterApiService: ChatterApiService,
    private modalController: ModalController,
    private dialogs: DialogsUIService,
    private toast: ToastService,
    @Inject(TRACKING_SERVICE) private trackingService: TrackingService
  ) {
  }

  ngOnInit(): void {
    this.createdPoll = this.createContentStrategy?.poll;
    this.initDialogState();
  }

  ngOnDestroy(): void {
    this.questionSubscription?.unsubscribe();
  }

  initDialogState(): void {
    if (this.createdPoll) {
      this.question.value = this.createdPoll.question;
      this.options = this.createdPoll.options.map(option => ({value: option.answer, isDirty: false, _id: option._id}));
      this.multipleChoice = !this.createdPoll.singleChoice;
    }
    this.questionSubscription = this.questionChange$
      .pipe(debounceTime(1000))
      .subscribe(() => {
        this.trackAction('createContent', 'addQuestionToPoll');
      });
  }

  onQuestionChange(value: string): void {
    this.question.value = value;
    this.questionChange$.next(this.question.value);
  }

  async onBack(): Promise<void> {
    const shouldProceedWithBack = await this.continueIfStateIsNotSaved();
    if (shouldProceedWithBack?.role === 'confirm' || shouldProceedWithBack === true) {
      try {
        const modal = await this.modalController.getTop();
        if (modal) {
          await modal.dismiss({customClose: true});
        }
      } catch (error) {
        console.warn('Modal dismissal failed:', error);
      }
      setTimeout(() => {
        this.createContentStrategy.openCreatePost(
          null,
          this.createContentStrategy.createPostState.postContent,
          this.createContentStrategy.clinicalCase,
          true,
          this.createContentStrategy.createPostState,
          this.createdPoll
        );
      }, 10)
      this.trackAction('createContent', 'stopCreatingPoll');
    }
  }

  async continueIfStateIsNotSaved(): Promise<any> {
    const hasDirtyOption = this.options.find((option) => option.value !== '' && option.isDirty);
    const isSingleChoiceModified = this.createdPoll ? this.createdPoll.singleChoice === this.multipleChoice : !!this.multipleChoice;
    const isQuestionModified = this.createdPoll ? this.createdPoll.question !== this.question.value : this.question.value !== '';

    if (hasDirtyOption || isQuestionModified || isSingleChoiceModified) {
      return this.createGroupContentService.promptUserAboutNotSavedState();
    } else {
      return true;
    }
  }

  handleReorder(event: CustomEvent<ItemReorderEventDetail>): void {
    this.options = event.detail.complete(this.options);
    this.trackAction('pollOptionReorder', 'pollOptionReorder', 'poll', this.createdPoll?._id);
  }

  addNewOption(): void {
    this.options.push({...this.emptyInputField});
    this.trackAction('createContent', 'addOptionToPoll');
  }

  async removeOption(index: number): Promise<void> {
    if (await this.showRemoveOptionConfirmDialog()) {
      this.options.splice(index, 1);
      this.trackAction('createContent', 'removeOptionToPoll');
    }
  }

  onSave(): void {
    let pollRequest: Promise<any>;
    this.creatingPoll = true;


    if (this.createdPoll) {
      const updatePollData: UpdatePollPayload = {
        _id: this.createdPoll._id,
        question: this.question.value,
        options: this.options.map(opt => ({_id: opt._id, answer: opt.value})),
        singleChoice: !this.multipleChoice
      };
      pollRequest = this.chatterApiService.updatePoll(updatePollData, this.source);
    } else {
      const createPollData: CreatePollPayload = {
        question: this.question.value,
        options: this.options.map(opt => opt.value),
        singleChoice: !this.multipleChoice
      };
      pollRequest = this.chatterApiService.createPoll(createPollData, this.source);
    }

    pollRequest.then(async (res: CreatePollSuccessResponse) => {
      try {
        const modal = await this.modalController.getTop();
        if (modal) {
          await modal.dismiss({customClose: true});
        }
      } catch (error) {
        console.warn('Modal dismissal failed:', error);
      }
      return new Promise(resolve => resolve(res.result));
    }).then((poll: Poll) => {
      setTimeout(() => {
        this.createContentStrategy.openCreatePost(
          null,
          this.createContentStrategy.createPostState.postContent,
          this.createContentStrategy.clinicalCase,
          true,
          this.createContentStrategy.createPostState,
          poll
        );
      }, 10);
    }).catch((err) => {
      this.toast.showWithMessage(err.error.error.message.errfor.message, 'app.common.error-default', ToastMode.ERROR);
    }).finally(() => this.creatingPoll = false);
  }

  onInputBlur(inputIndex: number): void {
    if (this.isBlurring) {
      return;
    }

    this.isBlurring = true;
    setTimeout(() => {
      this.options[inputIndex].isDirty = true;
      this.isBlurring = false;
    }, 50);
  }

  isOptionValid(inputIndex: number): boolean {
    const option = this.options[inputIndex];
    return option ? option.isDirty && !option.value.length : false;
  }

  async showRemoveOptionConfirmDialog(): Promise<boolean> {

    const removeActionKey = 'remove';
    const cancelActionKey = 'cancel';

    const removeOptionConfirmDialogData: ConfirmDialogData = {
      componentProps: {
        title: {
          translationKey: 'app.dialogs.CreatePollDialog.removeOptionTitle'
        },
        message: {
          translationKey: 'app.dialogs.CreatePollDialog.removeOptionMessage'
        },
        actions: [
          {
            key: cancelActionKey,
            label: {
              translationKey: 'app.dialogs.CreatePollDialog.cancel'
            },
            className: 'secondary'
          },
          {
            key: removeActionKey,
            label: {
              translationKey: 'app.dialogs.CreatePollDialog.removeOptionRemoveButton'
            },
            className: 'primary'
          }
        ]
      }
    }

    const confirmModal = await this.dialogs.createConfirmDialog(removeOptionConfirmDialogData);
    confirmModal.present();

    try {
      const res = await confirmModal.onDidDismiss();

      if (!res?.data?.actionKey) {
        return false;
      }

      return res?.data?.actionKey === removeActionKey;

    } catch (err) {
      console.error(err);
      return false;
    }
  }


  get isQuestionFieldValid(): boolean {
    return this.question?.isDirty && !this.question?.value.length;
  }

  get isSaveDisabled(): boolean {
    return !this.question.value.length || !!this.options.find(option => !option.value.length);
  }

  get showRemoveOptionButton(): boolean {
    return this.options.length > 2;
  }

  trackAction(objectId: string, objectTitle: string, objectType: string = 'poll', marketingCampaign?: string): void {
    this.trackingService.trackGenericClickedAction(objectId, objectType, objectTitle,
      {source: this.source || ActionSource.unspecified, campaign: marketingCampaign ?? undefined})
      .catch(_err => {
        console.error('Could not track poll action click.');
      });
  }

  get source(): string {
    return ActionSource.createOrEditPollDialog;
  }


}
