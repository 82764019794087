import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { GroupsFeedStateModel } from './groups-feed.model';
import { Injectable } from '@angular/core';
import { insertItem, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { GROUPS_FEED_STATE_KEY } from '../state-constants';
import { ChatterApiService } from 'src/app/services/chatter-api.service';
import { GroupsStreamItem, GroupsStreamSuccessResponse } from 'src/app/services/yeti-protocol/chatter-api';
import { GroupsFeed } from './groups-feed.actions';
import * as _ from 'lodash';

const GROUPS_FEED_STATE_TOKEN = new StateToken<GroupsFeedStateModel>(
  GROUPS_FEED_STATE_KEY
);

@State({
  name: GROUPS_FEED_STATE_TOKEN,
  defaults: {
    groupsFeedItems: [],
    totalCount: 0,
    loading: false,
  },
})
@Injectable()
export class GroupsFeedState {
  constructor(private chatterApiService: ChatterApiService) { }

  @Selector()
  static state(state: GroupsFeedStateModel): GroupsFeedStateModel {
    return state;
  }

  @Selector()
  static groupsFeedItems(state: GroupsFeedStateModel): Array<GroupsStreamItem> {
    return state.groupsFeedItems;
  }

  @Selector()
  static totalCount(state: GroupsFeedStateModel): number {
    return state.totalCount;
  }

  @Selector()
  static loading(state: GroupsFeedStateModel): boolean {
    return state.loading;
  }

  @Action(GroupsFeed.FetchGroupsFeed)
  async fetchGroupsFeed(
    ctx: StateContext<GroupsFeedStateModel>,
    payload: GroupsFeed.FetchGroupsFeed
  ): Promise<void> {
    try {
      ctx.patchState({
        loading: true,
      });

      const groupsFeedRes = (await this.chatterApiService.getGroupsStream(
        payload?.payloadParams?.pageIndex,
        payload?.payloadParams?.pageSize,
      )) as GroupsStreamSuccessResponse;

      ctx.dispatch(
        new GroupsFeed.FetchGroupsFeedSuccess(
          payload?.payloadParams,
          groupsFeedRes
        )
      );
    } catch (err) {
      ctx.dispatch(
        new GroupsFeed.FetchGroupsFeedFailed()
      );

      throw err;
    }
  }

  @Action(GroupsFeed.FetchGroupsFeedSuccess)
  fetchGroupsFeedSuccess(
    ctx: StateContext<GroupsFeedStateModel>,
    action: GroupsFeed.FetchGroupsFeedSuccess
  ): void {
    const state = ctx.getState();

    if (action.payloadParams.pageIndex === 0) {
      ctx.patchState({
        groupsFeedItems: action.response.result,
        totalCount: action.response.totalItemsCount,
        loading: false,
      });
    } else {
      ctx.patchState({
        groupsFeedItems: [...state.groupsFeedItems, ...action.response.result],
        totalCount: action.response.totalItemsCount,
        loading: false,
      });
    }
  }

  @Action(GroupsFeed.FetchGroupsFeedFailed)
  fetchGroupsFeedFailed(
    ctx: StateContext<GroupsFeedStateModel>
  ): void {
    ctx.patchState({
      loading: false,
    });
  }

  @Action(GroupsFeed.InsertGroupsFeedItemBeforeIndex)
  insertGroupsFeedItemBeforeIndex(ctx: StateContext<GroupsFeedStateModel>, action: GroupsFeed.InsertGroupsFeedItemBeforeIndex): void {
    ctx.setState(
      patch<GroupsFeedStateModel>({
        groupsFeedItems: insertItem<GroupsStreamItem>(action.item, action.index)
      })
    );

    const state = ctx.getState();

    ctx.patchState({
      totalCount: state.totalCount + 1,
    });
  }

  @Action(GroupsFeed.RemoveGroupsFeedItem)
  removeGroupsFeedItem(ctx: StateContext<GroupsFeedStateModel>, action: GroupsFeed.RemoveGroupsFeedItem): void {

    const state = ctx.getState();

    const itemIndex = state.groupsFeedItems.findIndex(item =>
      item.streamItem._id === action?.item?.streamItem?._id &&
      item.streamItem.parentType === action?.item?.streamItem?.parentType &&
      item.streamItem.parentId === action?.item?.streamItem?.parentId);

    if (itemIndex === -1) {
      return;
    }

    ctx.setState(
      patch<GroupsFeedStateModel>({
        groupsFeedItems: removeItem<GroupsStreamItem>(itemIndex)
      })
    );

    ctx.patchState({
      totalCount: state.totalCount - 1,
    });
  }

  @Action(GroupsFeed.UpdateGroupsFeedItem)
  updateGroupsFeedItem(ctx: StateContext<GroupsFeedStateModel>, action: GroupsFeed.UpdateGroupsFeedItem): void {
    ctx.setState(
      patch<GroupsFeedStateModel>({
        groupsFeedItems: updateItem<GroupsStreamItem>(item =>
          item.streamItem._id === action?.item?.streamItem?._id &&
          item.streamItem.parentType === action?.item?.streamItem?.parentType &&
          item.streamItem.parentId === action?.item?.streamItem?.parentId,
          existing => ({
            streamItemType: existing.streamItemType,
            streamItem: _.merge({}, existing.streamItem, action.item.streamItem) as any
          })
        )
      })
    );
  }

  @Action(GroupsFeed.UpdateGroupsFeedItemsOwnerFollowingStatus)
  updateGroupsPostsOwnerFollowingStatus(
    ctx: StateContext<GroupsFeedStateModel>,
    action: GroupsFeed.UpdateGroupsFeedItemsOwnerFollowingStatus): void {

    const state = ctx.getState();

    const groupsFeedItems = [...state.groupsFeedItems]?.map(item => {

      const itemCopy = { ...item };

      if (item?.streamItem?.owner?.userId === action?.userId) {
        itemCopy.streamItem.owner.isFollower = action?.isFollower;
        return itemCopy;
      }

      return itemCopy;
    });

    ctx.setState(
      patch<GroupsFeedStateModel>({
        groupsFeedItems: groupsFeedItems
      })
    );
  }
}
