import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { MIComponentsModule } from '../components.module';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-toggle-area-button',
  templateUrl: './toggle-area-button.component.html',
  styleUrls: ['./toggle-area-button.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    MIComponentsModule,
    FormsModule
  ]
})
export class ToggleAreaButtonComponent {

  @Input() title: string;
  @Input() description?: string;
  @Input() isToggleActive: boolean;
  @Output() toggleChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {
  }

  toggleClicked(): void {
    this.toggleChanged.emit(this.isToggleActive);
  }

}
