// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --title-font-size: var(--font-size-small);
  --title-font-weight: var(--font-weight-medium);
  --title-color: var(--mi-grey-color-13);
  --description-font-size: var(--font-size-small);
  --description-font-weight: var(--font-weight-regular);
  --description-color: var(--mi-secondary-color-2);
}

.toggle-area {
  background-color: var(--mi-inputs-color);
  border-radius: 8px;
  display: flex;
  padding: 15px;
  height: 65px;
}
.toggle-area .toggle-area-text {
  width: 75%;
}
.toggle-area .toggle-area-text .title {
  font-size: var(--title-font-size);
  font-weight: var(--title-font-weight);
  color: var(--title-color);
}
.toggle-area .toggle-area-text .description {
  font-size: var(--description-font-size);
  font-weight: var(--description-font-weight);
  color: var(--description-color);
}
.toggle-area ion-toggle {
  --background-checked: var(--mi-primary-color);
  margin-left: 11%;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
