import { Component, Inject, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CONTEXT_SERVICE, ContextService } from 'src/app/services/context/context.model';
import { Article } from 'src/app/services/yeti-protocol/article';
import { ActionSource } from '../../../services/yeti-protocol/tracking';
import {TopRecommendationType} from '../../../services/yeti-protocol/utils/enums';

@Component({
  selector: 'app-journal-post-item',
  templateUrl: './journal-post-item.component.html',
  styleUrls: ['./journal-post-item.component.scss'],
})
export class JournalPostItemComponent {
  @Input() article: Article;
  @Input() source: ActionSource;

  constructor(
    private router: Router,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService
  ) { }

  onOpenArticlePage(articleId: string): void {
    const url = '/' + [this.contextService.currentContext.key, 'article', articleId].join('/');
    if (this.source) {
      this.router.navigate([url], { queryParams: { source: this.source } });
    } else {
      this.router.navigateByUrl(url);
    }
  }

  get type(): string {
    return this.article?.type as any === TopRecommendationType.aoVideo || this.article?.type as any === TopRecommendationType.video
      ? 'Video' : this?.article?.type;
  }
}
