import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {IconComponent} from '../../modules/icon/icon.component';
import {MIComponentsModule} from '../components.module';

@Component({
  selector: 'app-info-banner',
  templateUrl: './info-banner.component.html',
  styleUrls: ['./info-banner.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    IconComponent,
    MIComponentsModule
  ]
})
export class InfoBannerComponent  implements OnInit {

  @Input() text: string;
  @Input() title?: string;
  @Input() additionalClass = '';
  @Input() buttonText?: string;

  @Output() buttonClicked: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {}

  onButtonClick(): void {
    this.buttonClicked.emit();
  }
}
