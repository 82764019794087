// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dialog-container {
  padding: 15px 25px;
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.dialog-container .dialog-title-close-btn-container {
  display: flex;
  justify-content: space-between;
}
.dialog-container .dialog-title-close-btn-container .dialog-title {
  padding-top: 5px;
  color: var(--mi-secondary-color-2);
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-medium);
  font-style: normal;
}
.dialog-container .dialog-title-close-btn-container .close-button-container .close-button {
  --icon-button-background-width: 100%;
  --icon-button-background-height: 100%;
  --icon-button-background-color: var(--mi-white-color);
  --icon-button-background-paddng: 0;
  --icon-button-border-radius: 0;
  --icon-button-icon-size: 24px;
  --icon-button-icon-color: var(--mi-secondary-color-2);
  outline: none;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  height: 30px;
  width: 30px;
}
.dialog-container .dialog-content {
  display: grid;
  grid-row-gap: 15px;
}
.dialog-container .dialog-content .button {
  width: 100%;
  height: 60px;
  display: grid;
  grid-template-columns: min-content auto min-content;
  grid-template-rows: auto;
  -moz-column-gap: 17px;
  column-gap: 17px;
  align-items: center;
  color: var(--mi-grey-color-13);
  background-color: var(--mi-background-color);
  border-radius: 10px;
  padding: 15px 20px;
  cursor: pointer;
  font-size: var(--font-size-regular);
}
.dialog-container .dialog-content .button app-icon {
  --icon-size: 26px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
