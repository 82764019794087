import {Component, Input, OnInit} from '@angular/core';
import {GetPollSuccessResponse, Poll} from '../../services/yeti-protocol/chatter-api';
import {ModalController} from '@ionic/angular';
import {ChatterApiService} from 'src/app/services/chatter-api.service';
import {ShortPublicProfile} from '../../services/yeti-protocol/public-profile';
import {AppNavController} from '../../services/app-nav-controller.service';
import {ActionSource} from '../../services/yeti-protocol/tracking';

@Component({
  selector: 'app-poll-see-all-votes-dialog',
  templateUrl: './poll-see-all-votes-dialog.component.html',
  styleUrls: ['./poll-see-all-votes-dialog.component.scss'],
})
export class PollSeeAllVotesDialogComponent  implements OnInit {

  @Input() pollId: string;

  poll: Poll;
  isLoading: boolean;

  constructor(private modalController: ModalController,
              private chatterApiService: ChatterApiService,
              private appNavController: AppNavController) { }

  ngOnInit(): void {
    this.loadPollData(this.pollId);
  }

  loadPollData(pollId: string): void {
    this.isLoading = true;
    this.chatterApiService.getPoll(pollId).then((res: GetPollSuccessResponse) => {
      if (res) {
        this.poll = res.result
      }
    }).finally(() => this.isLoading = false);
  }
  onClose(): void {
    this.modalController.dismiss();
  }

  goToUserProfile(user: ShortPublicProfile): void {
    this.modalController.dismiss();
    this.appNavController.openPublicUserProfile(user.userId, ActionSource.seeAllVotesDialog);
  }

  pullDownRefresh(): void {
    this.loadPollData(this.pollId);
  }

}
