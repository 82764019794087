import { Component, OnInit, ViewChild } from '@angular/core';
import { IonInfiniteScroll, ModalController } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { catchError, first } from 'rxjs';
import { CasesType, ClinicalCase } from 'src/app/services/yeti-protocol/clinical-case';
import { ClinicalCases } from 'src/app/state/clinical-cases/clinical-cases.actions';

@Component({
  selector: 'app-select-your-case-dialog',
  templateUrl: './select-your-case-dialog.component.html',
  styleUrls: ['./select-your-case-dialog.component.scss'],
})
export class SelectYourCaseDialogComponent  implements OnInit {

  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;

  selectedCase: ClinicalCase;
  clinicalCases: Array<ClinicalCase> = [];
  isLoading = false;
  totalItems: number;

  private count = 20;

  constructor(
    private modalController: ModalController,
    private store: Store,
  ) {}

  ngOnInit(): void {
    this.fetchClinicalCases();
  }

  onBack(): void {
    this.modalController.dismiss();
  }

  onNext(): void {
    this.modalController.dismiss(this.selectedCase, 'existing-case-selected');
  }

  onRadioChange(clinicalCase: ClinicalCase): void {
    this.selectedCase = clinicalCase;
  }

  onClickedCreateCase(): void {
    this.modalController.dismiss(null, 'create-new-case');
  }

  fetchClinicalCases(
    start = 0,
    count = this.count): void {
    this.isLoading = true;
    const fetchClinicalCasesPayload: ClinicalCases.FetchClinicalCasesPayload = {
      pageIndex: start,
      pageSize: count,
      type: CasesType.OWNED,
    };

    this.store.dispatch(new ClinicalCases.FetchClinicalCases(fetchClinicalCasesPayload))
      .pipe(
        first(),
        catchError(err => {
          console.error('Error in fetchClinicalCases:', err);
          return [];
        })
      )
      .subscribe(() => {
        const currentState = this.store.selectSnapshot(state => state.clinicalCases);
        this.clinicalCases = currentState?.clinicalCases || [];
        this.totalItems = currentState?.totalCount;
        this.isLoading = false;
      });
  }

  loadMore(): void {
    if (!this.canLoadMore) {
      this.disableInfiniteScroll(true);
      return;
    }

    const totalLoadedItems = this.clinicalCases.length;
    const start = Math.floor(totalLoadedItems / this.count);
    this.fetchClinicalCases(start);
  }

  get canLoadMore(): boolean {
    return this.clinicalCases?.length < this.totalItems;
  }

  get showSkeletonLoader(): boolean {
    return this.isLoading && !this.clinicalCases?.length;
  }

  get showEmptyState(): boolean {
    return !this.isLoading && !this.clinicalCases?.length;
  }

  private disableInfiniteScroll(value: boolean): void {
    if (this.infiniteScroll) {
      this.infiniteScroll.disabled = value;
    }
  }
}
