import {
  GroupListItem as GroupListItemChatterApi,
  Group as GroupChatterApi,
  GroupPostListItem as GroupPostListItemChatterApi,
  GroupMember as GroupMemberChatterApi,
  Post as GroupPostChatterApi,
  Case as CaseChatterApi
} from '../yeti-protocol/chatter-api';
import { PublicProfile } from '../yeti-protocol/public-profile';

export interface GroupUserPermissions {
  settings: boolean;
  invite: boolean;
  share: boolean;
  seeParticipantsList: boolean;
  seeDetails: boolean;
  editGroup: boolean,
  deleteGroup: boolean,
  reportGroup: boolean,
  leaveGroup: boolean,
  recommend: boolean;
}

export enum GroupUserScopes {
  ACTIVE = 'active',
  OWNER = 'owner',
  MODERATOR = 'moderator',
  MEMBER = 'member',
  PENDING_REQUEST = 'pending-request',
  PENDING_INVITATION = 'pending-invitation',
  PENDING_VERIFICATION_REQUEST = 'pending-verification-request',
  PENDING_VERIFICATION_INVITATION = 'pending-verification-invitation',
  NONE = 'none'
}

export enum GroupVisibility {
  PRIVATE = 'Private',
  PUBLIC = 'Public',
  UNLISTED = 'Unlisted'
}

export type Group = GroupChatterApi;
export type GroupListItem = GroupListItemChatterApi;
export interface GroupPostListItem extends GroupPostListItemChatterApi {
  userProfile?: PublicProfile
}
export type Post = GroupPostChatterApi;
export type Case = CaseChatterApi;
export type GroupMember = GroupMemberChatterApi;

export interface RemoveGroupEvent {
  groupId: string;
}
