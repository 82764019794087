// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --case-classification-chip-width: 100%;
  --case-classification-chip-height: 100%;
  --case-classification-chip-padding-top: 7px;
  --case-classification-chip-padding-bottom: 7px;
  --case-classification-chip-padding-start: 12px;
  --case-classification-chip-padding-end: 12px;
  --case-classification-chip-background-color: var(--mi-white-color);
  --case-classification-chip-color: var(--mi-primary-color);
  --case-classification-chip-selected-background-color: var(--mi-light-purple-3-color);
  --case-classification-chip-selected-color: var(--mi-primary-color);
  --case-classification-chip-icon-size: 17px;
  --case-classification-column-gap: 6px;
  --case-classification-text-color: var(--mi-secondary-color-2);
  --case-classification-text-size: var(--font-size-regular);
}

.case-classification-chip-container {
  background: var(--case-classification-chip-background-color);
  color: var(--case-classification-text-color);
  box-shadow: 0px 0px 0px 1px var(--case-classification-chip-color);
  padding-top: var(--case-classification-chip-padding-top);
  padding-bottom: var(--case-classification-chip-padding-bottom);
  padding-left: var(--case-classification-chip-padding-start);
  padding-right: var(--case-classification-chip-padding-end);
  border-radius: 8px;
  display: flex;
  margin: 1px;
  cursor: pointer;
  transition: background 0.25s ease, box-shadow 0.25s ease, opacity 0.25s ease;
}
.case-classification-chip-container.with-icon {
  display: grid;
  grid-template-columns: auto min-content;
  grid-template-rows: auto;
  align-items: center;
  -moz-column-gap: var(--case-classification-column-gap);
       column-gap: var(--case-classification-column-gap);
}
.case-classification-chip-container.with-icon .chip-text {
  grid-row: 1;
  grid-column: 1;
}
.case-classification-chip-container.selected {
  background: var(--case-classification-chip-selected-background-color);
  color: var(--case-classification-chip-selected-color);
  box-shadow: 0px 0px 0px 0px var(--case-classification-chip-color);
}
.case-classification-chip-container.selected .chip-text {
  font-weight: var(--font-weight-medium);
}
.case-classification-chip-container.disabled {
  opacity: 0.5;
}
.case-classification-chip-container .chip-text {
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--case-classification-text-size);
  width: 100%;
  margin: 0;
  display: inline-block;
  color: var(--case-classification-text-color);
}
@media screen and (max-width: 768px) {
  .case-classification-chip-container .chip-text {
    font-size: var(--font-size-small);
  }
}
.case-classification-chip-container app-icon {
  --icon-size: var(--case-classification-chip-icon-size);
  grid-row: 1;
  grid-column: 2;
  color: var(--case-classification-text-color);
}
.case-classification-chip-container.prevent-text-overflow {
  overflow: hidden;
}
.case-classification-chip-container.prevent-text-overflow .chip-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
