import { Component, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IonSlides, ModalController } from '@ionic/angular';
import { Clipboard } from '@awesome-cordova-plugins/clipboard/ngx';

import appConfig from '../../../config/config';

// models
import { GroupListItem, LastActiveGroupsSuccessResponse } from '../../services/yeti-protocol/chatter-api';
import {
  ActionSource,
  ActionTracked,
  ArticleShareTrackingParam,
  GenericShareTrackingParam,
  GroupShareTrackingParam,
  ObjectType,
  TrackingRequest
} from 'src/app/services/yeti-protocol/tracking';
import { Platform } from '../../../config/config.model';
import { VerificationStatus } from '../../services/verification.model';
import { Message } from 'src/app/services/yeti-protocol/message';
import { ClinicalCase, ShortPublicCLinicalCase } from 'src/app/services/yeti-protocol/clinical-case';

// services
import { TRACKING_SERVICE, TrackingService } from 'src/app/services/tracking/tracking.model';
import { GroupsService } from '../../services/groups/groups.service';
import { UserProfile } from 'src/app/services/yeti-protocol/auth/mi';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ToastMode, ToastService } from 'src/app/services/toast.service';
import {
  ObjectForSharing,
  ObjectForSharingType,
  ShareToObject,
  ShareToObjType,
  SocialSharingApp
} from 'src/app/services/sharing/sharing.model';
import { SHARING_SERVICE, SharingService } from 'src/app/services/sharing/sharing.service.interface';
import { GroupVisibility } from 'src/app/services/groups/group.model';
import { ResponsiveUtilsService } from 'src/app/services/utils/responsive-utils.service';
import { ConnectionsApiService, ConnectionsData } from 'src/app/services/messenger/connections-api.service';
import { Connection } from 'src/app/services/yeti-protocol/connections';
import {
  RichTextDocumentEditorComponent
} from 'src/app/modules/rich-text-document-editor/components/rich-text-document-editor/rich-text-document-editor.component';
import { MessengerService } from 'src/app/services/messenger/messenger.service';
import { CreateGroupContentService } from 'src/app/services/create-content/group/create-group-content-service.service';
import { stripHtmlTagsFromString } from 'src/app/services/utils/string-utils';
import { ClinicalCaseService } from 'src/app/services/case-library/clinical-case.service';
import { UI_UTILS_SERVICE, UIUtilsServiceInterface } from 'src/app/services/utils/ui-utils.service.interface';
import { ShortPublicProfile } from 'src/app/services/yeti-protocol/public-profile';
import { SelectedContacts } from '../share-recipients-people/share-recipients-people.model';
import { VerificationService } from '../../services/verification.service';
import { CreateGeneralContentStrategy } from '../../services/create-content/general/create-general-content-strategy';
import { CreatePostState } from 'src/app/services/create-content/create-content';

interface ShareDialogComponentConfig {
  platform: Platform;
}

@Component({
  selector: 'app-share-dialog',
  templateUrl: './share-dialog.component.html',
  styleUrls: ['./share-dialog.component.scss'],
})
export class ShareDialogComponent implements OnInit, OnDestroy {

  @Input() objectForSharing: ObjectForSharing;
  @Input() shareToAppsTitle: string;
  @Input() overlayImgNumber = 0;
  @Input() awsPersonalisationId?: string;
  @Input() source: ActionSource | string;

  @ViewChild('slides', { static: true }) slides: IonSlides;
  @ViewChild('richTextMessageEditor') richTextMessageEditor: RichTextDocumentEditorComponent;

  config: ShareDialogComponentConfig = appConfig;
  slideOptions = { slidesPerView: 'auto', zoom: false, grabCursor: true, spaceBetween: 25 };
  ObjectForSharingType = ObjectForSharingType;
  SocialSharingApp = SocialSharingApp;
  memberGroups: Array<ShareToObject> = [];
  totalMemberGroups: number;
  GroupVisibility = GroupVisibility;
  user: UserProfile;
  contacts: Array<ShareToObject> = [];
  totalContacts = 0;
  loadingContacts = false;
  selectedContacts: Array<ShareToObject> = [];
  shouldShowSelectedRecipientsView: boolean;
  messageMaxLength = 1000;
  messagePlainText = '';
  selectedRecipients: Array<ShareToObject> = [];
  modal: HTMLIonModalElement;
  sharingIsBeingProcessed: boolean;
  isNeedAdvice = false;
  qrCodeUrl: string;
  extractedUrlPath: string;

  private count = 1;

  constructor(
    private authService: AuthService,
    private clipboard: Clipboard,
    private connectionsApiService: ConnectionsApiService,
    private groupsService: GroupsService,
    private messengerService: MessengerService,
    private modalController: ModalController,
    private responsiveUtilsService: ResponsiveUtilsService,
    private toast: ToastService,
    @Inject(TRACKING_SERVICE) private trackingService: TrackingService,
    @Inject(UI_UTILS_SERVICE) private uiUtilsService: UIUtilsServiceInterface,
    private createGroupContentService: CreateGroupContentService,
    @Inject(SHARING_SERVICE) private sharingService: SharingService,
    private clinicalCaseService: ClinicalCaseService,
    private verificationService: VerificationService,
    public createGeneralContentStrategy: CreateGeneralContentStrategy
  ) {
    this.authService?.userProfileAsObservable?.subscribe(userProfile => {
      this.user = userProfile;
    });
  }

  async ngOnInit(): Promise<void> {

    this.source = this._source;

    if (this.objectForSharing?.type !== this.ObjectForSharingType.GROUP &&
      this.objectForSharing?.type !== this.ObjectForSharingType.PROFILE) {
      if (this.isUserVerified) {
        this.getMemberGroups()
          .then(groups => {
            this.memberGroups = groups;
          });
      }
    } else if (this.objectForSharing?.type === this.ObjectForSharingType.PROFILE) {
      this.qrCodeUrl = this.objectForSharing.url;
    }

    this.getContacts().then(({ connections, totalConnections }) => {
      this.contacts = connections;
      this.totalContacts = totalConnections;
    }).finally(() => this.loadingContacts = false);

    setTimeout(() => {
      this.setRichTextEditorHeightValues();
      document.addEventListener('mousedown', this.onBackdropClick);
    }, 0);

    this.modal.backdropDismiss = false;
  }

  ngOnDestroy(): void {
    document.removeEventListener('mousedown', this.onBackdropClick);
  }

  async goToPostToYourNetwork(): Promise<void> {
    const verificationStatus = await this.checkVerificationStatus();

    const createPostState: CreatePostState = {
      imageFiles: [],
      documentFiles: [],
      postContent: '',
      isSharing: true
    }

    console.log(this.objectForSharing);

    if (!verificationStatus) {
      return;
    }

    if (this.objectForSharing.type === ObjectForSharingType.CASE) {
      const caseDetails = await this.clinicalCaseService.getCase(this.objectForSharing._id);
      this.createGeneralContentStrategy.openCreatePost(null, null, caseDetails, null, createPostState);
    } else {

      const href = this.objectForSharing?.wrapperUrl || this.objectForSharing?.url;

      const anchorTagEmbeded = `<a href="${href}">${this.objectForSharing.url}</a>`
      this.createGeneralContentStrategy.openCreatePost(null, anchorTagEmbeded, null, true, createPostState);
    }

  }

  async checkVerificationStatus(): Promise<boolean> {
    const userVerificationStatus = await this.verificationService.verify();

    if (userVerificationStatus === VerificationStatus.VERIFIED) {
      return true;
    }

    return false;
  }

  onShare(target: SocialSharingApp): Promise<void> {
    return this.sharingService.share(target, this.objectForSharing)
      .then(shared => {
        if (shared) {
          this.trackObjectSharedAction(target);
        } else {
          this.toast.show('app.common.social-share-app-not-avilable', 'app.common.error-default', ToastMode.ERROR);
        }
      });

  }

  onBackdropClick = (ev: Event): any => {
    if ((ev as any).path?.length > 0) {

      const rootEl = (ev as any).path[0];

      if (rootEl?.localName === 'ion-backdrop') {
        this.uiUtilsService.stopEventPropagation(ev);
        this.onClose();
      }
    }
  };

  async onClose(): Promise<void> {
    const shouldProceedWithBack = await this.continueIfStateIsNOtSaved();

    if (shouldProceedWithBack?.role === 'confirm' || shouldProceedWithBack === true) {
      this.modalController.dismiss(null, 'cancel');
    }
  }

  async continueIfStateIsNOtSaved(): Promise<any> {
    let promptUserAboutNotSavedState = false;

    if (this.selectedContacts && this.selectedContacts.length > 0) {
      promptUserAboutNotSavedState = true;
    }

    if (this?.richTextMessageEditor?.content) {
      promptUserAboutNotSavedState = true;
    }

    if (promptUserAboutNotSavedState) {
      return this.createGroupContentService.promptUserAboutNotSavedState();
    } else {
      return true;
    }
  }

  getMemberGroups(start: number = 0): Promise<Array<ShareToObject>> {
    return this.groupsService.getLastActiveGroups(start, this.count, true)
      .then(response => {
        this.totalMemberGroups = (response as LastActiveGroupsSuccessResponse)?.totalItemsCount;
        return (response as LastActiveGroupsSuccessResponse)?.result.map(group => ({
          object: group,
          type: ShareToObjType.GROUP
        }));
      }).catch(() => {
        return [];
      });
  }

  getContacts(): Promise<{ connections: Array<ShareToObject>, totalConnections: number }> {
    this.loadingContacts = true;
    return this.connectionsApiService.getConnectionsFilteredByStatus('connected', 0, 9)
      .then((connectionsData: ConnectionsData) => {
        return {
          connections: connectionsData.connections.map(connection => ({
            object: connection,
            type: ShareToObjType.CONTACT
          })),
          totalConnections: connectionsData.total
        };
      }).catch(() => {
        return [];
      }) as Promise<{ connections: Array<ShareToObject>, totalConnections: number }>;
  }

  get isUserVerified(): boolean {
    return this.user?.verificationStatus === VerificationStatus.VERIFIED;
  }

  get isMobileDevice(): boolean {
    return this.config.platform === Platform.IOS || this.config.platform === Platform.ANDROID;
  }

  get isIOS(): boolean {
    return this.config.platform === Platform.IOS
  }

  get isIOSBrowser(): boolean {
    if (this.config.platform === Platform.BROWSER || this.config.platform === Platform.PWA) {
      return /(Mac|iPhone|iPod|iPad)/i.test(window.navigator.platform);
    }
    return false;
  }

  get sharedObjectImage(): string {
    return this.objectForSharing?.image || this.objectForSharing?.defaultImage;
  }

  get showGroupsContainer(): boolean {
    return (this.objectForSharing?.type === ObjectForSharingType?.ARTICLE ||
      this.objectForSharing?.type === ObjectForSharingType?.SURGERY_REFERENCE ||
      this.objectForSharing?.type === ObjectForSharingType?.EVENT ||
      this.objectForSharing?.type === ObjectForSharingType?.CASE ||
      this.objectForSharing?.type === ObjectForSharingType?.ORCID_ARTICLE);
  }

  get showQRCodeContainer(): boolean {
    return this.objectForSharing?.type === ObjectForSharingType?.PROFILE;
  }

  disableGroupsSelection(group: GroupListItem): boolean {
    return this.uiUtilsService.disableGroupsSelection(this.objectForSharing, group);
  }

  get clinicalCaseObj(): ShortPublicCLinicalCase {
    return {
      _id: this.objectForSharing?._id,
      deleted: null,
      createdDate: null,
      blurredUrl: this.objectForSharing?.image,
      title: this.objectForSharing?.title,
      numberOfDocuments: this.overlayImgNumber
    }
  }

  get profileObj(): ShortPublicProfile {
    return {
      userId: this.objectForSharing?._id,
      profileImageUrl: this.objectForSharing?.image,
      firstName: this.objectForSharing?.meta?.profileFirstName,
      lastName: this.objectForSharing?.meta?.profileLastName,
      fullName: this?.objectForSharing?.title,
      isVerified: this.objectForSharing?.meta?.profileIsVerified,
      organization: this?.objectForSharing?.meta?.profileOrganization,
      department: this?.objectForSharing?.meta?.profileDepartment,
      country: this.objectForSharing?.meta?.profileCountry
    }
  }

  get showGenericSharingCard(): boolean {
    return this.objectForSharing?.type !== ObjectForSharingType.CASE &&
      this.objectForSharing?.type !== ObjectForSharingType.PROFILE;
  }

  copyLink(): void {

    let promise;

    let url = this.objectForSharing?.wrapperUrl || this.objectForSharing?.url;

    if (this.objectForSharing?.type === ObjectForSharingType.PROFILE) {
      url += '?source=' + SocialSharingApp.LINK_COPIED + `&campaign=shared${this.objectForSharing?.type}_${this.objectForSharing._id}`;
    } else {
      url += '?source=' + SocialSharingApp.LINK_COPIED + `&campaign=shared${this.objectForSharing?.type}_${SocialSharingApp.LINK_COPIED}`;
    }

    if (this.isMobileDevice) {
      promise = this.clipboard.copy(url);
    } else {
      promise = navigator.clipboard.writeText(url);
    }

    if (promise) {
      promise.then(() => {
        this.toast.show('app.dialogs.SocialSharing.link-copied', 'app.common.success', ToastMode.SUCCESS, false, 3000);
        this.trackObjectSharedAction(SocialSharingApp.LINK_COPIED);
      }).catch(() => {
        this.toast.show('app.dialogs.SocialSharing.error-copying-link', 'app.common.error-default', ToastMode.ERROR);
      });
    }
  }

  get isDesktop(): boolean {
    return this.responsiveUtilsService.isDesktop;
  }

  get showAdviceNeededToggle(): boolean {
    return this.objectForSharing?.type === ObjectForSharingType.CASE;
  }

  async openRecipientsDialog(): Promise<void> {
    const selectedRecipients: SelectedContacts = await this.uiUtilsService.showShareRecipientsContactsDialog(this.selectedContacts);

    if (!selectedRecipients) {
      return;
    }

    this.selectedContacts = selectedRecipients.selectedContacts || [];

    if (this.selectedRecipientsCount > 0 && !selectedRecipients?.closed) {
      this.setSelectedRecipients();
      this.showSelectedRecipientsView();
    }
  }

  get selectedRecipientsCount(): number {
    return this.selectedContacts.length;
  }

  showSelectedRecipientsView(): void {
    this.shouldShowSelectedRecipientsView = true;

    setTimeout(() => {
      this.setRichTextEditorHeightValues();
    }, 0);
  }

  hideSelectedRecipientsView(): void {
    this.shouldShowSelectedRecipientsView = false;
  }

  onContentChanged(_event: Event): void {
    this.setPostContentPlainText(this?.richTextMessageEditor?.content);
  }

  setPostContentPlainText(messageContentHtml: string): void {
    this.messagePlainText = stripHtmlTagsFromString(messageContentHtml);
  }

  setRichTextEditorHeightValues(): void {
    if (this.richTextMessageEditor && this.richTextMessageEditor.setEditorHeight) {
      this.richTextMessageEditor?.setEditorHeight('170px');
    }
  }

  clickedRecipient(recipient: ShareToObject): void {
    this.selectedContacts = [recipient];
    this.setSelectedRecipients();

    if (this.selectedRecipientsCount > 0) {
      this.showSelectedRecipientsView();
    }
  }

  removeSelectedRecipient(recipient: ShareToObject): void {

    let index = -1;
    let recipients = [];

    if (recipient.type === ShareToObjType.CONTACT) {
      index = this.selectedContacts.findIndex(c => c?.object?._id === recipient?.object?._id);
      recipients = this.selectedContacts;
    }

    if (index > -1) {
      recipients.splice(index, 1);
      this.selectedRecipients = this.selectedContacts;
    }

    if (this.selectedRecipientsCount < 1) {
      this.hideSelectedRecipientsView();
    }
  }

  isContactSelected(contact: Connection): boolean {

    this.selectedContacts = this.selectedContacts || [];

    const index = this.selectedContacts.findIndex(c => c?.object?._id === contact?._id);

    return index > -1;
  }

  onContactSelectionChanged(selected: boolean, item: ShareToObject): void {

    const index = this.selectedContacts.findIndex(c => c?.object?._id === item?.object?._id);

    if (selected && index === -1) {
      this.selectedContacts.push(item);
    } else if (!selected && index > -1) {
      if (index > -1) {
        this.selectedContacts.splice(index, 1);
      }
    }

    this.setSelectedRecipients();
  }

  setSelectedRecipients(): void {
    this.selectedRecipients = this.selectedContacts;
  }

  get disableSendToRecipientsButton(): boolean {

    if (this.messagePlainText.length) {
      return !this.selectedRecipientsCount || this.messagePlainText.length > this.messageMaxLength;
    } else {
      return !this.selectedRecipientsCount;
    }
  }

  shareToRecipients(): void {

    if (this.sharingIsBeingProcessed) {
      return;
    }

    this.sharingIsBeingProcessed = true;

    this.sendMessageToContacts().then(() => {
      if (this.objectForSharing.type === this.ObjectForSharingType.CASE) {
        this.toast.show(
          'app.dialogs.SocialSharing.successful-case-title',
          'app.dialogs.SocialSharing.successful-case-text',
          ToastMode.SUCCESS);
      } else {
        this.toast.show(
          'app.dialogs.SocialSharing.successful-post-title',
          'app.dialogs.SocialSharing.successful-post-text',
          ToastMode.SUCCESS);
      }

      this.modalController.dismiss();
    }).catch(err => {
      if (err?.error?.error?.message?.errfor?.message || err?.message) {
        this.showError(err?.error?.error?.message?.errfor?.message || err?.message);
      }
    }).finally(() => {
      setTimeout(() => {
        this.sharingIsBeingProcessed = false;
      }, 500); // give time for dialog to close
    });
  }

  setAdviceNeededValue(value: boolean): void {
    this.isNeedAdvice = value;
  }

  private sendMessageToContacts(): Promise<Message> {

    if (!this.selectedContacts || !this.selectedContacts.length) {
      return Promise.resolve(null);
    }

    const connectionIds = this.selectedContacts.map(c => c?.object?._id);

    let messageType, sharedObjectId = '', message = '';

    if (this.objectForSharing.type === this.ObjectForSharingType.CASE) {
      messageType = 'case';
      sharedObjectId = this.objectForSharing._id;
      if (this?.richTextMessageEditor?.content) {
        message = `${this?.richTextMessageEditor?.content}`;
      }
    } else {
      messageType = 'link';
      if (this?.richTextMessageEditor?.content) {
        message = `
          ${this?.richTextMessageEditor?.content}
          ${this.objectForSharing?.url || this.objectForSharing?.wrapperUrl}
        `;
      } else {
        message = this.objectForSharing?.url || this.objectForSharing?.wrapperUrl;
      }
    }

    const sendMessagePromise = this.messengerService.sendMessageToMultipleParticipants(
      connectionIds,
      messageType,
      message,
      this.objectForSharing?.wrapperUrl || this.objectForSharing?.url,
      null,
      sharedObjectId, this.awsPersonalisationId);

    sendMessagePromise.then(() => {
      if (this.objectForSharing.type === this.ObjectForSharingType.CASE) {
        this.updateClinicalCaseSentStatus();
      }
    })

    return sendMessagePromise;
  }

  private updateClinicalCaseSentStatus(): void {

    this.clinicalCaseService.getCase(this.objectForSharing._id).then((clinicalCase: ClinicalCase) => {
      this.clinicalCaseService.emitClinicalCaseUpdated(clinicalCase);
    });

  }

  private extractPageLocationFromPathForTracking(): void {
    const pathName = window.location.pathname;
    const extractPath = pathName.split('/').slice(2).join('/');
    if (extractPath) {
      this.extractedUrlPath = this.trackingService.mapPathToActionSource(extractPath);
    }
  }

  private trackObjectSharedAction(target?: SocialSharingApp): void {
    try {
      const objectForSharing = this.objectForSharing;
      this.extractPageLocationFromPathForTracking();

      if (this.objectForSharing) {
        if (this.objectForSharing.type === 'group') {
          const paramsToTrack: GroupShareTrackingParam = {
            groupId: objectForSharing?._id,
            groupTitle: objectForSharing?.title,
            sharingTarget: target,
            source: this.source
          };
          if (this.source) {
            paramsToTrack.source = this.source;
          }
          const trackData: TrackingRequest = {
            action: ActionTracked.groupShared,
            params: paramsToTrack
          };
          this.trackingService.track(trackData).catch(_err => {
            console.error('Could not track group-shared action');
          });
        } else if (this.objectForSharing.type === 'case') {
          const paramsToTrack: GenericShareTrackingParam = {
            objectId: objectForSharing?._id,
            objectTitle: objectForSharing?.title,
            objectType: 'case',
            sharingTarget: target
          };
          if (this.source) {
            paramsToTrack.source = this.source;
          }
          const trackData: TrackingRequest = {
            action: ActionTracked.caseShared,
            params: paramsToTrack
          };
          this.trackingService.track(trackData).catch(_err => {
            console.error('Could not track case-shared action');
          });
        } else if (this.objectForSharing?.type === this.ObjectForSharingType.PROFILE) {
          const paramsToTrack: GenericShareTrackingParam = {
            objectId: objectForSharing?._id,
            objectTitle: objectForSharing?.title,
            objectType: 'profile',
            sharingTarget: target
          };
          if (this.source) {
            paramsToTrack.source = this.source;
          }
          const trackData: TrackingRequest = {
            action: ActionTracked.profileShared,
            params: paramsToTrack
          };
          this.trackingService.track(trackData).catch(_err => {
            console.error('Could not track ' + ActionTracked.profileShared + ' action');
          });
        } else if (this.objectForSharing?.type === ObjectForSharingType.ORCID_ARTICLE) {
          const paramsToTrack: GenericShareTrackingParam = {
            objectId: objectForSharing?._id,
            objectTitle: objectForSharing?.title,
            objectType: ObjectType.orcidArticle,
            sharingTarget: target
          };
          if (this.source) {
            paramsToTrack.source = this.source;
          }
          const trackData: TrackingRequest = {
            action: ActionTracked.orcIdArticleShared,
            params: paramsToTrack
          };
          this.trackingService.track(trackData).catch(_err => {
            console.error('Could not track ' + ActionTracked.orcIdArticleShared + ' action');
          });
        } else {
          const paramsToTrack: ArticleShareTrackingParam = {
            articleId: this.objectForSharing?._id,
            articleTitle: this.objectForSharing?.title,
            sharingTarget: target
          };
          if (this.source) {
            paramsToTrack.source = this.source;
          }
          let actionToTrack = ActionTracked.articleShared;
          if (this.objectForSharing.type === 'event') {
            actionToTrack = ActionTracked.eventShared;
          } else if (this.objectForSharing.type === 'surgery-reference') {
            actionToTrack = ActionTracked.surgeryReferenceShared;
            // for SR we don't have _id, and we will use url as id when indexing
            // so make sure to overwrite articleId if this is SR
            paramsToTrack.articleId = objectForSharing?.url;
          }
          const trackData: TrackingRequest = {
            action: actionToTrack,
            params: paramsToTrack
          };
          if (this.awsPersonalisationId) {
            trackData.awsPersonalisationId = this.awsPersonalisationId;
          }
          this.trackingService.track(trackData).catch(_err => {
            console.error('Could not track shared action. Error:' + _err);
          });
        }
      }
    } catch (err) {
      console.error('Could not track shared action! Error: ' + err);
    }
  }

  private showError(msg: string): void {
    this.toast.showWithMessage(msg, 'app.common.error-default', ToastMode.ERROR);
  }

  get _source(): ActionSource | string {

    if (this.source) {
      return this.source;
    }

    this.extractPageLocationFromPathForTracking();

    return this.extractedUrlPath;
  }
}

