import {Component, Input} from '@angular/core';
import {BaseButtonComponent} from '../base-button/base-button.component';

@Component({
  selector: 'app-create-poll-button',
  templateUrl: './create-poll-button.component.html',
  styleUrls: ['./create-poll-button.component.scss'],
})
export class CreatePollButtonComponent extends BaseButtonComponent<void> {
  @Input() iconOnly: boolean;
  @Input() buttonText = 'app.groups.case';
  @Input() disabled: boolean;
}
