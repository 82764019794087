import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-poll-image',
  templateUrl: './poll-image.component.html',
  styleUrls: ['./poll-image.component.scss'],
})
export class PollImageComponent {

  @Input() bannerText = 'Poll';
  @Input() defaultImage = 'assets/images/default-polls-image.svg';
  constructor() { }

}
