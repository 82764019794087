// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --select-your-case-padding-start: 20px;
  --select-your-case-padding-end: 15px;
  --button-padding-top-bottom: 16px;
  --button-padding-start-end: 70px;
  --list-items-spacing: 20px;
  --list-padding: 30px;
}

.select-your-case-container {
  width: 100%;
  height: 100%;
}
.select-your-case-container .header {
  grid-row: 1;
  grid-column: 1;
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  row-gap: 10px;
  background-color: var(--mi-white-color);
}
.select-your-case-container .header app-top-bar {
  --top-bar-padding-start: var(--select-your-case-padding-start);
  --top-bar-padding-end: var(--select-your-case-padding-end);
  --top-bar-column-gap: 20px;
  --top-bar-title-text-align: center;
}
.select-your-case-container .header .next-button {
  background: none;
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.select-your-case-container .header .next-button .button-text {
  font-weight: var(--font-weight-medium);
  font-style: normal;
  font-size: var(--font-size-large);
  line-height: var(--font-line-height-large);
  color: var(--mi-primary-color);
}
.select-your-case-container .header .next-button.disabled .button-text {
  color: var(--mi-color-text-lite);
}
.select-your-case-container .list-content {
  width: 100%;
  height: 90%;
}
.select-your-case-container .list-content .skeleton-container {
  padding: 30px;
}
.select-your-case-container .list-content .empty-state-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 150px;
}
.select-your-case-container .list-content .empty-state-container .warning-icon app-icon {
  --icon-size: 60px;
}
.select-your-case-container .list-content .empty-state-container .empty-state-message {
  width: 240px;
  text-align: center;
  padding: 30px;
  color: var(--mi-grey-color-13);
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-medium);
  font-style: normal;
}
.select-your-case-container .list-content .empty-state-container ion-button.create-case-btn {
  --background: var(--mi-primary-color);
  --border-radius: 4px;
  --padding-top: var(--button-padding-top-bottom);
  --padding-bottom: var(--button-padding-top-bottom);
  --padding-start: var(--button-padding-start-end);
  --padding-end: var(--button-padding-start-end);
  color: var(--ion-color-primary-contrast);
  margin: 10px auto;
  text-transform: none;
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-semi-bold);
  font-style: normal;
}
.select-your-case-container .list-content .content .list {
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  padding: var(--list-padding);
  row-gap: var(--list-items-spacing);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
