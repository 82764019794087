// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --icon-button-icon-color: var(--mi-color-text-lite);
}

.container app-icon-button {
  --icon-button-background-width: 20px;
  --icon-button-background-height: 20px;
  --icon-button-background-color: var(--mi-white-color);
  --icon-button-background-paddng: 0px;
  --icon-button-border-radius: 0;
  --icon-button-icon-size: 20px;
  --icon-button-icon-color: var(--icon-button-icon-color);
  grid-row: 1;
  grid-column: 1;
  justify-self: start;
}
.container .float-right {
  float: right;
  margin-top: -25px;
  margin-right: 5px;
}
.container .grey {
  --icon-button-background-color: var(--mi-inputs-color);
}
.container .toolbar-color {
  --icon-button-background-color: var(--mi-toolbars-color);
}
.container .create-case-position {
  position: absolute;
  margin-top: -345px;
  margin-left: 205px;
}
.container .comments-input-position {
  position: absolute;
  margin-top: -345px;
}
.container .position-absolute-bt-30 {
  position: absolute;
  bottom: 30px;
}
.container .position-absolute-bt-35 {
  position: absolute;
  bottom: 35px;
}
.container .width-350 {
  width: 350px;
}
.container .width-285 {
  width: 285px;
  right: 5px;
}
.container emoji-mart {
  z-index: 100;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
