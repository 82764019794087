// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.small-img ion-img {
  width: 50px !important;
  height: 50px !important;
  margin: auto !important;
}

.content-card {
  margin: 0 16px !important;
  --background: transparent;
  box-shadow: none;
}
.content-card .mobile-button {
  width: 100% !important;
  margin-right: 0 !important;
}
.content-card .button {
  margin-top: 15px;
  --background: var(--ion-color-secondary);
  color: var(--mi-white-color);
  font-weight: var(--font-weight-semi-bold);
  font-size: var(--mi-font-size-default);
  height: 47px;
  --border-radius: 5px;
  margin-right: 5px;
}
.content-card .card-content {
  text-align: center;
}
.content-card .card-content ion-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  height: auto;
}
.content-card .card-content .text-container {
  margin-top: 10px;
  color: var(--mi-black-color);
  padding: 0 20px;
}
.content-card .card-content .text-container h3 {
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-medium);
}
.content-card .card-content .text-container p {
  margin-top: 10px;
  color: var(--mi-black-color);
  font-size: var(--font-size-regular);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
