import { Component, Input } from '@angular/core';
import { BaseButtonComponent } from '../base-button/base-button.component';

@Component({
  selector: 'app-video-button',
  templateUrl: './video-button.component.html',
  styleUrls: ['./video-button.component.scss'],
})
export class VideoButtonComponent extends BaseButtonComponent<void> {
  @Input() iconOnly: boolean;
  @Input() title: string;
  @Input() buttonText = 'app.common.video';
  @Input() disabled: boolean;
}
