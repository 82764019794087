// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  padding: 20px 18px 30px 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.container .option-container {
  display: flex;
  flex-direction: column;
  gap: 13px;
}
.container .option-container .title {
  color: var(--mi-secondary-color-1);
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-regular);
}
.container .option-container .no-votes {
  color: var(--mi-color-text-lite);
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-regular);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
