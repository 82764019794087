import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ItemStatusChangeEvent } from '../items-list/items-list.model';
import { TranslatorData, TranslatorObject } from 'src/app/services/translator.model';
import { Subscription } from 'rxjs';

// models
import { Article } from '../../services/my-stream.model';
import {
  ActionSheetConfig,
  ArticleActionsVisibility,
  MiCardActionsObjectType,
  ArticleActions
} from '../../services/mi-card-actions.service';
import {ArticleItemType, ArticleStatus, TopRecommendationType} from 'src/app/services/yeti-protocol/utils/enums';
import { UserProfile } from 'src/app/services/yeti-protocol/auth/mi';
import { BaseUserProfile, NUMBER_USER_IMG_BUBBLES_CARDS } from 'src/app/modules/users/users.model';
import { TranslationAction, ActionSource } from 'src/app/services/yeti-protocol/tracking';

// services
import { ContextService, CONTEXT_SERVICE } from '../../services/context/context.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { TranslatorService } from 'src/app/services/translator.service';
import { ToastMode, ToastService } from 'src/app/services/toast.service';
import {
  ItemTypes,
  RecommendationsBookmarksService
} from '../../services/recommendations-service/recommendations-bookmarks.service';
import { SharingUIService } from 'src/app/services/sharing/sharing-ui.service';
import { SearchService } from 'src/app/services/search.service';
import { CONTEXT_DIALOGS_UI, ConfirmDialogData, ContextDialogsUI } from 'src/app/services/dialogs/dialogs.ui.interface';
import { TRACKING_SERVICE, TrackingService } from 'src/app/services/tracking/tracking.model';
import { getJournalTag } from 'src/app/services/utils/utils';
import { ArticleTagsService } from 'src/app/services/article/article-tags.service';
import { ArticleService } from 'src/app/services/article/article.service';
import { DialogsUIService } from 'src/app/services/dialogs/dialogs.ui.service';
import { ArticleTagType } from 'src/app/services/yeti-protocol/article';

interface ArticleRedirectQuery {
  awsPersonalisationId?: string;
  source?: string;
  recommendedBy?: string;
}
@Component({
  selector: 'app-article-card',
  templateUrl: './article-card.component.html',
  styleUrls: ['./article-card.component.scss'],
})
export class ArticleCardComponent implements OnInit, OnDestroy {

  @Input() article: Article;
  @Input() articlePublishState: boolean;
  @Input() source: ActionSource;
  @Input() selectedArticleState: string;
  @Input() checkedSelection: boolean;
  @Input() awsPersonalisationId?: string;
  @Input() recommendedById?: string;
  @Output() changePublishState = new EventEmitter<ItemStatusChangeEvent>();
  @Output() changeCheckedItemsPublishState = new EventEmitter<ItemStatusChangeEvent>();
  @Output() openPublicUserProfile: EventEmitter<string> = new EventEmitter();

  miCardActionsActionSheetConfig: ActionSheetConfig;
  articleActionsVisibility: ArticleActionsVisibility;
  safeVideoUrl: any;
  hideArticleImage: boolean;
  ArticleItemType = ArticleItemType;
  ArticleStatus = ArticleStatus;
  infoSheetId = 'article-infosheet';
  user: UserProfile;
  userProfileSubscription: Subscription;
  isTranslated = false;
  originalTitle: string;
  originalContent: string;
  articleTags: Array<string> = [];
  findingIdForArticle: boolean;
  bookmarking: boolean;

  constructor(
    private router: Router,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    private authService: AuthService,
    private translatorService: TranslatorService,
    private toast: ToastService,
    private recommendationsService: RecommendationsBookmarksService,
    private sharingUIService: SharingUIService,
    @Inject(CONTEXT_DIALOGS_UI) private dialogs: ContextDialogsUI,
    private searchService: SearchService,
    @Inject(TRACKING_SERVICE) private trackingService: TrackingService,
    private articleTagsService: ArticleTagsService,
    private articleService: ArticleService,
    private dialogsUiService: DialogsUIService,
  ) {
    this.articleActionsVisibility = {
      bookmark: true,
      translate: true
    };

    this.miCardActionsActionSheetConfig = {
      objectType: MiCardActionsObjectType.article,
      object: this.article,
      actionsVisibilityConfig: this.articleActionsVisibility
    }
  }

  async ngOnInit(): Promise<void> {
    this.userProfileSubscription = this.authService.userProfileAsObservable.subscribe((userProfile: UserProfile) => {
      this.user = userProfile;
    });

    this.articleTags = await this._getArticleTags(this.article);
  }

  _getArticleTags(article: Article): Promise<Array<string>> {
    const tagTypes = [
      ArticleTagType.channels,
      ArticleTagType.interests,
      ArticleTagType.language,
      ArticleTagType.fullText
    ]
    return this.articleTagsService.getTags(article, tagTypes, this.contextService.currentContext.key);
  }

  ngOnDestroy(): void {
    this.userProfileSubscription?.unsubscribe();
  }

  async openArticle(): Promise<void> {
    if (this.article) {

      const canProceed = await this.checkCanProceedWithAction();

      if (!canProceed) {
        return;
      }

      const url = '/' + [this.contextService.currentContext.key, 'article', this.article?._id].join('/');
      if (this.source || this.awsPersonalisationId || this.recommendedById) {
        const queryParams: ArticleRedirectQuery = {};

        if (this.source) {
          queryParams.source = this.source;
        } else {
          queryParams.source = ActionSource.articleVideoCard;
        }

        if (this.awsPersonalisationId) {
          queryParams.awsPersonalisationId = this.awsPersonalisationId;
        }
        if (this.recommendedById) {
          queryParams.recommendedBy = this.recommendedById;
        }
        this.router.navigate([url], { queryParams: queryParams });
      } else {
        this.router.navigateByUrl(url);
      }
    }
  }

  async onShareArticle(): Promise<void> {

    const canProceed = await this.checkCanProceedWithAction();

    if (!canProceed) {
      return;
    }

    this.sharingUIService.showShareDialogForObject(this.article, this.awsPersonalisationId);
  }

  miCardActionsActionClicked(action: string): void {
    switch (action) {
      case ArticleActions.bookrmark:
        this.bookmark();
        break;
      case ArticleActions.translate:
        this.onTranslate();
        break;
    }
  }

  async bookmark(): Promise<void> {

    if (this.bookmarking) {
      return;
    }

    this.trackBookmarkClicked();

    const canProceed = await this.checkCanProceedWithAction();

    if (!canProceed) {
      return;
    }

    if (this.article?.hasBookmarked) {
      const shouldProceed = await this.showShouldRemoveSavedItemDialog();

      if (!shouldProceed) {
        return;
      }

      this.bookmarking = true;

      this.recommendationsService.
        removeRecommendationBookmarkItem(this.article?.type, ItemTypes.BOOKMARK, this.article._id, this.awsPersonalisationId)
        .then(response => {
          if (response && response?.success) {
            this.article.hasBookmarked = !this.article.hasBookmarked;
            this.articleService.emitArticleUpdated(this.article as any);
            this.toast.show('app.common.item-removed-from-my-saved-knowledge');
          }
        }).catch(err => {
          console.error(err);
          this.toast.show('app.common.remove-saved-item-error-message');
        }).finally(() => {
          this.bookmarking = false;
        });
    } else {
      this.bookmarking = true;

      this.recommendationsService.addRecommendationBookmarkItem(
        this.article?.type, ItemTypes.BOOKMARK,
        this.article._id,
        this.awsPersonalisationId)
        .then(response => {
          if (response && response?.success) {
            this.article.hasBookmarked = !this.article.hasBookmarked;
            this.articleService.emitArticleUpdated(this.article as any);
            this.toast.show('app.common.item-added-to-my-saved-knowledge');
          }
        }).catch(err => {
          console.error(err);
          this.toast.show('app.common.save-item-error-message');
        }).finally(() => {
          this.bookmarking = false;
        });
    }
  }

  checkBoxClick($event: CustomEvent, articleId: string): void {
    const status = $event.detail.checked;
    this.checkedSelection = status;
    this.changeCheckedItemsPublishState.emit({ status, articleId });
  }

  changePublishStateOnArticle(value: string, articleId: string): void {
    const field = value;
    this.changePublishState.emit({ status, articleId, field });
  }

  get recommendsUsers(): Array<BaseUserProfile> {
    if (!this.article || !this.article?.recommends?.length) {
      return [];
    }

    return this.article?.recommends.slice(0, NUMBER_USER_IMG_BUBBLES_CARDS).map(member => {
      return {
        userId: member.userId,
        profileImageUrl: member.profileImageUrl
      }
    });
  }

  get displayProfileImageBubbles(): boolean {
    return this.article?.totalNumberOfRecommends && this.article?.totalNumberOfRecommends > 0;
  }

  get videoJournalTag(): string {
    return getJournalTag(this.article?.journal);
  }

  async onTranslate(): Promise<void> {

    const canProceed = await this.checkCanProceedWithAction();

    if (!canProceed) {
      return;
    }

    const translationLanguage = this.user.translationLanguage || '';
    if (translationLanguage) {
      this.translateArticle(translationLanguage)
    } else {
      this.dialogs.showSelectLanguageDialog();
    }
  }

  async recommendClicked(article: Article): Promise<void> {

    const canProceed = await this.checkCanProceedWithAction();

    if (!canProceed) {
      return;
    }

    if (article?.hasRecommended) {
      try {
        if (!(await this.showShouldUndoRecommendDialog())) {
          return;
        }
      } catch (err) {
        console.error(err);
        return;
      }
    }

    this.recommendationsService.recommendClicked(article, this.user, this.awsPersonalisationId)
  }

  async openRecommendsList(): Promise<void> {

    const canProceed = await this.checkCanProceedWithAction();

    if (!canProceed) {
      return;
    }
    return this.dialogs.showArticleRecommends(this.article?._id);
  }

  checkCanProceedWithAction(): Promise<boolean> {
    if (this.article?._id) {
      return Promise.resolve(true);
    }
    return this.findIdForArticle();
  }

  findIdForArticle(): Promise<boolean> {

    if (this.findingIdForArticle) {
      return Promise.resolve(false);
    }

    this.findingIdForArticle = true;

    return this.searchService.findIdForArticle(this.article, this.contextService.currentContext.key).then(article => {
      this.article = article as Article;
      return true;
    }).catch(() => {
      this.toast.show('app.common.something-went-wrong', 'app.common.error-default', ToastMode.ERROR, false, 3000);
      return false;
    }).finally(() => {
      this.findingIdForArticle = false;
    })
  }

  onOpenPublicUserProfile(userId: string): void {
    this.openPublicUserProfile.emit(userId);
  }

  private translateArticle(translationLanguage: string): void {
    if (!this.isTranslated) {
      this.originalTitle = this.article?.title;
      this.originalContent = this.article?.content;

      const dataToTranslateArray: Array<TranslatorObject> = new Array<TranslatorObject>();
      dataToTranslateArray.push({
        origin: this.article?.origin,
        title: this.article?.title,
        text: this.article?.content
      });

      this.trackTranslationAction(this.article?._id, this.article?.title, TranslationAction.translate, translationLanguage);
      this.translatorService.translateBatch('google', translationLanguage, dataToTranslateArray)
        .then((data: TranslatorData) => {
          if (data.records.length === 1) {
            this.article.title = data.records[0].title;
            this.article.content = data.records[0].text;
            this.isTranslated = true;
          }
        }).catch(error => {
          this.toast.showWithMessage(error, 'app.common.error-default', ToastMode.ERROR);
        });
    } else {
      this.article.title = this.originalTitle;
      this.article.content = this.originalContent;
      this.isTranslated = false;
      this.trackTranslationAction(this.article?._id, this.article?.title, TranslationAction.backToOriginal, translationLanguage);
    }
  }

  trackTranslationAction(articleId: string, title: string, action: string, language: string): void {
    this.trackingService.trackTranslateAction(articleId, 'article', title, language, action, this.awsPersonalisationId)
      .catch(_err => {
        console.error('Could not track translation click action.');
      });
  }

  private async showShouldRemoveSavedItemDialog(): Promise<boolean> {

    const removeActionKey = 'remove';
    const cancelActionKey = 'cancel';

    const showShouldRemoveSavedItemDialogData: ConfirmDialogData = {
      componentProps: {
        title: {
          translationKey: 'app.common.remove-saved-item-confirm.title'
        },
        message: {
          translationKey: 'app.common.remove-saved-item-confirm.text'
        },
        actions: [
          {
            key: cancelActionKey,
            label: {
              translationKey: 'app.common.remove-saved-item-confirm.cancel-btn'
            },
            className: 'secondary'
          },
          {
            key: removeActionKey,
            label: {
              translationKey: 'app.common.remove-saved-item-confirm.confirm-btn'
            },
            className: 'primary'
          }
        ]
      }
    }

    const confirmModal = await this.dialogsUiService.createConfirmDialog(showShouldRemoveSavedItemDialogData);
    confirmModal.present();

    try {
      const res = await confirmModal.onDidDismiss();

      if (!res?.data?.actionKey) {
        return false;
      }

      if (res?.data?.actionKey === removeActionKey) {
        return true;
      } else {
        return false;
      }

    } catch (err) {
      console.error(err);
      return false;
    }
  }

  private async showShouldUndoRecommendDialog(): Promise<boolean> {

    const confirmActionKey = 'confirm';
    const cancelActionKey = 'cancel';

    const showShouldUnRecommendDialogData: ConfirmDialogData = {
      componentProps: {
        title: {
          translationKey: 'app.common.undo-recommend.title'
        },
        message: {
          translationKey: 'app.common.undo-recommend.text'
        },
        actions: [
          {
            key: cancelActionKey,
            label: {
              translationKey: 'app.common.undo-recommend.cancel-btn'
            },
            className: 'secondary'
          },
          {
            key: confirmActionKey,
            label: {
              translationKey: 'app.common.undo-recommend.confirm-btn'
            },
            className: 'primary'
          }
        ]
      }
    }

    const confirmModal = await this.dialogsUiService.createConfirmDialog(showShouldUnRecommendDialogData);
    confirmModal.present();

    try {
      const res = await confirmModal.onDidDismiss();

      if (!res?.data?.actionKey) {
        return false;
      }

      if (res?.data?.actionKey === confirmActionKey) {
        return true;
      } else {
        return false;
      }

    } catch (err) {
      console.error(err);
      return false;
    }
  }

  trackBookmarkClicked(): void {
    this.trackingService.trackGenericClickedAction(this.article._id, this.article?.type, 'save',
      { source: this.source || ActionSource.unspecified })
      .catch(_err => {
        console.error('Could not track bookmark click action.');
      });
  }

  get type(): string {
    return this.article?.type as any === TopRecommendationType.aoVideo || this.article?.type as any === TopRecommendationType.video
      ? 'Video' : this?.article?.type;
  }
}
