import {Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule, ModalController} from '@ionic/angular';
import {Case, Poll, Post, VotePollPayload, VotePollSuccessResponse} from '../../services/yeti-protocol/chatter-api';
import {IconButtonModule} from '../../modules/icon-button/icon-button.module';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {ChatterApiService} from '../../services/chatter-api.service';
import {ToastMode, ToastService} from '../../services/toast.service';
import {UserProfile} from '../../services/yeti-protocol/auth/mi';
import {AuthService} from '../../services/auth/auth.service';
import {Subscription} from 'rxjs';
import {IconComponent} from '../../modules/icon/icon.component';
import {ActionSource} from '../../services/yeti-protocol/tracking';
import {TRACKING_SERVICE, TrackingService} from '../../services/tracking/tracking.model';
import {PostsDataService} from '../../services/posts/posts-data.service';
import {
  PollSeeAllVotesDialogComponent
} from '../../dialogs/poll-see-all-votes-dialog/poll-see-all-votes-dialog.component';

export enum PollState {
  CreateView = 'createView',
  DetailsView = 'detailsView',
  FeedView = 'feedView',
  EditView = 'editView'
}

@Component({
  selector: 'app-poll-details-state',
  templateUrl: './poll-details-state.component.html',
  styleUrls: ['./poll-details-state.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    IconButtonModule,
    TranslateModule,
    IconComponent
  ]
})
export class PollDetailsStateComponent implements OnInit, OnDestroy {

  @Input() poll: Poll;
  @Input() state: PollState;
  @Input() source: ActionSource;
  @Input() post?: Post | Case;

  @Output() editClicked: EventEmitter<void> = new EventEmitter<void>();
  @Output() deleteClicked: EventEmitter<void> = new EventEmitter<void>();

  user: UserProfile;
  selectedOptions: Array<string> = [];

  readonly PollState = PollState;
  private userSubscription: Subscription;

  constructor(
    private chatterApiService: ChatterApiService,
    private toast: ToastService,
    private translateService: TranslateService,
    private authService: AuthService,
    @Inject(TRACKING_SERVICE) private trackingService: TrackingService,
    private postsDataService: PostsDataService,
    private modalController: ModalController
  ) {
  }

  ngOnInit(): void {
    this.userSubscription = this.authService.userProfileAsObservable.subscribe(userProfile => {
      this.user = userProfile;
    });
  }

  ngOnDestroy(): void {
    this.userSubscription?.unsubscribe();
  }

  onEdit(): void {
    this.editClicked.emit();
  }

  onDelete(): void {
    this.deleteClicked.emit();
  }

  optionClicked(optionId: string): void {
    if (this.isVoteAllowed) {
      const index = this.selectedOptions.findIndex(id => optionId === id);
      if (index > -1) {
        this.selectedOptions.splice(index, 1);
      } else {
        if (this.poll.singleChoice) {
          this.selectedOptions = [optionId];
        } else {
          this.selectedOptions.push(optionId);
        }
        this.trackAction('pollOptionSelected', optionId);
      }
    }
  }

  onSubmitVote(): void {
    const voteData: VotePollPayload = {
      _id: this.poll._id,
      votes: this.selectedOptions
    }
    this.chatterApiService.voteOnPoll(voteData, this.source).then((res: VotePollSuccessResponse) => {
      this.poll = res.result;
      this.toast.showWithMessage(
        this.translateService.instant('app.dialogs.CreatePollDialog.voteSuccess'),
        null,
        ToastMode.SUCCESS
      );
      this.post.poll = this.poll;
      this.postsDataService.triggerPostEditedAction(this.post);
    }).catch((_err) => {
      console.error(_err);
      this.toast.showWithMessage(
        this.translateService.instant('app.dialogs.CreatePollDialog.voteError'),
        'app.common.error-default',
        ToastMode.ERROR
      );
    })
  }

  get showActionButtons(): boolean {
    return this.state === PollState.CreateView;
  }

  get showMultipleChoiceAllowed(): boolean {
    return this.state === PollState.DetailsView && !this.poll.singleChoice;
  }

  get isVoteAllowed(): boolean {
    return this.state === PollState.DetailsView && !this.poll.hasVoted && !this.isPollOwner && this.poll.isActive;
  }

  get showAdditionalInfo(): boolean {
    return this.state === PollState.DetailsView || this.state === PollState.FeedView;
  }

  get isPollOwner(): boolean {
    return this.poll?.owner?.userId === this.user?.id;
  }

  getOptionPercentage(option: { totalVotes: number }): number {
    return this.poll.totalVotes ? (option.totalVotes / this.poll.totalVotes) * 100 : 0;
  }

  get showResults(): boolean {
    return (this.state !== PollState.CreateView && this.state !== PollState.EditView)
      && (this.poll.hasVoted || !this.poll.isActive || this.isPollOwner);
  }

  get isOptionBorderHighlighted(): boolean {
    return this.isVoteAllowed || this.state === PollState.CreateView
      || this.state === this.PollState.EditView || (!this.poll.hasVoted && this.state === this.PollState.FeedView && !this.isPollOwner);
  }

  async openVotesDialog(event: Event): Promise<void> {
    event.preventDefault();
    event.stopPropagation();
    if (this.isPollOwner) {
      const dialog = await this.modalController.create({
        component: PollSeeAllVotesDialogComponent,
        componentProps: {
          pollId: this.poll._id
        },
        cssClass: 'poll-see-all-votes-dialog rounded-modal'
      });
      await dialog.present();
      this.trackAction('votersView', 'clickOpenVotersView');
    }
  }

  trackAction(objectId: string, objectTitle: string, objectType: string = 'poll'): void {
    this.trackingService.trackGenericClickedAction(objectId, objectType, objectTitle,
      {source: this.source || ActionSource.unspecified, campaign: this.poll._id})
      .catch(_err => {
        console.error('Could not track action click.');
      });
  }

  get isFeedView(): boolean {
    return this.state === PollState.FeedView;
  }
}
