// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --info-sheet-action-padding-top: 20px;
  --info-sheet-action-padding-bottom: 20px;
  --info-sheet-action-padding-start: 20px;
  --info-sheet-action-padding-end: 20px;
  --info-sheet-action-background-color: var(--mi-background-color);
  --info-sheet-action-column-gap: 20px;
  --info-sheet-action-border-radius: 10px;
  --info-sheet-icon-color: var(--mi-black-color);
  --info-sheet-icon-font-size: 24px;
  --info-sheet-hover-color: var(--mi-light-purple-color);
  --info-sheet-action-text-color: var(--mi-secondary-color-2);
  --info-sheet-action-text-align: start;
}

.action-container {
  width: 100%;
  height: 100%;
}
.action-container.disabled {
  opacity: 0.5;
}
.action-container .action-button {
  width: 100%;
  height: 100%;
  padding: var(--info-sheet-action-padding-top) var(--info-sheet-action-padding-end) var(--info-sheet-action-padding-bottom) var(--info-sheet-action-padding-start);
  background-color: var(--info-sheet-action-background-color);
  overflow: hidden;
  border-radius: var(--info-sheet-action-border-radius);
  outline: none;
  margin: 0;
}
.action-container .action-button:hover {
  background-color: var(--info-sheet-hover-color);
  cursor: pointer;
}
.action-container .action-button .button-content-container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: auto;
  -moz-column-gap: var(--info-sheet-action-column-gap);
       column-gap: var(--info-sheet-action-column-gap);
  align-items: center;
}
.action-container .action-button .button-content-container:not(.with-custom-column) {
  grid-template-columns: min-content 1fr;
}
.action-container .action-button .button-content-container.with-custom-column {
  grid-template-columns: min-content 1fr min-content;
}
.action-container .action-button .button-content-container .icon {
  grid-row: 1;
  grid-column: 1;
  color: var(--info-sheet-icon-color);
  font-size: var(--info-sheet-icon-font-size);
  min-width: 24px;
  min-height: 24px;
}
.action-container .action-button .button-content-container .action-text-container {
  grid-row: 1;
  grid-column: 2;
  width: 100%;
  overflow: hidden;
  position: relative;
  height: 20px;
}
.action-container .action-button .button-content-container .action-text-container .action-text {
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-large);
  line-height: var(--font-line-height-large);
  color: var(--info-sheet-action-text-color);
  margin: 0;
  text-align: var(--info-sheet-action-text-align);
  width: 100%;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  position: absolute;
}
.action-container .action-button .button-content-container .custom-column {
  grid-row: 1;
  grid-column: 3;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
