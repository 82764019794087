import {Inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {CONTEXT_SERVICE, ContextService} from '../../context/context.model';
import {UIUtilsServiceInterface, UI_UTILS_SERVICE} from '../../utils/ui-utils.service.interface';
import {ClinicalCase} from '../../yeti-protocol/clinical-case';
import {CreatePostAction, CreatePostState, ParentData} from '../create-content';
import {ParentType} from '../../yeti-protocol/chatter-api-schema';
import {Poll} from '../../yeti-protocol/chatter-api';

export enum Status {
  SUCCESS = 'success',
  PENDING = 'pending',
  ERROR = 'error',
}

@Injectable({
  providedIn: 'root'
})
export class CreateContactsFollowersContentService {

  constructor(
    private router: Router,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    @Inject(UI_UTILS_SERVICE) private uiUtilsService: UIUtilsServiceInterface,
  ) {
  }

  async openCreateContactsFollowersPostPage(
    postId?: string,
    action?: CreatePostAction,
    postContentText?: string,
    clinicalCase?: ClinicalCase,
    replaceUrl?: boolean,
    createPostState?: CreatePostState,
    poll?: Poll
  ): Promise<void> {

    const postIdUrlPart = postId ? `/${postId}` : '';
    const url = [this.contextService.currentContext.key, `create/post${postIdUrlPart}`].join('/');
    const navigation = this.uiUtilsService.getNavigationValueBasedOnPlatform(url);

    this.router.navigate([navigation], {
      state: {
        action: action,
        postContentText: postContentText,
        clinicalCase: clinicalCase,
        createPostState,
        poll
      },
      replaceUrl: replaceUrl ? true : false
    });
  }

  openCreateContactsFollowersCasePage(
    caseId?: string,
    replaceUrl?: boolean,
    facultyOnly?: boolean,
    shareCaseInPost?: boolean,
    parentsType?: ParentType): void {

    const caseIdUrlPart = caseId ? `/${caseId}` : '';
    const url = [this.contextService.currentContext.key, `create/case${caseIdUrlPart}`].join('/');
    const navigation = this.uiUtilsService.getNavigationValueBasedOnPlatform(url);

    this.router.navigate([navigation], {
      state: {
        facultyOnly: facultyOnly,
        shareCaseInPost: shareCaseInPost,
        parentsType: parentsType
      },
      replaceUrl: replaceUrl ? true : false
    });
  }

  openCreateGeneralPollPage(
    parentsData: Array<ParentData>,
    createPostState: CreatePostState,
    poll?: Poll,
    clinicalCase?: ClinicalCase,
    replaceUrl?: boolean
  ): void {

    const url = [this.contextService.currentContext.key, 'create/poll'].join('/');
    const navigation = this.uiUtilsService.getNavigationValueBasedOnPlatform(url);

    this.router.navigate([navigation], {
      state: {parentsData, createPostState, poll, clinicalCase},
      replaceUrl: replaceUrl ? true : false
    });
  }
}
