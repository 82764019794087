import {Component, EventEmitter, Inject, Input, Output} from '@angular/core';

// models
import {BaseUserProfile} from '../../users.model';

// services
import {UIUtilsServiceInterface, UI_UTILS_SERVICE} from 'src/app/services/utils/ui-utils.service.interface';
import {roundTotalCount} from 'src/app/services/utils/string-utils';
import {ActionSource} from 'src/app/services/yeti-protocol/tracking';

@Component({
  selector: 'app-user-profile-image-bubbles',
  templateUrl: './user-profile-image-bubbles.component.html',
  styleUrls: ['./user-profile-image-bubbles.component.scss'],
})
export class UserProfileImageBubblesComponent {

  @Input() label: string;
  @Input() users: Array<BaseUserProfile>;
  @Input() count: number;
  @Input() isCustomOpenPublicProfile = false;
  @Input() source: ActionSource | string;

  @Output() seeAll: EventEmitter<any> = new EventEmitter();
  @Output() openPublicUserProfile: EventEmitter<string> = new EventEmitter()

  constructor(
    @Inject(UI_UTILS_SERVICE) private uiUtilsService: UIUtilsServiceInterface
  ) {
  }

  getUniqueIdentifierForImage(_index: number, user: BaseUserProfile): string | undefined {
    return user ? user.profileImageUrl : undefined;
  }

  get roundTotalItemsNumber(): string {
    return roundTotalCount(this.count - this.users.length);
  }

  openApplaudsList(event: Event): void {
    this.uiUtilsService.stopEventPropagation(event);
    this.seeAll.emit();
  }

  openPublicProfile(userId: string): void {
    if (userId && userId !== 'hidden') {
      this.openPublicUserProfile.emit(userId);
    }
  }
}
