import { Component, EventEmitter, Input, Output, Inject } from '@angular/core';
import { UIUtilsServiceInterface, UI_UTILS_SERVICE } from 'src/app/services/utils/ui-utils.service.interface';

@Component({
  selector: 'app-post-type-info-tags',
  templateUrl: './post-type-info-tags.component.html',
  styleUrls: ['./post-type-info-tags.component.scss'],
})
export class PostTypeInfoTagsComponent {
  @Input() clinicalCase: boolean;
  @Input() INeedAdvice: boolean;
  @Input() poll: boolean;
  @Input() isCaseOwner = true;
  @Input() hasBookmarked: boolean;

  @Output() bookmark: EventEmitter<any> = new EventEmitter();

  constructor(
    @Inject(UI_UTILS_SERVICE) public uiUtilsService: UIUtilsServiceInterface
  ) {}

  onBookmark(): void {
    this.bookmark.emit();
  }
}

