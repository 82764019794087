import {Inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {CONTEXT_SERVICE, ContextService} from '../../context/context.model';
import {UIUtilsServiceInterface, UI_UTILS_SERVICE} from '../../utils/ui-utils.service.interface';
import {CreatePostAction, CreatePostState, ParentData} from '../create-content';
import {ClinicalCase} from '../../yeti-protocol/clinical-case';
import {Poll} from '../../yeti-protocol/chatter-api';

export enum Status {
  SUCCESS = 'success',
  PENDING = 'pending',
  ERROR = 'error',
}

@Injectable({
  providedIn: 'root'
})
export class CreateGeneralContentService {

  constructor(
    private router: Router,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    @Inject(UI_UTILS_SERVICE) private uiUtilsService: UIUtilsServiceInterface,
  ) {
  }

  async openCreateGeneralPostPage(
    parentsData: Array<ParentData>,
    action?: CreatePostAction,
    postContentText?: string,
    clinicalCase?: ClinicalCase,
    replaceUrl?: boolean,
    createPostState?: CreatePostState,
    poll?: Poll
  ): Promise<void> {
    const url = [this.contextService.currentContext.key, 'create/post'].join('/');
    const navigation = this.uiUtilsService.getNavigationValueBasedOnPlatform(url);

    this.router.navigate([navigation], {
      state: {
        action: action,
        postContentText: postContentText,
        clinicalCase: clinicalCase,
        parentsData: parentsData,
        createPostState,
        poll
      },
      replaceUrl: replaceUrl ? true : false
    });
  }

  openCreateGeneralCasePage(parentsData: Array<ParentData>, replaceUrl?: boolean, shareCaseInPost?: boolean): void {

    const url = [this.contextService.currentContext.key, 'create/case'].join('/');
    const navigation = this.uiUtilsService.getNavigationValueBasedOnPlatform(url);

    this.router.navigate([navigation], {
      state: {
        parentsData: parentsData,
        shareCaseInPost: shareCaseInPost
      },
      replaceUrl: replaceUrl ? true : false
    });
  }

  openCreateGeneralPollPage(
    parentsData: Array<ParentData>,
    createPostState: CreatePostState,
    poll?: Poll,
    clinicalCase?: ClinicalCase,
    replaceUrl?: boolean
  ): void {

    const url = [this.contextService.currentContext.key, 'create/poll'].join('/');
    const navigation = this.uiUtilsService.getNavigationValueBasedOnPlatform(url);

    this.router.navigate([navigation], {
      state: {parentsData, createPostState, poll, clinicalCase},
      replaceUrl: replaceUrl ? true : false
    });
  }
}
