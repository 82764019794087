import {PollState} from '../poll-details-state/poll-details-state.component';

export interface CaseBookmarkChanged {
  caseId: string,
  hasBookmarked: boolean
}

import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {Subscription} from 'rxjs';
import {ParentType} from '../../services/yeti-protocol/chatter-api-schema';
import {
  PostType,
  UpdatePostRequest,
  UpdatePostSuccessResponse,
  Case,
  PostListItem,
  PostOwner, Post, LikeUnlikeSuccessResponse
} from '../../services/yeti-protocol/chatter-api';
import {VIEW_TYPE} from '../message-container/message-container.component';
/* eslint-disable */
/* eslint-enable */
import {UserProfile} from 'src/app/services/yeti-protocol/auth/mi';
import {TranslatorData, TranslatorObject} from 'src/app/services/translator.model';
import {InfoSheetService} from 'src/app/modules/info-sheet/services/info-sheet.service';
import {AuthService} from 'src/app/services/auth/auth.service';
import {ToastMode, ToastService} from 'src/app/services/toast.service';
import {ImageAttachment, isAnyImage, isVideo} from 'src/app/services/attachments.model';
import {ActivatedRoute, Router} from '@angular/router';
import {CONTEXT_SERVICE, ContextService} from 'src/app/services/context/context.model';
import {TranslatorService} from 'src/app/services/translator.service';
import {BaseUserProfile, NUMBER_USER_IMG_BUBBLES_CARDS} from 'src/app/modules/users/users.model';
import {UIUtilsServiceInterface, UI_UTILS_SERVICE} from 'src/app/services/utils/ui-utils.service.interface';
import {InfoSheetActionItem} from 'src/app/modules/info-sheet/models/info-sheet-action-item.model';
import {ImageGalleryService} from 'src/app/modules/file-select/services/image-gallery.service';
import {GalleryConfig, ReviewGalleryModeData} from 'src/app/modules/file-select/services/image-gallery.model';
import {TRACKING_SERVICE, TrackingService} from 'src/app/services/tracking/tracking.model';
import {ContextDialogsUI, CONTEXT_DIALOGS_UI, ConfirmDialogData} from 'src/app/services/dialogs/dialogs.ui.interface';
import {FileSelectScope} from 'src/app/modules/file-select/services/file-select.service';
import {ClinicalCaseService} from 'src/app/services/case-library/clinical-case.service';
import {ClinicalCaseUIService} from 'src/app/services/case-library/clinical-case.ui.service';
import {ClinicalCase} from 'src/app/services/yeti-protocol/clinical-case';
import {PostActionContent, PostsActions, PostsDataService} from '../../services/posts/posts-data.service';
import {ChatterApiService} from '../../services/chatter-api.service';
import {ImageGalleryCaseStrategy} from 'src/app/services/image-gallery/image-gallery-case-service.service';
import {
  ActionSource,
  ActionTracked,
  GenericTrackingParam,
  TrackingRequest,
  TranslationAction
} from 'src/app/services/yeti-protocol/tracking';
import {VerificationStatus} from 'src/app/services/verification.model';
import {StreamItemType} from 'src/app/services/yeti-protocol/utils/enums';
import {Store} from '@ngxs/store';
import {MyNetworkStream} from 'src/app/state/my-network-stream/my-network-stream.actions';
import {getImagesWithCorrectUrlBasedOnWatermark} from '../../services/utils/image-attachments.utils';
import {AppNavController} from 'src/app/services/app-nav-controller.service';
import {IconLabelPosition} from '../../modules/buttons/base-button/base-button.component';
import {AppTranslationService} from 'src/app/services/app-translation.service';
import {DialogsUIService} from 'src/app/services/dialogs/dialogs.ui.service';
import {VerificationService} from '../../services/verification.service';

@Component({
  selector: 'app-case-card',
  templateUrl: './case-card.component.html',
  styleUrls: ['./case-card.component.scss'],
})
export class CaseCardComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() caseInPost: Case;
  @Input() isModerator: boolean;
  @Input() source: ActionSource;
  @Input() displayPostLastModifed = false;
  @Input() allowPin = false;

  @Output() caseClick: EventEmitter<PostListItem> = new EventEmitter();
  @Output() caseDeleted: EventEmitter<string> = new EventEmitter();
  @Output() bookmarkChanged: EventEmitter<CaseBookmarkChanged> = new EventEmitter();
  @Output() pin: EventEmitter<string> = new EventEmitter();
  @Output() unpin: EventEmitter<string> = new EventEmitter();
  @Output() userFollowed: EventEmitter<PostOwner> = new EventEmitter();

  IconLabelPosition = IconLabelPosition;

  infoSheetId = 'case-infosheet';
  VIEW_TYPE = VIEW_TYPE;
  caseFeatureStatusChanging: boolean;
  hideAlsoDiscussedIn = false;
  protected applauding: boolean;
  protected deletingCase: boolean;
  protected deletingOptionalMessage: boolean;
  protected reportingCase: boolean;
  protected user: UserProfile;
  protected isTranslated = false;
  protected originalTitle: string;
  protected originalDescription: string;
  protected originalLatestComment: string;
  protected originalPollQuestion: string;
  protected originalPollOptions: string[];
  protected translationLanguage: string;
  private userSubscription: Subscription;
  private postChangesSubscription: Subscription;
  readonly PollState = PollState;

  constructor(
    private infoSheetService: InfoSheetService,
    private authService: AuthService,
    private toast: ToastService,
    private imageGalleryService: ImageGalleryService,
    private router: Router,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    private translatorService: TranslatorService,
    private clinicalCaseService: ClinicalCaseService,
    private clinicalCaseUIService: ClinicalCaseUIService,
    @Inject(TRACKING_SERVICE) private trackingService: TrackingService,
    @Inject(CONTEXT_DIALOGS_UI) private ContextDialogs: ContextDialogsUI,
    @Inject(UI_UTILS_SERVICE) public uiUtilsService: UIUtilsServiceInterface,
    private postsDataService: PostsDataService,
    private chatterApiService: ChatterApiService,
    private imageGalleryCaseStrategy: ImageGalleryCaseStrategy,
    private route: ActivatedRoute,
    private store: Store,
    private appNavController: AppNavController,
    private appTranslationService: AppTranslationService,
    private dialogs: DialogsUIService,
    private verificationService: VerificationService
  ) {
  }

  ngOnInit(): void {

    this.userSubscription = this.authService.userProfileAsObservable.subscribe(userProfile => {
      this.user = userProfile;
    });

    this.postChangesSubscription = this.postsDataService.getPostsChangesStream().subscribe((postsActionContent: PostActionContent) =>
      this.executePostChange(postsActionContent));
  }

  ngAfterViewInit(): void {

    setTimeout(() => { // throws error if this is not put in timeout

      this.infoSheetId += `-${this.caseInPost?._id}`;
      this.infoSheetId = this.infoSheetService.generateNewInfoSheetIdIfDuplicate(this.infoSheetId);
    }, 0);
  }

  get caseImages(): ImageAttachment[] {
    return getImagesWithCorrectUrlBasedOnWatermark(
      this.caseInPost?.caseDiscussion?.media.mediaAttachments || [], this.caseInPost?.watermarked, this.caseInPost?.isAnonymous
    );
  }

  get totalMediaImages(): number {
    return this.caseInPost?.caseDiscussion?.media?.totalImages ?? 0;
  }

  get totalMediaVideos(): number {
    return this.caseInPost?.caseDiscussion?.media?.totalVideos ?? 0;
  }

  ngOnDestroy(): void {
    this.userSubscription?.unsubscribe();
    this.postChangesSubscription?.unsubscribe();
  }

  async openCase(event: Event): Promise<void> {
    if (event) {
      this.uiUtilsService.stopEventPropagation(event);
    }

    try {
      await this.verificationService.checkAndReloadCachedUserProfile();
    } catch (err) {
      console.error(err);
    }

    if (this.user?.verificationStatus === VerificationStatus.PENDING) {
      this.uiUtilsService.showPendingStateExplainerDialog(this.caseInPost?._id, PostType.case, this.source);
      return;
    }

    let url = '';

    switch (this.caseInPost?.parentType) {
      case ParentType.CHAT:
      case ParentType.USER:
        if (this.caseInPost?.type === PostType.case) {
          this.appNavController.openPrivatePublicSharedCase(this.caseInPost?._id, this.source);
          return;
        } else {
          this.appNavController.openPrivatePublicPost(this.caseInPost?._id, this.source);
          return;
        }
      default:
        url = '/' +
          [this.contextService.currentContext.key,
            `${this.caseInPost?.parentType?.toLowerCase()}/case`,
            this.caseInPost?._id].join('/');
        break;
    }

    if (this.source) {
      this.router.navigate([url], {queryParams: {source: this.source}});
    } else {
      this.router.navigateByUrl(url);
    }
  }

  pinPost(): void {
    this.pin.emit(this.caseInPost?._id);
  }

  unpinPost(): void {
    this.unpin.emit(this.caseInPost?._id);
  }

  editCase(): void {
    const url = '/' + [this.contextService.currentContext.key, 'case',
      this.caseInPost?.caseDiscussion?.caseId].join('/');

    if (this.source) {
      this.router.navigate([url], {queryParams: {source: this.source}});
    } else {
      this.router.navigateByUrl(url);
    }
  }

  async deleteCase(): Promise<void> {
    if (this.deletingCase) {
      return;
    }

    this.deletingCase = true;

    try {

      const shouldProceedWithDelete = await this.showDeletePostConfirmDialog();

      if (!shouldProceedWithDelete) {
        this.deletingCase = false;
        return;
      }

      this.chatterApiService.deletePost(this.caseInPost?._id).then(() => {
        this.caseDeleted.emit(this.caseInPost?._id);
        this.postsDataService.triggerPostDeletedAction(this.caseInPost);
      }).catch(err => {
        console.log(err);
      }).finally(() => {
        this.deletingCase = false;
      });

    } catch (err) {
      this.deletingCase = false;
    }
  }

  deleteOptionalMessage(): void {
    if (this.deletingOptionalMessage) {
      return;
    }
    this.deletingOptionalMessage = true;

    const data: UpdatePostRequest = {
      description: '',
      type: PostType.case,
      parentId: null,
      parentType: null
    };
    this.chatterApiService.updatePost(this.caseInPost?._id, data).then(async (postCaseRes: UpdatePostSuccessResponse) => {
      this.caseInPost = postCaseRes?.result as Case;
    }).catch(err => {
      console.log(err);
    }).finally(() => {
      this.deletingOptionalMessage = false;
    });
  }

  editPostAdviceNeeded(needAdviceValue: boolean): void {
    const data: UpdatePostRequest = {
      needAdvice: needAdviceValue,
      source: this.source,
      type: PostType.case,
      parentId: this.caseInPost?.parentId,
      parentType: this.caseInPost?.parentType
    }
    this.chatterApiService.updatePost(this.caseInPost?._id, data).then(async (postCaseRes: UpdatePostSuccessResponse) => {
      this.caseInPost = postCaseRes?.result as Case;
    }).catch(err => {
      console.log(err);
    });
  }

  get isCaseOwner(): boolean {
    return this.caseInPost?.owner?.userId === this.user?.id || this.caseInPost?.isOwnPost;
  }

  async onApplaudClicked(applaud: boolean): Promise<void> {

    try {
      await this.verificationService.checkAndReloadCachedUserProfile();
    } catch (err) {
      console.error(err);
    }

    if (this.user?.verificationStatus === VerificationStatus.PENDING) {
      this.uiUtilsService.showPendingStateExplainerDialog(this.caseInPost?._id, PostType.case, this.source);
      return;
    }

    if (this.applauding) {
      return;
    }

    this.applauding = true;

    if (applaud) {
      this.chatterApiService.likeOrUnlikeObject(this.caseInPost?._id, 'post', true).then((casePostResponse: LikeUnlikeSuccessResponse) => {
        this._updateLikesAndApplauded(casePostResponse);
      }).finally(() => {
        this.applauding = false;
      });
    } else {
      this.chatterApiService.likeOrUnlikeObject(this.caseInPost?._id, 'post', false).then((casePostResponse: LikeUnlikeSuccessResponse) => {
        this._updateLikesAndApplauded(casePostResponse);
      }).finally(() => {
        this.applauding = false;
      });
    }
  }

  _updateLikesAndApplauded(casePostResponse: LikeUnlikeSuccessResponse): void {
    const casePost = casePostResponse.result as Post;
    this.caseInPost.hasLiked = casePost?.hasLiked;
    this.caseInPost.numberOfLikes = casePost?.numberOfLikes;
    this.caseInPost.usersThatApplauded = casePost?.usersThatApplauded;
  }

  async openApplaudsList(): Promise<void> {
    this.ContextDialogs.showApplaudsList(this.caseInPost?._id, 'post');
  }

  onTranslate(): void {
    this.translationLanguage = this.user.translationLanguage || '';
    if (this.translationLanguage) {
      this.translateCase(this.translationLanguage)
    } else {
      this.ContextDialogs.showSelectLanguageDialog();
    }
  }

  translateCase(translationLanguage: string): void {
    if (!this.isTranslated) {
      this.saveOriginalValuesBeforeTranslation();
      const translationIndexes = {
        title: null,
        latestComment: null,
        pollQuestion: null,
        pollOptionStart: null
      };

      const dataToTranslateArray: Array<TranslatorObject> = new Array<TranslatorObject>();
      dataToTranslateArray.push({
        origin: '',
        title: this.caseInPost?.title,
        text: this.caseInPost?.description || '',
      });
      translationIndexes.title = 0;
      if (this.caseInPost?.latestComment) {
        dataToTranslateArray.push({
          origin: '',
          title: '',
          text: this.caseInPost?.latestComment.content,
        });
        translationIndexes.latestComment = 1;
      }
      if (this.caseInPost?.poll) {
        dataToTranslateArray.push({
          origin: '',
          title: '',
          text: this.caseInPost?.poll?.question
        });
        translationIndexes.pollQuestion = !!translationIndexes.latestComment ? 2 : 1;
        translationIndexes.pollOptionStart = !!translationIndexes.latestComment ? 3 : 2;
        dataToTranslateArray.push(...(this.originalPollOptions.map(text => ({origin: '', title: '', text}))));
      }

      this.trackTranslationAction(this.caseInPost?._id, this.caseInPost?.title, TranslationAction.translate, translationLanguage);
      this.translatorService.translateBatch('google', translationLanguage, dataToTranslateArray)
        .then((data: TranslatorData) => {
          if (translationIndexes.title !== null) {
            this.caseInPost.title = data.records[translationIndexes.title].title;
            this.caseInPost.description = data.records[translationIndexes.title].text;
          }
          if (translationIndexes.latestComment !== null) {
            this.caseInPost.latestComment.content = data.records[translationIndexes.latestComment].text;
          }
          if (translationIndexes.pollQuestion !== null) {
            this.caseInPost.poll.question = data.records[translationIndexes.pollQuestion].text;
          }
          if (translationIndexes.pollOptionStart !== null) {
            this.caseInPost.poll.options = this.caseInPost.poll.options.map((option, index) => ({
              ...option,
              answer: data.records[index + translationIndexes.pollOptionStart].text
            }))
          }
          this.isTranslated = true;
        }).catch(error => {
        this.toast.showWithMessage(error, 'app.common.error-default', ToastMode.ERROR);
      });
    } else {
      this.revertToOriginalValuesBeforeTranslation();
      this.isTranslated = false;
      this.trackTranslationAction(this.caseInPost?._id, this.caseInPost?.title, TranslationAction.backToOriginal, translationLanguage);
    }
  }

  revertToOriginalValuesBeforeTranslation(): void {
    this.caseInPost.title = this.originalTitle;
    this.caseInPost.description = this.originalDescription;
    if (this.caseInPost.latestComment) {
      this.caseInPost.latestComment.content = this.originalLatestComment;
    }
    if (this.caseInPost?.poll) {
      this.caseInPost.poll.question = this.originalPollQuestion;
      this.caseInPost.poll.options = this.caseInPost.poll.options.map((option, index) => ({
        ...option,
        answer: this.originalPollOptions[index]
      }))
    }
  }

  saveOriginalValuesBeforeTranslation(): void {
    this.originalTitle = this.caseInPost?.title;
    this.originalDescription = this.caseInPost?.description;
    this.originalLatestComment = this.caseInPost?.latestComment?.content;
    if (this.caseInPost?.poll) {
      this.originalPollQuestion = this.caseInPost?.poll?.question;
      this.originalPollOptions = this.caseInPost?.poll?.options.map(option => option.answer);
    }
  }

  reportCase(): void {
    if (this.reportingCase || !this.caseInPost?._id) {
      return;
    }
    this.reportingCase = true;

    this.chatterApiService.reportPostAsAbuse(this.caseInPost?._id).then(() => {
      this.toast.show('app.groups.case-reported-message');
    }).catch(err => {
      console.error(err);
    }).finally(() => {
      this.reportingCase = false;
    });
  }

  async bookmarkCase(): Promise<void> {
    if (this.caseInPost?.isAnonymous) {
      this.toast.show('app.groups.no-bookmarking-possibility', '', ToastMode.ERROR);
      return;
    }

    try {
      await this.verificationService.checkAndReloadCachedUserProfile();
    } catch (err) {
      console.error(err);
    }

    if (this.user?.verificationStatus === VerificationStatus.PENDING) {
      this.uiUtilsService.showPendingStateExplainerDialog(this.caseInPost?._id, PostType.case, this.source);
      return;
    }

    if (!this.caseInPost?.caseDiscussion?.hasBookmarked) {
      this.clinicalCaseService.bookmarkCase(this.caseInPost?.caseDiscussion?.caseId,
        this.source, this.caseInPost?.parentId, this.caseInPost?.parentType,
        this.caseInPost?.parentTitle, this.caseInPost?.parentTitle)
        .then((responseObj: ClinicalCase) => {
          if (responseObj) {
            this.updateBookmarkFields(responseObj);
          }
        })
        .catch(err => {
          console.error(err);
          this.showError(err?.error?.error?.message?.errfor?.module);
        })
    } else {
      const confirmModal = await this.clinicalCaseUIService.showRemoveBookmarkCaseConfirmDialog();
      const res = await confirmModal.onDidDismiss();

      if (res && res?.data.actionKey === 'yes') {
        this.clinicalCaseService.removeBookmarkedCase(this.caseInPost?.caseDiscussion?.caseId,
          this.source, this.caseInPost?.parentId, this.caseInPost?.parentType,
          this.caseInPost?.parentTitle, this.caseInPost?.parentTitle)
          .then((responseObj: ClinicalCase) => {
            if (responseObj) {
              this.updateBookmarkFields(responseObj);
            }
          })
          .catch(err => {
            console.error(err);
            this.showError(err?.error?.error?.message?.errfor?.module);
          });
      }
    }
  }

  updateBookmarkFields(obj: ClinicalCase): void {
    this.caseInPost.caseDiscussion.hasBookmarked = obj?.hasBookmarked;
    this.bookmarkChanged.emit({caseId: this.caseInPost.caseDiscussion.caseId, hasBookmarked: obj?.hasBookmarked});

    const streamItemType =
      this.caseInPost?.type === PostType.case
        ? StreamItemType.case
        : StreamItemType.post;

    const streamItem = {
      streamItemType: streamItemType,
      streamItem: this.caseInPost as any
    }

    this.store.dispatch(new MyNetworkStream.UpdateMyNetworkStreamItem(streamItem));
  }

  openGalleryModal(): void {
    const galeryImages = this.caseImages.map(image => {

      if (isVideo(image.mimeType)) {
        image.videoUrl = image?.fullUrl || null;
        image.previewUrl = this.caseInPost?.watermarked ? image?.watermarkedPreviewUrl : image?.previewUrl;
        image.fullUrl = this.caseInPost?.watermarked ? image?.fullWatermarkUrl : image?.fullUrl;
      }

      return image;
    });

    const galleryConfig: GalleryConfig = {
      images: galeryImages,
      allowImageEdit: this.isCaseOwner,
      reviewMode: this.isCaseOwner,
      scope: FileSelectScope.CASE,
      imageGalleryStrategy: this.imageGalleryCaseStrategy
    };

    this.imageGalleryService.openImageGallery(galleryConfig)
      .then(res => {
        if (res) {
          this._updateImageAttachments(res);
        }
      });
  }

  _updateImageAttachments(data: ReviewGalleryModeData): void {
    if (data && data.images) {
      const imagesMap: Record<string, ImageAttachment> = {};
      data.images.forEach(image => {
        imagesMap[image._id] = image;
      });
      this.caseImages.forEach(caseImage => {
        if (isAnyImage(caseImage.mimeType)) {
          const dataImage = imagesMap[caseImage._id];
          if (dataImage) {
            caseImage.watermarkedPreviewUrl = dataImage.watermarkedPreviewUrl;
            caseImage.fullWatermarkUrl = dataImage.fullUrl;
            caseImage.fullUrl = dataImage.fullUrl;
            caseImage.previewUrl = dataImage.previewUrl;
            caseImage.videoUrl = dataImage.videoUrl;
          }
        }
      });
    }
  }

  get usersThatApplauded(): Array<BaseUserProfile> {
    if (!this.caseInPost || !this.caseInPost?.usersThatApplauded?.length) {
      return [];
    }

    return this.caseInPost?.usersThatApplauded.slice(0, NUMBER_USER_IMG_BUBBLES_CARDS).map(userThatApplauded => {
      return {
        userId: userThatApplauded.userId,
        profileImageUrl: userThatApplauded.profileImageUrl
      }
    });
  }

  get displayProfileImageBubbles(): boolean {
    return this.caseInPost?.numberOfLikes && this.caseInPost?.numberOfLikes > 0
  }

  get infoSheetActions(): Array<InfoSheetActionItem> {
    const actions: Array<InfoSheetActionItem> = [];

    if (this.isCaseOwner) {
      if (this.caseInPost?.caseDiscussion?.isFeatured) {
        actions.push({
          id: 'unfeature_case_btn',
          icon: 'md-icon-medal-filled',
          textKey: 'app.common.unfeature-case',
          code: 'UNFEATURE_CASE_BTN',
          handler: () => {
            this.toggleCaseFeaturedStatus();
          }
        });
      } else {
        actions.push({
          id: 'feature_case_btn',
          icon: 'md-icon-medal',
          textKey: 'app.common.feature-case-on-profile',
          code: 'FEATURE_CASE_BTN',
          handler: () => {
            this.toggleCaseFeaturedStatus();
          }
        });
      }
    }

    if (this.allowPin && this.isModerator && !this.caseInPost?.isPinned) {
      actions.push({
        id: 'pin_post_btn',
        icon: 'md-icon-pin',
        textKey: 'app.common.pin-to-top',
        code: 'PIN_POST',
        handler: () => {
          this.pinPost();
        }
      });
    }

    if (this.allowPin && this.isModerator && this.caseInPost?.isPinned) {
      actions.push({
        id: 'unpin_post_btn',
        icon: 'md-icon-pin',
        textKey: 'app.common.unpin',
        code: 'UNPIN_POST',
        handler: () => {
          this.unpinPost();
        }
      });
    }

    if (this.isCaseOwner) {
      if (this.caseInPost?.needAdvice) {
        actions.push({
          id: 'remove_advice_btn',
          icon: 'md-icon-remove-advice-needed',
          textKey: 'app.groups.CreateGroupCase.remove-advice-needed',
          code: 'REMOVE_ADVICE_BTN',
          handler: () => {
            this.editPostAdviceNeeded(false);
          }
        });
      } else if (!this.caseInPost?.needAdvice) {
        actions.push({
          id: 'advice_needed_btn',
          icon: 'md-icon-advice-needed',
          textKey: 'app.groups.CreateGroupCase.advice-needed',
          code: 'ADVICE_NEEDED_BTN',
          handler: () => {
            this.editPostAdviceNeeded(true);
          }
        });
      }

      actions.push({
        id: 'edit_group_case_btn',
        icon: 'md-icon-pencil',
        textKey: 'app.common.edit-case',
        code: 'EDIT_CASE',
        handler: () => {
          this.editCase();
        }
      });

      actions.push({
        id: 'translate_case_btn',
        icon: 'md-icon-translate',
        textKey: 'app.common.translate',
        code: 'TRANSLATE_CASE',
        handler: () => {
          this.onTranslate();
        }
      });

      if (this.caseInPost?.description) {
        actions.push({
          id: 'delete_optional_message_btn',
          icon: 'md-icon-bin',
          textKey: 'app.common.deleteMessage',
          code: 'DELETE_OPTIONAL_MESSAGE',
          handler: () => {
            this.deleteOptionalMessage();
          }
        });
      }

      actions.push({
        id: 'delete_post_btn',
        icon: 'md-icon-bin',
        textKey: 'app.common.delete',
        code: 'DELETE_POST',
        handler: () => {
          this.deleteCase();
        }
      });
    } else {
      actions.push({
        id: 'translate_case_btn',
        icon: 'md-icon-translate',
        textKey: 'app.common.translate',
        code: 'TRANSLATE_CASE',
        handler: () => {
          this.onTranslate();
        }
      })

      if (!this.isModerator) {
        actions.push({
          id: 'report_post_btn',
          icon: 'md-icon-alert',
          textKey: 'app.common.flag-irrelevant',
          code: 'REPORT_POST',
          handler: () => {
            this.reportCase();
          }
        })
      }

      if (this.showDeleteButton) {
        actions.push({
          id: 'delete_post_btn',
          icon: 'md-icon-bin',
          textKey: 'app.common.delete',
          code: 'DELETE_POST',
          handler: () => {
            this.deleteCase();
          }
        })
      }
    }
    return actions;
  }

  get showDeleteButton(): boolean {
    return this.isCaseOwner || this.isModerator || this.caseInPost?.isGroupOwnerOrModerator ? true : false;
  }

  executePostChange(postsActionContent: PostActionContent): void {

    if (postsActionContent?.post?.parentId !== this.caseInPost?.parentId || postsActionContent?.post._id !== this.caseInPost._id) {
      return;
    }

    switch (postsActionContent.action) {
      case PostsActions.POST_EDITED:
        this.caseInPost = postsActionContent?.post as Case;
        break;
    }
  }

  get showLatestComment(): boolean {
    return this.caseInPost?.latestComment?.content?.length > 0 || this.caseInPost?.latestComment?.attachments?.length > 0;
  }

  get postDate(): string {
    return this.displayPostLastModifed ? this.caseInPost?.lastModified : this.caseInPost?.createdDate;
  }

  // if lastModified date is displayed insted of createdDate, the 'posted' verb should be removed
  get postedVerb(): boolean {
    return !this.displayPostLastModifed;
  }

  get isCrownUser(): boolean {
    return this.caseInPost?.createdByModerator ? true : false;
  }

  get isVerificationPendingUser(): boolean {
    return this.user?.verificationStatus === VerificationStatus.PENDING;
  }

  async handleActionFromPopover(action: InfoSheetActionItem): Promise<void> {

    try {
      await this.verificationService.checkAndReloadCachedUserProfile();
    } catch (err) {
      console.error(err);
    }

    if (this.user?.verificationStatus === VerificationStatus.PENDING) {
      this.uiUtilsService.showPendingStateExplainerDialog(this.caseInPost?._id, PostType.case, this.source);
      return;
    }

    switch (action.code) {
      case 'PIN_POST': {
        this.pinPost();
        break;
      }
      case 'UNPIN_POST': {
        this.unpinPost();
        break;
      }
      case 'EDIT_CASE': {
        this.editCase();
        break;
      }
      case 'DELETE_POST': {
        this.deleteCase();
        break;
      }
      case 'TRANSLATE_CASE': {
        this.onTranslate();
        break;
      }
      case 'REPORT_POST': {
        this.reportCase();
        break;
      }
      case 'DELETE_OPTIONAL_MESSAGE': {
        this.deleteOptionalMessage();
        break;
      }
      case 'REMOVE_ADVICE_BTN': {
        this.editPostAdviceNeeded(false);
        break;
      }
      case 'ADVICE_NEEDED_BTN': {
        this.editPostAdviceNeeded(true);
        break;
      }
      case 'FEATURE_CASE_BTN':
      case 'UNFEATURE_CASE_BTN':
        this.toggleCaseFeaturedStatus();
        break;
      default:
        throw new Error('Invalid action code!')
    }
  }

  trackTranslationAction(caseId: string, title: string, action: string, language: string): void {
    this.trackingService.trackTranslateAction(caseId, 'case', title, language, action, undefined, {source: this.source})
      .catch(_err => {
        console.error('Could not track translation click action.');
      });
  }

  private showError(msg: string): void {
    this.toast.showWithMessage(msg, 'app.common.error-default', ToastMode.ERROR);
  }

  async toggleCaseFeaturedStatus(): Promise<void> {

    if (this.caseFeatureStatusChanging) {
      return;
    }

    this.caseFeatureStatusChanging = true;

    if (this.caseInPost?.caseDiscussion?.isFeatured) {
      const shouldProceed = await this.uiUtilsService.showUnfeatureCasePrompt();

      if (shouldProceed) {
        this.clinicalCaseService.unfeatureCase(this.caseInPost?.caseDiscussion?.caseId).then(() => {
          this.caseInPost.caseDiscussion.isFeatured = false;
          this.postsDataService.triggerPostEditedAction(this.caseInPost);
          this.uiUtilsService.showCaseUnfeatureSuccessToast();
          this.trackActionCaseFeaturedStatusChanged(false);
        }).finally(() => {
          this.caseFeatureStatusChanging = false;
        })
      } else {
        this.caseFeatureStatusChanging = false;
      }
    } else {
      const shouldProceed = await this.uiUtilsService.showFeatureCasePrompt();

      if (shouldProceed) {
        this.clinicalCaseService.featureCase(this.caseInPost?.caseDiscussion?.caseId).then(() => {
          this.caseInPost.caseDiscussion.isFeatured = true;
          this.postsDataService.triggerPostEditedAction(this.caseInPost);
          this.uiUtilsService.showCaseFeatureSuccessToast();
          this.trackActionCaseFeaturedStatusChanged(true);
        }).finally(() => {
          this.caseFeatureStatusChanging = false;
        })
      } else {
        this.caseFeatureStatusChanging = false;
      }
    }
  }

  async openParentDetailsPage(event: Event): Promise<void> {

    if (event) {
      this.uiUtilsService.stopEventPropagation(event);
    }

    try {
      await this.verificationService.checkAndReloadCachedUserProfile();
    } catch (err) {
      console.error(err);
    }

    if (this.user?.verificationStatus === VerificationStatus.PENDING) {
      this.uiUtilsService.showPendingStateExplainerDialog(this.caseInPost?._id, PostType.case, this.source);
      return;
    }

    let url = '';

    if (this.caseInPost?.parentType === ParentType.GROUP && this.caseInPost?.parentId) {
      url = `${this.contextService.currentContext.homePath}/group/${this.caseInPost?.parentId}`;
    }

    if (this.caseInPost?.parentType === ParentType.EVENT && this.caseInPost?.parentId) {
      url = `${this.contextService.currentContext.homePath}/event/${this.caseInPost?.parentId}`;
    }

    if (!url?.length) {
      return;
    }

    if (this.source && this.source !== ActionSource.unspecified) {
      url = `${url}?source=${this.source}`;
    }

    this.router.navigateByUrl(url);
  }

  trackActionCaseFeaturedStatusChanged(featured: boolean): void {
    const paramsToTrack: GenericTrackingParam = {
      objectId: this.caseInPost?.caseDiscussion?.caseId,
      objectType: 'case'
    };

    if (this.source) {
      paramsToTrack.source = this.source;
    }

    const trackData: TrackingRequest = {
      action: featured ? ActionTracked.caseFeatured : ActionTracked.caseUnfeatured,
      params: paramsToTrack
    };

    this.trackingService.track(trackData).catch(_err => {
      console.error('Could not track case featured status changed action');
    });
  }

  onUserFollowed(postOwner: PostOwner): void {
    this.userFollowed.emit(postOwner);
  }

  async showDeletePostConfirmDialog(): Promise<boolean> {
    const deleteActionKey = 'delete';
    const cancelActionKey = 'cancel';

    const confirmDialogData: ConfirmDialogData = {
      componentProps: {
        title: {
          translationKey: this.appTranslationService.instant('app.components.Post.delete-post-confirm.title')
        },
        message: {
          translationKey: this.appTranslationService.instant('app.components.Post.delete-post-confirm.text')
        },
        actions: [
          {
            key: deleteActionKey,
            label: {
              translationKey: this.appTranslationService.instant('app.components.Post.delete-post-confirm.confirm-btn')
            },
            className: 'primary'
          },
          {
            key: cancelActionKey,
            label: {
              translationKey: this.appTranslationService.instant('app.components.Post.delete-post-confirm.cancel-btn')
            },
            className: 'secondary'
          }
        ]
      }
    }

    const confirmModal = await this.dialogs.createConfirmDialog(confirmDialogData);
    confirmModal.present();

    try {
      const res = await confirmModal.onDidDismiss();

      if (!res?.data?.actionKey) {
        return false;
      }

      if (res?.data?.actionKey === deleteActionKey) {
        return true;
      } else {
        return false;
      }

    } catch (err) {
      console.error(err);
      return false;
    }
  }

  get hasPoll(): boolean {
    return !!this.caseInPost?.poll;
  }

}
