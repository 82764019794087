// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --poll-state-border-radius: 0px;
}

.poll-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--mi-light-purple-1-color);
  padding: 10px 16px 20px;
  border-radius: var(--poll-state-border-radius);
}
.poll-container.feed-view {
  cursor: pointer;
}
.poll-container .actions-container {
  display: flex;
  justify-content: end;
  gap: 6px;
}
.poll-container .actions-container app-icon-button {
  --icon-button-background-height: 16px;
  --icon-button-background-width: 16px;
  --icon-button-icon-size: 10px;
}
.poll-container .content-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.poll-container .content-container .title {
  color: var(--mi-secondary-color-2);
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-medium);
}
.poll-container .content-container .title.poll-creation-title {
  width: 90%;
  color: var(--mi-black-color);
  font-size: var(--font-size-regular);
}
.poll-container .content-container .multiple-allowed {
  color: var(--mi-black-color);
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-light);
}
.poll-container .content-container .option {
  padding: 7px 13px;
  background-color: var(--mi-white-color);
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-light);
  border-radius: 15px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.poll-container .content-container .option.highlight-border {
  border: 1px solid var(--mi-primary-color);
}
.poll-container .content-container .option .option-fill {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: var(--mi-blue-color-23);
  transition: width 1s ease-in-out;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}
.poll-container .content-container .option .option-fill.rounded-right {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}
.poll-container .content-container .option .option-text {
  position: relative;
  z-index: 10;
  flex: 1;
  padding-right: 10px;
  color: var(--mi-black-color);
}
.poll-container .content-container .option .option-percentage-container {
  display: flex;
  gap: 14px;
  align-items: center;
}
.poll-container .content-container .option .option-percentage-container app-icon {
  --icon-size: 15px;
  color: var(--mi-grey-color-36);
}
.poll-container .content-container .option .option-percentage-container .option-percentage {
  position: relative;
  z-index: 10;
  color: var(--mi-secondary-color-2);
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-large);
}
.poll-container .content-container .option.clickable {
  cursor: pointer;
}
.poll-container .content-container .option.selected {
  background-color: var(--mi-blue-color-23);
}
.poll-container .content-container .vote-btn {
  height: 55px;
  --background: var(--mi-primary-color);
  --border-color: var(--mi-primary-color);
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-regular);
}
.poll-container .content-container .vote-btn.button-disabled {
  --background: var(--mi-color-text-lite);
  --border-color: var(--mi-color-text-lite);
  --background-hover: var(--mi-color-text-lite);
  opacity: 1;
}
.poll-container .content-container .additional-info-container {
  color: var(--mi-black-color);
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-light);
  display: flex;
  justify-content: space-between;
}
.poll-container .content-container .additional-info-container .total-votes.clickable-votes {
  cursor: pointer;
  color: var(--mi-blue-color-13);
}
.poll-container .content-container .additional-info-container .total-votes.clickable-votes:hover {
  text-decoration: underline;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
