import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { MyNetworkStreamTopStateModel } from './my-network-stream-top.model';
import { Injectable } from '@angular/core';
import { insertItem, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { MyStreamService } from 'src/app/services/my-stream.service';
import {
  CaseInResponse,
  MyStreamSuccessResponse,
  PostInResponse,
  ResponseStreamItem,
  StreamFilter
} from 'src/app/services/yeti-protocol/my-stream';
import { StreamStateType } from 'src/app/services/yeti-protocol/utils/enums';
import { firstValueFrom } from 'rxjs';
import { MyNetworkStreamTop } from './my-network-stream-top.actions';
import { MY_NETWORK_STREAM_TOP_STATE_KEY } from '../state-constants';
import * as _ from 'lodash';

const MY_NETWORK_STREAM_TOP_STATE_TOKEN = new StateToken<MyNetworkStreamTopStateModel>(
  MY_NETWORK_STREAM_TOP_STATE_KEY
);

@State({
  name: MY_NETWORK_STREAM_TOP_STATE_TOKEN,
  defaults: {
    streamItems: [],
    streamFilters: [],
    loading: false,
    lastFetchLength: 0
  },
})
@Injectable()
export class MyNetworkStreamTopState {

  constructor(private myStreamService: MyStreamService) { }

  @Selector()
  static state(state: MyNetworkStreamTopStateModel): MyNetworkStreamTopStateModel {
    return state;
  }

  @Selector()
  static streamItems(state: MyNetworkStreamTopStateModel): Array<ResponseStreamItem> {
    return state.streamItems;
  }

  @Selector()
  static streamFilters(state: MyNetworkStreamTopStateModel): Array<StreamFilter> {
    return state.streamFilters;
  }

  @Selector()
  static loading(state: MyNetworkStreamTopStateModel): boolean {
    return state.loading;
  }

  @Action(MyNetworkStreamTop.FetchMyNetworkStreamTopItems)
  async fetchMyNetworkStreamTopItems(
    ctx: StateContext<MyNetworkStreamTopStateModel>,
    payload: MyNetworkStreamTop.FetchMyNetworkStreamTopItems
  ): Promise<void> {
    try {
      ctx.patchState({
        loading: true,
      });

      const myNetworkStreamRes = (await firstValueFrom(this.myStreamService.fetchStream(
        payload?.payloadParams?.pageIndex,
        payload?.payloadParams?.pageSize,
        StreamStateType.networkTop,
        false,
        payload?.payloadParams?.filter,
        false
      ))) as MyStreamSuccessResponse;


      ctx.dispatch(
        new MyNetworkStreamTop.FetchMyNetworkStreamTopItemsSuccess(
          payload?.payloadParams,
          myNetworkStreamRes
        )
      );
    } catch (err) {

      ctx.dispatch(
        new MyNetworkStreamTop.FetchMyNetworkStreamTopItemsFailed()
      );

      throw err;
    }
  }

  @Action(MyNetworkStreamTop.FetchMyNetworkStreamTopItemsSuccess)
  fetchMyNetworkStreamTopItemsSuccess(
    ctx: StateContext<MyNetworkStreamTopStateModel>,
    action: MyNetworkStreamTop.FetchMyNetworkStreamTopItemsSuccess
  ): void {
    const state = ctx.getState();

    if (action.payloadParams.pageIndex === 0) {
      ctx.patchState({
        streamItems: action?.response?.result,
        streamFilters: action?.response?.meta?.filters,
        lastFetchLength: action?.response.result?.length,
        loading: false,
      });
    } else {
      ctx.patchState({
        streamItems: [...state.streamItems, ...action?.response?.result],
        streamFilters: action?.response?.meta?.filters,
        lastFetchLength: action?.response?.result?.length,
        loading: false,
      });
    }
  }

  @Action(MyNetworkStreamTop.FetchMyNetworkStreamTopItemsFailed)
  fetchMyNetworkStreamTopItemsFailed(
    ctx: StateContext<MyNetworkStreamTopStateModel>
  ): void {
    ctx.patchState({
      streamItems: [],
      streamFilters: [],
      lastFetchLength: 0,
      loading: false,
    });
  }

  @Action(MyNetworkStreamTop.InsertMyNetworkStreamTopItemBeforeIndex)
  insertMyNetworkStreamTopItemBeforeIndex(
    ctx: StateContext<MyNetworkStreamTopStateModel>,
    action: MyNetworkStreamTop.InsertMyNetworkStreamTopItemBeforeIndex
  ): void {

    ctx.setState(
      patch<MyNetworkStreamTopStateModel>({
        streamItems: insertItem<ResponseStreamItem>(action.item, action.index)
      })
    );
  }

  @Action(MyNetworkStreamTop.RemoveMyNetworkStreamTopItem)
  removeMyNetworkStreamTopItem(
    ctx: StateContext<MyNetworkStreamTopStateModel>,
    action: MyNetworkStreamTop.RemoveMyNetworkStreamTopItem
  ): void {

    const state = ctx.getState();

    const itemIndex = state.streamItems.findIndex(item =>
      (item?.streamItem as any)?._id === (action?.item?.streamItem as any)?._id &&
      (item?.streamItem as any)?.parentType === (action?.item?.streamItem as any)?.parentType &&
      (item?.streamItem as any)?.parentId === (action?.item?.streamItem as any)?.parentId);

    if (itemIndex === -1) {
      return;
    }

    ctx.setState(
      patch<MyNetworkStreamTopStateModel>({
        streamItems: removeItem<ResponseStreamItem>(itemIndex)
      })
    );
  }

  @Action(MyNetworkStreamTop.UpdateMyNetworkStreamTopItem)
  updateMyNetworkStreamTopItem(
    ctx: StateContext<MyNetworkStreamTopStateModel>,
    action: MyNetworkStreamTop.UpdateMyNetworkStreamTopItem
  ): void {

    ctx.setState(
      patch<MyNetworkStreamTopStateModel>({
        streamItems: updateItem<ResponseStreamItem>(item =>
          (item?.streamItem as any)?._id === (action?.item?.streamItem as any)?._id &&
          (item?.streamItem as any)?.parentType === (action?.item?.streamItem as any)?.parentType &&
          (item?.streamItem as any)?.parentId === (action?.item?.streamItem as any)?.parentId,
          existing => ({
            streamItemType: existing.streamItemType,
            streamItem: _.merge({}, existing.streamItem, action.item.streamItem) as any
          })
        )
      })
    );
  }

  @Action(MyNetworkStreamTop.UpdateMyNetworkStreamTopItemsOwnerFollowingStatus)
  updateMyNetworkStreamTopItemsOwnerFollowingStatus(
    ctx: StateContext<MyNetworkStreamTopStateModel>,
    action: MyNetworkStreamTop.UpdateMyNetworkStreamTopItemsOwnerFollowingStatus): void {

    const state = ctx.getState();

    const streamItems = [...state.streamItems]?.map(item => {

      const itemCopy = { ...item };

      if ((item as PostInResponse | CaseInResponse)?.streamItem?.owner?.userId === action?.userId) {
        (itemCopy as PostInResponse | CaseInResponse).streamItem.owner.isFollower = action?.isFollower;
        return itemCopy;
      }

      return itemCopy;
    });

    ctx.setState(
      patch<MyNetworkStreamTopStateModel>({
        streamItems: streamItems
      })
    );
  }
}
