import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IonicModule } from '@ionic/angular';

import { TranslateModule } from '@ngx-translate/core';

import { MIPipesModule } from 'src/app/pipes/pipes.module';
import { AoEventLinkButtonComponent } from './ao-event-link-button/ao-event-link-button.component';
import { ApplaudButtonComponent } from './applaud-button/applaud-button.component';
import { BookmarkButtonComponent } from './bookmark-button/bookmark-button.component';
import { BackButtonComponent } from './back-button/back-button.component';
import { CommentButtonComponent } from './comment-button/comment-button.component';
import { ContextButtonComponent } from './context-button/context-button.component';
import { CopyButtonComponent } from './copy-button/copy-button.component';
import { CreateGroupButtonComponent } from './create-group-button/create-group-button.component';
import { GroupCaseButtonComponent } from './group-case-button/group-case-button.component';
import { GroupDocumentButtonComponent } from './group-document-button/group-document-button.component';
import { GroupImageButtonComponent } from './group-image-button/group-image-button.component';
import { RecommendButtonComponent } from './recommend-button/recommend-button.component';
import { ReplyButtonComponent } from './reply-button/reply-button.component';
import { SettingsButtonComponent } from './settings-button/settings-button.component';
import { SettingsGearButtonComponent } from './settings-gear-button/settings-gear-button.component';
import { ShareButtonComponent } from './share-button/share-button.component';
import { PublicViewButtonComponent } from './public-view-button/public-view-button.component';
import { ExportButtonComponent } from './export-button/export-button.component'
import { TranslateButtonComponent } from './translate-button/translate-button.component';
import { FeedbackButtonComponent } from './feedback-button/feedback-button.component';
import { FilterButtonComponent } from './filter-button/filter-button.component';
import { CustomizeButtonComponent } from './customize-button/customize-button.component';
import { DownloadAppButtonComponent } from './download-app-button/download-app-button.component';
import { WatermarkButtonComponent } from './watermark-button/watermark-button.component';
import { VideoButtonComponent } from './video-button/video-button.component';
import { AddButtonComponent } from './add-button/add-button.component';
import { ReorderButtonComponent } from './reorder-button/reorder-button.component';
import { FeatureCaseButtonComponent } from './feature-case-button/feature-case-button.component';
import { FollowUserButtonComponent } from './follow-user-button/follow-user-button.component';
import { SaveButtonComponent } from './save-button/save-button.component';
import {OutlinkButtonComponent} from './outlink-button/outlink-button.component';
import { IconComponent } from '../icon/icon.component';
import {OutlinkLabelComponent} from '../../buttons/outlink-label/outlink-label.component';
import {CreatePollButtonComponent} from './create-poll-button/create-poll-button.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    MIPipesModule,
    TranslateModule,
    IconComponent
  ],
  exports: [
    AoEventLinkButtonComponent,
    ApplaudButtonComponent,
    BackButtonComponent,
    BookmarkButtonComponent,
    CommentButtonComponent,
    ContextButtonComponent,
    CopyButtonComponent,
    CreateGroupButtonComponent,
    GroupCaseButtonComponent,
    GroupDocumentButtonComponent,
    GroupImageButtonComponent,
    VideoButtonComponent,
    RecommendButtonComponent,
    ReplyButtonComponent,
    SettingsButtonComponent,
    SettingsGearButtonComponent,
    ShareButtonComponent,
    PublicViewButtonComponent,
    ExportButtonComponent,
    TranslateButtonComponent,
    FeedbackButtonComponent,
    FilterButtonComponent,
    CustomizeButtonComponent,
    DownloadAppButtonComponent,
    WatermarkButtonComponent,
    AddButtonComponent,
    ReorderButtonComponent,
    FeatureCaseButtonComponent,
    FollowUserButtonComponent,
    SaveButtonComponent,
    OutlinkButtonComponent,
    OutlinkLabelComponent,
    CreatePollButtonComponent
  ],
  declarations: [
    AoEventLinkButtonComponent,
    ApplaudButtonComponent,
    BackButtonComponent,
    BookmarkButtonComponent,
    CommentButtonComponent,
    ContextButtonComponent,
    CopyButtonComponent,
    CreateGroupButtonComponent,
    GroupCaseButtonComponent,
    GroupDocumentButtonComponent,
    GroupImageButtonComponent,
    VideoButtonComponent,
    RecommendButtonComponent,
    ReplyButtonComponent,
    SettingsButtonComponent,
    SettingsGearButtonComponent,
    ShareButtonComponent,
    PublicViewButtonComponent,
    ExportButtonComponent,
    TranslateButtonComponent,
    FeedbackButtonComponent,
    FilterButtonComponent,
    CustomizeButtonComponent,
    DownloadAppButtonComponent,
    WatermarkButtonComponent,
    AddButtonComponent,
    BookmarkButtonComponent,
    ReorderButtonComponent,
    FeatureCaseButtonComponent,
    FollowUserButtonComponent,
    SaveButtonComponent,
    OutlinkButtonComponent,
    OutlinkLabelComponent,
    CreatePollButtonComponent
  ]
})
export class MIButtonsModule {
}
