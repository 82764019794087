// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --side-padding: 20px;
}

.item-container {
  width: 100%;
  display: inline-block;
  position: relative;
}
.item-container .group-container {
  display: grid;
  grid-template-columns: min-content auto min-content;
  grid-template-rows: auto;
  -moz-column-gap: 15px;
       column-gap: 15px;
  padding-left: var(--side-padding);
  padding-right: var(--side-padding);
  align-items: center;
}
.item-container .group-container .image-container {
  grid-row: 1;
  grid-column: 1;
  cursor: pointer;
}
.item-container .group-container .image-container app-group-image ::ng-deep {
  --group-image-size: 70px;
  --group-image-border-radius: 5px;
}
.item-container .group-container .content-container {
  grid-row: 1;
  grid-column: 2;
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  row-gap: 2px;
  height: 70px;
  width: 100%;
}
.item-container .group-container .content-container .title-container {
  position: relative;
  width: 100%;
  height: 40px;
  overflow: hidden;
  cursor: pointer;
}
.item-container .group-container .content-container .title-container .wrapper {
  position: absolute;
  top: 0;
  left: 0;
}
.item-container .group-container .content-container .title-container .wrapper app-multiline-text-with-ellipsis {
  --multiline-text-with-ellipsis-max-height: 47px;
}
.item-container .group-container .content-container .title-container .wrapper app-multiline-text-with-ellipsis ::ng-deep {
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-medium);
  color: var(--mi-secondary-color-2);
}
.item-container .group-container .content-container .owner-container {
  position: relative;
  width: 100%;
  height: 14px;
  overflow: hidden;
  cursor: pointer;
}
.item-container .group-container .content-container .owner-container .owner-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.item-container .group-container .content-container .owner-container .owner-wrapper .owner-firstname-lastname {
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-regular);
  color: var(--mi-secondary-color-2);
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.item-container .group-container .content-container .visibility-and-members-count-container {
  display: grid;
  grid-template-rows: auto;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  -moz-column-gap: 15px;
       column-gap: 15px;
  align-items: center;
  width: 100%;
  cursor: pointer;
}
.item-container .group-container .content-container .visibility-and-members-count-container .group-visibility-container .visibility-wrapper {
  display: flex;
  justify-items: start;
  align-items: center;
}
.item-container .group-container .content-container .visibility-and-members-count-container .group-visibility-container .visibility-wrapper app-icon {
  --icon-size: 11px;
  color: var(--mi-secondary-color-2);
  padding-right: 2px;
}
.item-container .group-container .content-container .visibility-and-members-count-container .group-visibility-container .visibility-wrapper p {
  margin: 0;
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-regular);
  color: var(--mi-secondary-color-2);
  white-space: nowrap;
  line-height: 1;
}
.item-container .group-container .content-container .visibility-and-members-count-container .members-count-container .group-total-members {
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-regular);
  color: var(--mi-secondary-color-2);
  white-space: nowrap;
  display: flex;
  justify-content: start;
  align-items: center;
}
.item-container .group-container .content-container .visibility-and-members-count-container .members-count-container .group-total-members app-icon {
  --icon-size: 15px;
  padding-right: 1px;
}
.item-container .group-container .content-container .visibility-and-members-count-container .members-count-container .group-total-members span {
  line-height: 1;
  height: 12px;
  padding-top: 0.5px;
}
.item-container .group-container .button-container {
  grid-row: 1;
  grid-column: 3;
}
.item-container .group-container .button-container .owner-actions-button {
  cursor: pointer;
}

.item-container:after {
  position: absolute;
  content: "";
  border-bottom: 1px solid var(--mi-grey-background);
  width: 100%;
  bottom: -1px;
  left: 65px;
}

.item-container.hide-border:after {
  border-bottom: 0 !important;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
