import { Component, EventEmitter, Input, Output } from '@angular/core';

// services
import { InfoSheetService } from '../../services/info-sheet.service';

@Component({
  selector: 'app-info-sheet-action',
  templateUrl: './info-sheet-action.component.html',
  styleUrls: ['./info-sheet-action.component.scss'],
})
export class InfoSheetActionComponent {

  @Input() icon: string;
  @Input() actionText: string;
  @Input() notificationIcon: string = null;
  @Input() isBeta = false;
  @Input() infoSheetId: string;
  @Input() disabled = false;

  @Output() actionClick: EventEmitter<any> = new EventEmitter();

  constructor(
    private infoSheetService: InfoSheetService
  ) { }

  onActionClicked(): void {
    this.infoSheetService.actionClicked(this.infoSheetId);
    this.actionClick.emit();
  }
}
