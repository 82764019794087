import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[app-limit-input]'
})
export class LimitInputDirective {

  @Input() maxLength = 1000;
  @Input() value: string;

  @Output() valueChanged: EventEmitter<string> = new EventEmitter();

  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event'])
  onInput(): void {
    const inputElement = this.el.nativeElement;
    const inputValue = inputElement.value;

    if (inputValue.length > this.maxLength) {
      inputElement.value = inputValue.substring(0, this.maxLength);
    }
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    const inputElement = this.el.nativeElement;
    const inputValue = inputElement.value;

    if (event.key === 'Backspace') {
      return;
    }

    if (['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes(event.key)) {
      return;
    }

    if (event.key === 'Home' || event.key === 'End') {
      return;
    }

    if ((event.ctrlKey || event.metaKey) && event.key === 'a') {
      return;
    }

    if ((event.ctrlKey || event.metaKey) && event.key === 'z') {
      return;
    }

    if ((event.ctrlKey || event.metaKey) && event.key === 'v') {
      return;
    }

    if ((event.ctrlKey || event.metaKey) && event.key === 'c') {
      return;
    }

    if (inputValue.length >= this.maxLength) {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent): void {

    const realInput = (event?.currentTarget as any)?.querySelector('input, textarea');
    const selectionStart = realInput?.selectionStart;
    const selectionEnd = realInput?.selectionEnd;

    const inputElement = this.el.nativeElement;
    const inputValue = inputElement.value;
    const maxLengthRemaining = this.maxLength - inputValue.length;

    const pasteText = event.clipboardData?.getData('text') ?? '';

    if (pasteText) {
      const pasteTextToAdd = pasteText.slice(0, maxLengthRemaining);

      const beforeText = inputValue.substring(0, selectionStart);
      const afterText = inputValue.substring(selectionEnd);

      this.value = beforeText + pasteTextToAdd + afterText;
      this.valueChanged.emit(this.value);

      setTimeout(() => {
        const selectionRange = selectionStart + (pasteTextToAdd?.length || 0);
        realInput.setSelectionRange(selectionRange, selectionRange);
      }, 250);

      event.preventDefault();
    }
  }

}
