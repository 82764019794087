import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';

import {UserFollowingService} from '../../services/followingService/user-following.service';
import {UserFollowersSuccessResponse} from '../../services/yeti-protocol/user-following';
import {LoadingController, ModalController} from '@ionic/angular';
import {PublicProfile, ShortPublicProfile} from '../../services/yeti-protocol/public-profile';
import {AuthService} from 'src/app/services/auth/auth.service';
import {ResponsiveUtilsService} from '../../services/utils/responsive-utils.service';
import {UserFollowersDialogComponent} from '../../dialogs/user-followers-dialog/user-followers-dialog.component';
import {AppTranslationService} from '../../services/app-translation.service';
import {ConnectionsApiService} from '../../services/messenger/connections-api.service';
import {UserProfile} from '../../services/yeti-protocol/auth/mi';
import {QRCodeService} from 'src/app/services/qr-code/qr-code.service';
import {UserImageService} from 'src/app/services/user-image-service/user-image.service';
import {Subscription} from 'rxjs';
import {BaseUserProfile, NUMBER_USER_IMG_BUBBLES_PROFILE_PAGE} from 'src/app/modules/users/users.model';
import {AuthenticationAOConfig, Platform} from 'src/config/config.model';
import {UIUtilsServiceInterface, UI_UTILS_SERVICE} from 'src/app/services/utils/ui-utils.service.interface';
import {AppNavController} from 'src/app/services/app-nav-controller.service';
import {NavControllerService} from 'src/app/services/nav-controller.service';
import {roundTotalCount} from 'src/app/services/utils/string-utils';
import {DialogsUIService} from 'src/app/services/dialogs/dialogs.ui.service';
import {CONTEXT_DIALOGS_UI, ContextDialogsUI} from 'src/app/services/dialogs/dialogs.ui.interface';
import {CONTEXT_SERVICE, ContextService} from 'src/app/services/context/context.model';
import {SharingUIService} from 'src/app/services/sharing/sharing-ui.service';
import {ConnectionRequestsService} from 'src/app/services/messenger/connection-requests.service';
import {PeopleYouMayKnow} from 'src/app/state/people-you-may-know/people-you-may-know.actions';
import {Store} from '@ngxs/store';
import {ActionSource} from '../../services/yeti-protocol/tracking';
import {TRACKING_SERVICE, TrackingService, TrackingSourceParams} from '../../services/tracking/tracking.model';
import appConfig from 'src/config/config';

interface UserProfileSettingsComponentConfig {
  platform: Platform;
  authenticationAO: AuthenticationAOConfig;
  link: any
}

@Component({
  selector: 'app-profile-card',
  templateUrl: './profile-card.component.html',
  styleUrls: ['./profile-card.component.scss'],
})
export class ProfileCardComponent implements OnChanges, OnInit, OnDestroy {

  // @Input() disableProfileOpen: boolean;
  @Input() isCustomOpenMessenger = false;
  // @Input() userProfile: any;
  @Input() userProfile: PublicProfile;
  @Input() showFollowButton: boolean;
  @Input() hideOptionIcons: boolean;
  @Input() hideEyeIcon: boolean;
  @Input() showChangeProfileImageButton = false;
  @Input() showTopContributorBadge = false;
  @Input() parentSource = ActionSource.unspecified;
  @Input() source: ActionSource | string;
  @Input() showMyFullAoProfileButton = false;
  @Input() showButtons = true;

  @Output() follow: EventEmitter<any> = new EventEmitter();
  @Output() reloadUserProfile: EventEmitter<any> = new EventEmitter();
  @Output() openMessenger: EventEmitter<string> = new EventEmitter();
  @Output() userProfileOpened: EventEmitter<void> = new EventEmitter();
  @Output() openProfileActions: EventEmitter<any> = new EventEmitter();

  config: UserProfileSettingsComponentConfig = appConfig;
  platform = appConfig.platform;
  platformOptions = Platform;
  currentUserProfile: UserProfile;
  userFollowers: Array<ShortPublicProfile>;
  imageUrl: string = null;
  totalItemsCount: number;
  totalItemsRounded: string;
  userImageSubscription: Subscription
  private readonly start = 0;
  private requestConnectionPending: boolean;

  constructor(
    private appNavController: AppNavController,
    private appTranslationService: AppTranslationService,
    private authService: AuthService,
    private connectionsApiService: ConnectionsApiService,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    private modalController: ModalController,
    private dialogs: DialogsUIService,
    private qrCodeService: QRCodeService,
    private navController: NavControllerService,
    private responsiveUtilsService: ResponsiveUtilsService,
    private userFollowingService: UserFollowingService,
    private userImageService: UserImageService,
    private loadingController: LoadingController,
    private sharingUIService: SharingUIService,
    @Inject(CONTEXT_DIALOGS_UI) private contextDialogs: ContextDialogsUI,
    @Inject(UI_UTILS_SERVICE) private uiUtilsService: UIUtilsServiceInterface,
    private connectionRequestsService: ConnectionRequestsService,
    private store: Store,
    @Inject(TRACKING_SERVICE) private trackingService: TrackingService,
  ) {
  }

  ngOnInit(): void {
    this.source = this.source || this._source;
  }

  ngOnDestroy(): void {
    this.userImageSubscription?.unsubscribe();
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.userProfile) {
      await this.initializeComponent();
    }
  }

  openMyFullAoProfile(): void {
    this.trackClickAction('myFullAoProfileButton', 'outlink', 'aoProfilePage')
    if (!this.config.authenticationAO) {
      return;
    }
    const AOLink = this.config.authenticationAO.updateUserDataAoLink;
    if (AOLink) {
      this.authService.openAoLink(AOLink);
    }
  }


  trackClickAction(objectId: string, objectType: string, objectTitle: string): void {
    const sourceParams: TrackingSourceParams = {
      source: this.source,
    };
    this.trackingService
      .trackGenericClickedAction(
        objectId,
        objectType,
        objectTitle,
        sourceParams
      )
      .catch(_err => {
        console.error('Could not track ' + objectId + ' clicked action');
      });
  }

  async initializeComponent(): Promise<void> {
    this.authService.userProfileAsObservable.subscribe(userProfile => {
      this.currentUserProfile = userProfile;
    });
    this.userImageSubscription = this.userImageService.userImageUrl$.subscribe(imageUrl => {
      if (this.imageUrl !== null)
        this.imageUrl = imageUrl;
    })
    if (this.userProfile && this.userProfile.userId) {
      if (this.imageUrl === null) {
        this.imageUrl = this.userProfile.profileImageUrl;
      }
      this.userFollowingService.getUserFollowers(this.userProfile.userId, this.start, NUMBER_USER_IMG_BUBBLES_PROFILE_PAGE)
        .then(response => {
          if (response) {
            const list = (response as UserFollowersSuccessResponse).result;
            this.userFollowers = list;
            this.totalItemsCount = (response as UserFollowersSuccessResponse).totalItemsCount;
            this.roundTotalItemsNumber();
          }
        });
    }
  }

  get followButtonText(): string {

    if (!this.userProfile) {
      return '';
    }

    if (!this.userProfile.isFollower) {
      return this.appTranslationService.instant('app.common.follow');
    } else {
      return this.appTranslationService.instant('app.common.unfollow');
    }
  }

  get usersFollowers(): Array<BaseUserProfile> {
    if (!this.userFollowers || !this.userFollowers?.length) {
      return [];
    }

    return this.userFollowers.map(follower => {
      return {
        userId: follower.userId,
        profileImageUrl: follower.profileImageUrl
      }
    });
  }

  get displayProfileImageBubbles(): boolean {
    return this.userFollowers && this.userFollowers.length > 0;
  }

  get tooltipTextSettings(): string {
    return this.appTranslationService.instant('app.userProfile.Settings.settings');
  }

  get tooltipTextQRCode(): string {
    return this.appTranslationService.instant('app.pagesProfileQRCodePage.qr-code');
  }

  get tooltipTextSeeProfile(): string {
    return this.appTranslationService.instant('app.userProfile.Settings.see-public-profile');
  }

  get showPublicViewIcon(): boolean {
    return !this.hideOptionIcons && !this.showFollowButton && !this.hideEyeIcon;
  }

  getShowFollowButton(): boolean {
    const status = this.userProfile.connectionInfo?.status;
    const isInitiator = this.userProfile.connectionInfo?.isInitiator;
    if (this.currentUserProfile) {
      return this.showFollowButton
        && !(status === 'connected' || (status === 'pending' && isInitiator))
        || !this.currentUserProfile.isVerified;
    }
  }

  onOpenMessenger(connectionId: string): void {
    if (this.isCustomOpenMessenger) {
      return this.openMessenger.emit(connectionId);
    }
    this.appNavController.openMessenger(connectionId);
  }

  async connectAction(message = ''): Promise<void> {

    if (this.requestConnectionPending) {
      return;
    }

    this.requestConnectionPending = true;

    if (this.userProfile.connectOnlyWithAoMembers && !this.currentUserProfile.isAOMember) {
      await this.openBecomeAoMemberDialog();
      this.requestConnectionPending = false;
    } else {
      this.connectionsApiService.requestConnection(this.userProfile.userId, message)
        .then(() => {
          this.reloadUserProfile.emit();
          this.store.dispatch(new PeopleYouMayKnow.DisableConnectButtonForUser(this.userProfile.userId));
        })
        .catch(async errData => {

          console.error(errData);

          if (errData === 'User is not verified') {
            return;
          }
          if (errData?.error?.error?.info === '{"connection":"Restricted to ao members"}') {
            await this.openBecomeAoMemberDialog();
          } else if (errData?.error?.error?.info) {
            this.dialogs.showErrorDialog(errData?.error?.error?.info);
          } else if (errData?.error?.error?.message?.errfor?.message) {
            this.dialogs.showErrorDialog(errData?.error?.error?.message?.errfor?.message);
          }
        }).finally(() => {
        this.requestConnectionPending = false;
      });
    }
  }

  async openBecomeAoMemberDialog(): Promise<void> {
    this.uiUtilsService.showBecomeAoMemberDialog().then(accepted => {
      if (accepted) {
        this.uiUtilsService.onBecomeAOMember(this.contextService?.currentContext?.aoMembershipUrl);
      }
    });
  }

  acceptChatRequest(chatId: string): void {
    this.connectionsApiService.updateConnection(chatId, 'connected').then(updatedConnection => {
      this.connectionRequestsService.propagateConnectionRequestUpdate(updatedConnection);
    }).finally(() => {
      this.reloadUserProfile.emit();
    });
  }

  rejectChatRequest(chatId: string): void {
    this.connectionsApiService.updateConnection(chatId, 'rejected').then(updatedConnection => {
      this.connectionRequestsService.propagateConnectionRequestUpdate(updatedConnection);
    }).finally(() => {
      this.reloadUserProfile.emit();
    });
  }

  roundTotalItemsNumber(): void {
    this.totalItemsRounded = roundTotalCount(this.totalItemsCount - 5)
  }

  async followUser(): Promise<void> {
    this.follow.emit();
  }

  showUserSettings(): Promise<void> {
    return this.contextDialogs.showUserSettings()
      .then(signOut => {
        if (signOut) {
          this.appTranslationService.get('app.common.signing-out').then(message => {
            return this.loadingController.create({message});
          }).then(loader => {
            return Promise.all([
              loader,
              loader.present()
            ]);
          }).then(res => {
            const [loader] = res;
            return Promise.all([
              loader,
              this.uiUtilsService.signOut()
            ]);
          }).then(res => {
            const [loader] = res;
            loader.dismiss()
          });
        }
      }).catch(err => {
        console.error(err);
      });
  }

  onOpenActions(event: Event): void {
    this.openProfileActions.emit(event);
  }

  shareProfile(): void {
    this.sharingUIService.showShareDialogForObject(this.userProfile, undefined, this.source);
  }

  async showFollowersList(): Promise<void> {
    if (this.isDesktop && !this.isIOSDevice) {
      const dialog = await this.modalController.create({
        component: UserFollowersDialogComponent,
        cssClass: 'group-participants-dialog',
        componentProps: {
          currentUserId: this.userProfile.userId
        }
      });
      await dialog.present();
    } else {
      const url = `${this.contextService.currentContext.homePath}/profile/user-followers/${this.userProfile.userId}`
      await this.navController.navigateForward(url);
    }
  }

  onOpenProfile(): void {

    if (this.currentUserProfile?.userId !== this.userProfile?.userId) {
      this.onOpenPublicProfile(this.userProfile.userId);
      return;
    }

    this.appNavController.openProfile(this.source as ActionSource);
    this.userProfileOpened.emit();
  }

  onOpenPublicProfile(userId: string): void {
    this.appNavController.openPublicUserProfile(userId, this.source as ActionSource);
    this.userProfileOpened.emit();
  }

  get isIOSDevice(): boolean {
    return appConfig.platform === Platform.IOS
  }

  get isDesktop(): boolean {
    return this.responsiveUtilsService.isDesktop;
  }

  get QRCodeIsAvailable(): boolean {
    return this.qrCodeService.QRCodeIsAvailable;
  }

  get showConnectButton(): boolean {
    return this.uiUtilsService.isConnectButtonForPublicProfileVisible(this.userProfile);
  }

  navigateToQRCode(): void {
    this.navController.navigateForward(this.qrCodeService.profileQRCodePageUrl);
  }

  get _source(): string {
    const componentSource = ActionSource.profileCard;

    return this.parentSource && this.parentSource !== ActionSource.unspecified ?
      `${this.parentSource}-${componentSource}` : componentSource;
  }
}
