import {ContextRequest} from './context-request';
import {ErrorResponse} from './error';
import {PaginationRequest} from './pagination-request';
import {Comment} from './comments';
import {ShortPublicProfile, UserProfileImage} from './public-profile';
import {OpenGraph} from './open-graph';
import {PersonalMediaGalleryDocument} from './personal-media-gallery';
import {Approach, FractureAndTreatment, Preparation} from './surgery-reference-schema';
import {SourceCampaignForTracking} from './tracking';
import {ApplaudsSuccessResponseBase, UsersThatApplauded} from './applaud';
import {OrcIdArticle} from './article';
import {ClinicalCase} from './clinical-case';
import {ParentType} from './chatter-api-schema';

export interface UserRecentPublicContributionsMeta {
  hasPublicPosts: boolean,
  hasPublicCases: boolean
  hasPublicPolls: boolean
}

export interface GroupMember extends ShortPublicProfile {
  status?: string,
  memberRequestId?: string,
  isFollower?: any,
  role?: string,
  username?: string,
  isSendReminderAvailable?: boolean
}

export interface GroupListItem {
  _id: string;
  deleted?: boolean;
  createdDate?: string;
  title: string;
  appId?: string;
  description?: string;
  visibility: string;
  status?: string;
  activityDate?: string,
  keywords?: string[];
  tags?: string[];
  interests?: string[];
  imageUrl?: string;
  memberRequestId?: string;
  totalMembersCount?: number;
  totalInvitationsCount?: number;
  totalRequestsCount?: number;
  owner?: GroupMember,
  latestComment?: Comment;
  hasRecommended?: boolean;
  recommends?: Array<UserProfileImage>;
  AOMembersOnly?: boolean;
}

export interface Group extends GroupListItem {
  members?: Array<UserProfileImage>,
  moderators?: Array<GroupMember>
}

export enum PostType {
  link = 'link',
  attachment = 'attachment',
  text = 'text',
  case = 'case',
  post = 'post'
}

export enum CaseFileType {
  PRE_OP = 'preOp',
  INTRA_OP = 'intraOp',
  POST_OP = 'postOp',
  CLINICAL_FOLLOW_UP = 'clinicalFollowUp'
}

export type PostAttachment = PersonalMediaGalleryDocument;

export enum SharingParentType {
  GROUP = 'Group',
  EVENT = 'Event'
}

export interface UserRecentPublicContribution {
  item: Case | Post;
  type: UserRecentPublicContributionType;
}

export enum UserRecentPublicContributionType {
  CASE = 'case',
  POST = 'post',
  POLL = 'poll',
  LINK = 'link',
  ATTACHMENT = 'attachment',
  TEXT = 'text'
}

export type GroupPostAttachment = PersonalMediaGalleryDocument;

export type PostOwner = ShortPublicProfile;

export interface Post {
  _id: string;
  deleted: boolean;
  createdDate?: string;
  groupName?: string;
  type: PostType;
  groupId?: string;
  parentTitle?: string; // groupName
  description?: string;
  numberOfLikes: number;
  lastModified?: string;
  hasLiked: boolean;
  commentsCount: number;
  og?: OpenGraph;
  owner: PostOwner;
  attachments?: Array<PostAttachment>;
  url?: string;
  usersThatApplauded?: Array<UsersThatApplauded>,
  latestComment?: Comment,
  isAnonymous?: boolean,
  watermarked?: boolean,
  parentId: string,
  parentType: ParentType,
  createdByModerator?: boolean;
  createdByTopContributor?: boolean;
  isPinned?: boolean;
  isOwnPost?: boolean;
  isGroupOwnerOrModerator?: boolean;
  otherSources?: OtherSource[];
  title?: string;
  poll?: Poll;
}

export interface OtherSource {
  parentId: string;
  parentType: string;
  postId: string;
}

export interface Case extends Post {
  title: string;
  needAdvice: boolean;
  caseDiscussion: {
    caseId?: string,
    aootaClassification?: string;
    approach?: Approach
    preOpDocuments: Array<PostAttachment>,
    preOpDescription: string,
    intraOpDocuments: Array<PostAttachment>,
    intraOpDescription: string,
    postOpDocuments: Array<PostAttachment>,
    postOpDescription: string,
    clinicalFollowUpDocuments: Array<PostAttachment>,
    clinicalFollowUpDescription: string,
    complainMedicalCondition: string,
    description?: string,
    fractures?: Array<FractureAndTreatment>;
    clinicalExam: string,
    notes: string,
    classificationLevelOne: string;
    classificationLevelTwo?: string;
    preparation?: Preparation;
    title?: string;
    hasBookmarked?: boolean;
    isFeatured?: boolean;
    summary?: string;
    media?: {
      totalImages: number;
      totalVideos: number;
      mediaAttachments: Array<PostAttachment>
    }
  }
  isUpdated?: boolean;
}

export type GroupPostListItem = Post;
export type PostListItem = Post;

export enum LeaveGroupMemberStatus {
  LEFT = 'left',
  REMOVED = 'removed'
}

export interface LeaveGroupMember {
  userId: string;
  role?: string;
  status: LeaveGroupMemberStatus
}

export interface SaveGroupData {
  title: string;
  description?: string;
  visibility: string;
  image?: string;
  AOMembersOnly?: boolean;
}

export interface GroupsStreamItem {
  streamItem: GroupPostListItem;
  streamItemType: string;
}

export type PaginationContextRequestParams = PaginationRequest & ContextRequest;

export interface GroupsListRequestParams extends PaginationContextRequestParams {
  onlymember: boolean;
}

export interface GroupsListRequest {
  _schemaPath?: 'chatter/ionic/groups';
  _schemaMethod?: 'get';
  _schemaGetParams?: GroupsListRequestParams;
}

export interface GroupListSuccessResponse {
  _schemaPath?: 'chatter/ionic/groups';
  _schemaMethod?: 'get';
  success?: boolean;
  totalItemsCount?: number;
  result: Array<GroupListItem>;
}

export type GroupsListResponse = GroupListSuccessResponse | ErrorResponse;

export interface MemberPendingInvitationsRequest {
  _schemaPath?: 'chatter/ionic/groups/memberPendingInvitations';
  _schemaMethod?: 'get';
  _schemaGetParams?: PaginationContextRequestParams;
}

export interface MemberPendingInvitationsSuccessResponse {
  _schemaPath?: 'chatter/ionic/groups/memberPendingInvitations';
  _schemaMethod?: 'get';
  success: boolean;
  result: Array<GroupListItem>;
  totalItemsCount: number;
}

export type MemberPendingInvitationsResponse = MemberPendingInvitationsSuccessResponse | ErrorResponse;

export interface GroupMemberPendingRequestsRequest {
  _schemaPath?: 'chatter/ionic/groups/pendingRequestsForMember';
  _schemaMethod?: 'get';
  _schemaGetParams?: PaginationContextRequestParams;
}

export interface BaseGroupInfo {
  _id: string;
  deleted?: boolean;
  createdDate?: string;
  title: string;
  appId?: string;
  visibility: string;
  totalMembersCount?: number;
  imageUrl?: string;
}

export interface GroupMemberRequest {
  groupInfo: BaseGroupInfo;
  requester: ShortPublicProfile;
  memberRequestId: string;
}

export interface GroupMemberPendingRequestsSuccessResponse {
  _schemaPath?: 'chatter/ionic/groups/pendingRequestsForMember';
  _schemaMethod?: 'get';
  success: boolean;
  result: Array<GroupMemberRequest>;
  totalItemsCount: number;
}

export type GroupMemberPendingRequestsResponse = GroupMemberPendingRequestsSuccessResponse | ErrorResponse;


export type GroupByIdRequestParams = ContextRequest;

export interface GroupByIdRequest {
  _schemaPath?: 'chatter/ionic/groups';
  _schemaMethod?: 'get';
  _schemaGetParams?: GroupByIdRequestParams;
}

export interface GroupByIdSuccessResponse {
  _schemaPath?: 'chatter/ionic/groups';
  _schemaMethod?: 'get';
  result: Group;
}

export type GroupByIdResponse = GroupByIdSuccessResponse | ErrorResponse;

export interface GroupPostsListRequest {
  _schemaPath?: 'chatter/groups/posts';
  _schemaMethod?: 'get';
  _schemaGetParams?: PaginationContextRequestParams;
}

export interface GroupPostsListResponse {
  _schemaPath?: 'chatter/groups/posts';
  _schemaMethod?: 'get';
  result: Array<GroupPostListItem>;
  totalItemsCount?: number;
}

export interface PostsDetailsByPostIdsSuccessResponse {
  success: boolean;
  result: Array<Post>;
}

export type PostsDetailsByPostIdsResponse = PostsDetailsByPostIdsSuccessResponse | ErrorResponse;


export interface PostsListRequestParams extends PaginationContextRequestParams {
  facultyOnly?: boolean
}

export interface PostsListRequest {
  _schemaPath?: 'chatter/posts/v2';
  _schemaMethod?: 'get';
  _schemaGetParams?: PaginationContextRequestParams;
}

export interface PostsListResponse {
  _schemaPath?: 'chatter/posts/v2';
  _schemaMethod?: 'get';
  result: Array<PostListItem>;
  totalItemsCount?: number;
}

export interface LeaveGroupParams {
  [key: string]: string | number
}

export interface LeaveGroupRequest {
  _schemaPath?: 'chatter/members';
  _schemaMethod?: 'delete';
  _schemaGetParams?: LeaveGroupParams;
  members: Array<LeaveGroupMember>;
}

export interface LeaveGroupResponse {
  _schemaPath?: 'chatter/members';
  _schemaMethod?: 'delete';
}

export type DeleteGroupResponse = DeleteSuccessResponse | ErrorResponse;

export interface DeleteSuccessResponse {
  _schemaPath?: 'chatter/groups';
  _schemaMethod?: 'delete';
}

export type DeleteGroupParams = ContextRequest;

export interface GroupMembersRequestParams extends PaginationContextRequestParams {
  type?: string,
  excludedUserIds?: Array<string>
}

export interface GroupMembersRequest {
  _schemaPath?: 'chatter/members';
  _schemaMethod?: 'get';
  _schemaGetParams?: GroupMembersRequestParams;
}

export interface GroupMembersResponse {
  _schemaPath?: 'chatter/members';
  _schemaMethod?: 'get';
  totalItemsCount?: number;
  result: Array<GroupMember>;
}

export interface SearchGroupParticipantsResponse {
  _schemaPath?: 'chatter/searchParticipants';
  _schemaMethod?: 'get';
  result: Array<GroupMember>;
}

export type GroupPostByIdRequestParams = ContextRequest;

export interface GroupPostByIdRequest {
  _schemaPath?: 'chatter/posts';
  _schemaMethod?: 'get';
  _schemaGetParams?: GroupByIdRequestParams;
}

export interface PostByIdResponse {
  _schemaPath?: 'chatter/posts';
  _schemaMethod?: 'get';
  result: Post | Case;
}

export interface CreateGroupRequest {
  _schemaPath?: 'chatter/ionic/groups'
  _schemaMethod?: 'post',
  _schemaGetParams: CreateGroupRequestParams
}

export type CreateGroupRequestParams = ContextRequest;

export interface CreateGroupSuccessResponse {
  _schemaPath?: 'chatter/ionic/groups'
  _schemaMethod?: 'post',
  result: GroupListItem
}

export type CreateGroupResponse = CreateGroupSuccessResponse | ErrorResponse;

export interface UpdateGroupRequest {
  _schemaPath?: 'chatter/ionic/groups'
  _schemaMethod?: 'put',
  _schemaGetParams: UpdateGroupRequestParams
}

export type UpdateGroupRequestParams = ContextRequest;

export type UpdateGroupMemberResponse = UpdateGroupMemberRequest | ErrorResponse;

export interface UpdateGroupMemberRequest {
  _schemaPath?: 'chatter/ionic/groups'
  _schemaMethod?: 'put',
  _schemaGetParams: UpdateGroupMemberParams
}

export interface UpdateGroupSuccessResponse {
  _schemaPath?: 'chatter/ionic/groups'
  _schemaMethod?: 'put',
  result: GroupListItem
}

export type UpdateGroupResponse = UpdateGroupSuccessResponse | ErrorResponse;

export interface UpdateGroupMemberParams {
  userId: string,
  role: string,
  status?: string
}

export type RequestToJoinGroupRequestParams = ContextRequest;

export interface RequestToJoinGroupRequest {
  _schemaPath?: 'chatter/members';
  _schemaMethod?: 'post';
  _schemaGetParams?: RequestToJoinGroupRequestParams;
  email: string;
  userid: string;
}

export interface RequestToJoinGroupResponse {
  _schemaPath?: 'chatter/groups'
  _schemaMethod?: 'post',
  errfor?: { [key: string]: string },
  errors?: Array<any>,
  result?: {
    memberRequestAdded: string
  },
  success?: boolean
}

export interface SendReminderForGroupInvitationResponse {
  _schemaPath?: 'chatter/groups'
  _schemaMethod?: 'post',
  errfor?: { [key: string]: string },
  errors?: Array<any>,
  result?: {
    reminderSent: string
  },
  success?: boolean
}

export type JoinGroupRequestParams = ContextRequest;

export interface JoinGroupMember {
  email: string;
  userId: string;
}

export interface JoinGroupRequest {
  _schemaPath?: 'chatter/members';
  _schemaMethod?: 'put';
  _schemaGetParams?: JoinGroupRequestParams;
  members: Array<JoinGroupMember>;
}

export interface JoinGroupResponse {
  _schemaPath?: 'chatter/groups'
  _schemaMethod?: 'put',
  errfor?: { [key: string]: string },
  errors?: Array<any>,
  result: ShortPublicProfile,
  success: boolean
}

export type AcceptRejectGroupInvitationRequestParams = ContextRequest;

export interface AcceptRejectGroupInvitationRequest {
  _schemaPath?: 'chatter/members';
  _schemaMethod?: 'put';
  _schemaGetParams?: AcceptRejectGroupInvitationRequestParams;
  memberRequestId: string;
  status: string;
  userid: string;
}

export interface AcceptRejectGroupInvitationResponse {
  _schemaPath?: 'chatter/groups'
  _schemaMethod?: 'put',
  errfor: { [key: string]: string },
  errors: Array<any>,
  result: {
    membersUpdate: string
  },
  success: boolean
}

export type CreatePostResponse = CreatePostSuccessResponse | ErrorResponse;

export interface CreatePostRequestParams {
  appId: string;
}

export interface CreatePostRequest {
  _schemaPath?: '/chatter/ionic/posts/v2';
  _schemaMethod?: 'post';
  _schemaGetParams?: CreatePostRequestParams;
  title?: string;
  description?: string;
  file?: any;
  url?: string;
  type: PostType;
  preOpDescription?: string;
  intraOpDescription?: string;
  postOpDescription?: string;
  clinicalFollowUpDescription?: string;
  clinicalExam?: string,
  notes?: string;
  complainMedicalCondition?: string;
  needAdvice?: boolean;
  classificationLevelOne?: string;
  classificationLevelTwo?: string;
  isAnonymous?: boolean;
  objectId?: string;
  watermarked?: boolean;
  documents?: Array<string>;
  parentIds: Array<string>;
  parentType: ParentType;
  facultyOnly?: boolean;
  pollId?: string;
}

export interface CreatePostSuccessResponse {
  _schemaPath?: '/chatter/ionic/posts/v2';
  _schemaMethod?: 'get';
  result: Post | Case;
  error?: ErrorResponse
}

export type UpdatePostResponse = UpdatePostSuccessResponse | ErrorResponse;

export interface UpdatePostRequestParams {
  appId: string;
}

export interface UpdatePostRequest extends SourceCampaignForTracking {
  _schemaPath?: '/chatter/ionic/posts/v2';
  _schemaMethod?: 'post';
  _schemaGetParams?: UpdatePostRequestParams;
  title?: string;
  description?: string;
  file?: any;
  url?: string;
  type: PostType;
  preOpDescription?: string;
  intraOpDescription?: string;
  postOpDescription?: string;
  clinicalFollowUpDescription?: string;
  clinicalExam?: string,
  notes?: string;
  complainMedicalCondition?: string;
  needAdvice?: boolean;
  classificationLevelOne?: string;
  classificationLevelTwo?: string;
  isAnonymous?: boolean;
  watermarked?: boolean;
  documents?: Array<string>;
  parentId: string;
  parentType: ParentType;
  facultyOnly?: boolean;
}

export interface UpdatePostSuccessResponse {
  _schemaPath?: 'chatter/groups/posts';
  _schemaMethod?: 'get';
  result: Post | Case;
}

export type DeletePostResponse = DeletePostSuccessResponse | ErrorResponse;

export interface DeletePostRequestParams {
  appId: string;
}

export interface DeletePostSuccessResponse {
  _schemaPath?: 'chatter/groups/posts';
  _schemaMethod?: 'get';
}

export type LikeUnlikeResponse = LikeUnlikeSuccessResponse | ErrorResponse;

export interface LikeUnlikeRequestParams {
  appId: string;
}

export interface LikeUnlikeRequest {
  value: boolean;
  ownerId?: string;
}

export interface LikeUnlikeSuccessResponse {
  result: Post | ClinicalCase | Comment | OrcIdArticle;
}


export type UploadPostAttachmentResponse = UploadPostAttachmentSuccessResponse | ErrorResponse;

export interface UploadPostAttachmentSuccessResponse {
  _schemaPath?: 'chatter/groups/posts';
  _schemaMethod?: 'put';
  result: Post;
}

export type RemovePostAttachmentResponse = RemovePostAttachmentSuccessResponse | ErrorResponse;

export interface RemovePostAttachmentRequestParams {
  appId: string;
  fileType?: CaseFileType,
  postType?: PostType
}

export interface RemovePostAttachmentSuccessResponse {
  _schemaPath?: 'chatter/groups/posts';
  _schemaMethod?: 'delete';
  result: Post | Case;
}

export enum ReportAsAbuseObjectType {
  POST = 'post',
  COMMENT = 'comment',
  GROUP = 'Group'
}

export interface ReportAsAbuse {
  reportAdded: string;
}

export type ReportAsAbuseResponse = ReportAsAbuseSuccessResponse | ErrorResponse;

export type ReportGroupResponse = ReportGroupSuccessResponse | ErrorResponse;

export interface ReportAsAbuseRequestParams {
  appId: string;
}

export interface ReportAsAbuseRequest {
  _schemaPath?: 'chatter/groups/report';
  _schemaMethod?: 'post';
  _schemaGetParams?: ReportAsAbuseRequestParams;
  objectType: ReportAsAbuseObjectType,
  objectId: string,
  reason?: string
}

export interface ReportAsAbuseSuccessResponse {
  _schemaPath?: 'chatter/groups/report';
  _schemaMethod?: 'get';
  result: ReportAsAbuse;
}

export interface ReportGroupSuccessResponse {
  _schemaPath?: 'chatter/ionic/report';
  _schemaMethod?: 'get';
  result: ReportAsAbuse;
}

export interface CaseClassificationTagsObject {
  primaryTopicName: string;
  secondaryTopics: Array<string>
}

export type CaseClassificationsResponse = CaseClassificationsSuccessResponse | ErrorResponse;

export interface CaseClassificationsRequestParams {
  appId: string;
  language?: string;
}

export interface CaseClassificationsSuccessResponse {
  _schemaPath?: 'chatter/groups/posts/case-classifications';
  _schemaMethod?: 'get';
  result: Array<CaseClassificationTagsObject>;
}

export type LastActiveGroupsResponse = LastActiveGroupsSuccessResponse | ErrorResponse;

export interface LastActiveGroupsRequestParams {
  appId: string;
  start: number;
  count: number;
  excludeAoOnlyGroups?: boolean;
  publicOnly?: boolean;
}

export interface LastActiveGroupsSuccessResponse {
  _schemaPath?: 'chatter/groups/lastActive';
  _schemaMethod?: 'get';
  result: Array<GroupListItem>;
  totalItemsCount: number;
}

export interface GetLikesSuccessResponse extends ApplaudsSuccessResponseBase {
  _schemaPath?: 'chatter/ionic/posts';
  _schemaMethod?: 'get';
  _schemaGetParams?: PaginationContextRequestParams;
}

export type GetLikesResponse = GetLikesSuccessResponse | ErrorResponse;

export interface GetGroupRecommendsSuccessResponse {
  _schemaPath?: 'chatter/ionic/groups/recommends';
  _schemaMethod?: 'get';
  _schemaGetParams?: PaginationContextRequestParams;
  result: Array<ShortPublicProfile>;
  totalItemsCount: number;
}

export type GetGroupRecommendsResponse = GetGroupRecommendsSuccessResponse | ErrorResponse;

export type ShareObjectInMultipleGroupsRequestParams = ContextRequest;

export interface ShareObjectInMultipleGroupsEventsRequestData {
  type: PostType,
  isAnonymous: boolean,
  needAdvice?: boolean;
  objectId?: string,
  description?: string,
  url?: string,
  parentIds: Array<string>,
  parentType: SharingParentType,
  awsPersonalisationId?: string
}

export interface ShareObjectInMultipleGroupsEventsSuccessResponse {
  _schemaPath?: 'chatter/ionic/groups/share';
  _schemaMethod?: 'post';
  result: Array<Post>;
}

export type ShareObjectInMultipleGroupsEventsResponse =
  ShareObjectInMultipleGroupsEventsSuccessResponse
  | ErrorResponse;

export type PinPostRequestParams = ContextRequest;

export interface PinPostSuccessResponse {
  _schemaPath?: '/chatter/ionic/posts/v2/[postId]/pin?appId=[appId]';
  _schemaMethod?: 'put';
  result: Post;
}

export type PinPostResponse = PinPostSuccessResponse | ErrorResponse;

export type UnpinPostRequestParams = ContextRequest;

export interface UnpinPostSuccessResponse {
  _schemaPath?: '/chatter/ionic/posts/v2/[postId]/unpin?appId=[appId]';
  _schemaMethod?: 'put';
  result: Post;
}

export type UnpinPostResponse = UnpinPostSuccessResponse | ErrorResponse;

export interface GroupsStreamSuccessResponse {
  _schemaPath?: 'chatter/groups/stream';
  _schemaMethod?: 'get';
  _schemaGetParams?: PaginationContextRequestParams;
  result: Array<GroupsStreamItem>;
  totalItemsCount?: number;
}

export type GroupsStreamResponse = GroupsStreamSuccessResponse | ErrorResponse;

export interface SharedGroupsResponse {
  _schemaPath?: 'shared/groups/';
  _schemaMethod?: 'get';
  success: boolean,
  totalItemsCount: number,
  result: Array<GroupListItem>
}

export interface UserRecentPublicContributionsParams extends PaginationContextRequestParams {
  filterBy?: string
}

export interface UserRecentPublicContributionsRequest {
  _schemaPath?: 'chatter/ionic/publicContributions/:?userId/list';
  _schemaMethod?: 'get';
  _schemaGetParams?: UserRecentPublicContributionsParams;
}

export interface UserRecentPublicContributionsSuccessResponse {
  _schemaPath?: 'chatter/ionic/publicContributions/:userId/list';
  _schemaMethod?: 'get';
  success: boolean,
  totalItemsCount: number,
  result: Array<UserRecentPublicContribution>,
  meta?: UserRecentPublicContributionsMeta
}

export type UserRecentPublicContributionsResponse = UserRecentPublicContributionsSuccessResponse | ErrorResponse;

export enum DRAMeta {
  EVENTS = 'events',
  VIDEOS = 'videos',
  CONTACTS = 'contacts'
}

export interface DRAMetaSuccessResponse {
  _schemaPath?: '/backend/ionic/recommendation/meta';
  _schemaMethod?: 'get';
  success?: boolean;
  meta: DRAMeta
}

export type DRAMetaResponse = DRAMetaSuccessResponse | ErrorResponse;

export interface Poll {
  _id: string;
  deleted?: boolean;
  createdDate?: string;
  owner?: ShortPublicProfile;
  hasVoted?: boolean;
  isActive?: boolean;
  options: Array<{
    _id: string;
    deleted: boolean;
    answer: string;
    totalVotes?: number;
    hasVoted?: boolean;
    voters?: Array<ShortPublicProfile>
  }>;
  singleChoice: boolean;
  updatedDate?: string;
  question: string;
  totalVotes?: number;
}

export interface CreatePollPayload {
  question: string;
  options: Array<string>;
  singleChoice: boolean;
}

export interface UpdatePollPayload {
  _id?: string;
  question: string;
  options: Array<{ _id?: string, answer: string }>;
  singleChoice: boolean;
}

export interface CreatePollSuccessResponse {
  _schemaPath?: 'chatter/ionic/poll'
  _schemaMethod?: 'post',
  result: Poll,
  success: boolean
}

export type CreatePollResponse = CreatePollSuccessResponse | ErrorResponse;

export interface UpdatePollSuccessResponse {
  _schemaPath?: 'chatter/ionic/poll'
  _schemaMethod?: 'put',
  result: Poll,
  success: boolean
}

export type UpdatePollResponse = UpdatePollSuccessResponse | ErrorResponse;

export interface DeletePollPayload {
  _id: string;
}

export interface DeletePollSuccessResponse {
  _schemaPath?: 'chatter/ionic/poll'
  _schemaMethod?: 'delete',
  result: Record<string, never>,
  success: boolean
}

export type DeletePollResponse = DeletePollSuccessResponse | ErrorResponse;

export interface VotePollPayload {
  _id: string;
  votes: Array<string>;
}

export interface VotePollSuccessResponse {
  _schemaPath?: 'chatter/ionic/poll/vote'
  _schemaMethod?: 'put',
  result: Poll,
  success: boolean
}

export type VotePollResponse = VotePollSuccessResponse | ErrorResponse;

export interface GetPollSuccessResponse {
  _schemaPath?: 'chatter/ionic/poll/<pollId>'
  _schemaMethod?: 'get',
  result: Poll,
  success: boolean
}

export type GetPollResponse = GetPollSuccessResponse | ErrorResponse;
